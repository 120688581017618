$color_1: #4b4b4b;
$color_2: #df0c30;
$color_3: #b50a27;
$color_4: #fff;
$color_5: #b9bcbf;
$color_6: #83898f;
$color_7: #1eaea5;
$color_8: #009aed;
$color_9: #cfd1d3;
$color_10: #ffffff;
$color_11: #cb0728;
$color_12: #f6f7f9;
$color_13: white;
$color_14: #f21037;
$color_15: #f44336;
$color_16: #506370;
$color_17: #54d834;
$color_18: #4b4b4b;
$color_19: #664d03;
$color_20: #09ad95;
$color_21: #05c3fb;
$color_22: #222;
$color_23: #d40e2d;
$color_24: #222222;
$color_25: #34495e;
$color_26: #f8f9fa;
$color_27: #1d4ed8;
$color_28: red;
$color_29: #306391;
$color_30: #1c2a40;
$color_31: #86a258;
$color_32: rgba(75, 75, 75, 0.3);
$color_33: #009aed;
$color_34: #ffffff;
$color_35: #000000;
$color_36: #130101;
$color_37: #cf152d;
$color_38: #f7b731;
$color_39: #664d03;
$color_40: #924040;
$color_41: #df0c30;
$font-family_1: 'Montserrat', sans-serif;
$font-family_5: 'Montserrat';
$font-family_6: Montserrat;
$font-family_7: 'Open Sans';
$font-family_8: Open Sans;
$font-family_9: 'Open Sans';
$font-family_10: 'Montserrat';
$font-family_11: montserrat;
$font-family_12: Montserrat, sans-serif;
$font-family_13: Roboto, 'Helvetica Neue', sans-serif;
$font-family_14: 'Material Icons';
$background-color_1: #df0c30;
$background-color_2: #0db9f0;
$background-color_3: #fff;
$background-color_4: #e6e6e6;
$background-color_5: #b50a27;
$background-color_6: rgba(143, 208, 243, 0.2);
$background-color_7: rgba(0, 230, 130, 0.1);
$background-color_8: rgba(5, 193, 250, 0.2);
$background-color_9: #ffffff;
$background-color_10: #dadada;
$background-color_11: #ced4da;
$background-color_12: #ebebeb;
$background-color_13: hotpink;
$background-color_14: #dcedfc;
$background-color_15: #f46a6a;
$background-color_16: #cfd1d3;
$background-color_17: rgba(255, 162, 43, 0.1);
$background-color_18: #b50a27;
$background-color_19: #f7f7f8;
$background-color_20: #d7d9da;
$border-color_1: #cbe4fb;
$border-color_2: #f46a6a;

@import 'app/scss/custom';
@import 'app/scss/icon-font';

/** Media Queries **/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes loaderCircle {
  0% {
    box-shadow:
      0 -27px 0 0 rgba(232, 32, 63, 0.05),
      19px -19px 0 0 rgba(232, 32, 63, 0.1),
      27px 0 0 0 rgba(232, 32, 63, 0.2),
      19px 19px 0 0 rgba(232, 32, 63, 0.3),
      0 27px 0 0 rgba(232, 32, 63, 0.4),
      -19px 19px 0 0 rgba(232, 32, 63, 0.6),
      -27px 0 0 0 rgba(232, 32, 63, 0.8),
      -19px -19px 0 0 #e8203f;
  }
  12.5% {
    box-shadow:
      0 -27px 0 0 #e8203f,
      19px -19px 0 0 rgba(232, 32, 63, 0.05),
      27px 0 0 0 rgba(232, 32, 63, 0.1),
      19px 19px 0 0 rgba(232, 32, 63, 0.2),
      0 27px 0 0 rgba(232, 32, 63, 0.3),
      -19px 19px 0 0 rgba(232, 32, 63, 0.4),
      -27px 0 0 0 rgba(232, 32, 63, 0.6),
      -19px -19px 0 0 rgba(232, 32, 63, 0.8);
  }
  25% {
    box-shadow:
      0 -27px 0 0 rgba(232, 32, 63, 0.8),
      19px -19px 0 0 #e8203f,
      27px 0 0 0 rgba(232, 32, 63, 0.05),
      19px 19px 0 0 rgba(232, 32, 63, 0.1),
      0 27px 0 0 rgba(232, 32, 63, 0.2),
      -19px 19px 0 0 rgba(232, 32, 63, 0.3),
      -27px 0 0 0 rgba(232, 32, 63, 0.4),
      -19px -19px 0 0 rgba(232, 32, 63, 0.6);
  }
  37.5% {
    box-shadow:
      0 -27px 0 0 rgba(232, 32, 63, 0.6),
      19px -19px 0 0 rgba(232, 32, 63, 0.8),
      27px 0 0 0 #e8203f,
      19px 19px 0 0 rgba(232, 32, 63, 0.05),
      0 27px 0 0 rgba(232, 32, 63, 0.1),
      -19px 19px 0 0 rgba(232, 32, 63, 0.2),
      -27px 0 0 0 rgba(232, 32, 63, 0.3),
      -19px -19px 0 0 rgba(232, 32, 63, 0.4);
  }
  50% {
    box-shadow:
      0 -27px 0 0 rgba(232, 32, 63, 0.4),
      19px -19px 0 0 rgba(232, 32, 63, 0.6),
      27px 0 0 0 rgba(232, 32, 63, 0.8),
      19px 19px 0 0 #e8203f,
      0 27px 0 0 rgba(232, 32, 63, 0.05),
      -19px 19px 0 0 rgba(232, 32, 63, 0.1),
      -27px 0 0 0 rgba(232, 32, 63, 0.2),
      -19px -19px 0 0 rgba(232, 32, 63, 0.3);
  }
  62.5% {
    box-shadow:
      0 -27px 0 0 rgba(232, 32, 63, 0.3),
      19px -19px 0 0 rgba(232, 32, 63, 0.4),
      27px 0 0 0 rgba(232, 32, 63, 0.6),
      19px 19px 0 0 rgba(232, 32, 63, 0.8),
      0 27px 0 0 #e8203f,
      -19px 19px 0 0 rgba(232, 32, 63, 0.05),
      -27px 0 0 0 rgba(232, 32, 63, 0.1),
      -19px -19px 0 0 rgba(232, 32, 63, 0.2);
  }
  75% {
    box-shadow:
      0 -27px 0 0 rgba(232, 32, 63, 0.2),
      19px -19px 0 0 rgba(232, 32, 63, 0.3),
      27px 0 0 0 rgba(232, 32, 63, 0.4),
      19px 19px 0 0 rgba(232, 32, 63, 0.6),
      0 27px 0 0 rgba(232, 32, 63, 0.8),
      -19px 19px 0 0 #e8203f,
      -27px 0 0 0 rgba(232, 32, 63, 0.05),
      -19px -19px 0 0 rgba(232, 32, 63, 0.1);
  }
  87.5% {
    box-shadow:
      0 -27px 0 0 rgba(232, 32, 63, 0.1),
      19px -19px 0 0 rgba(232, 32, 63, 0.2),
      27px 0 0 0 rgba(232, 32, 63, 0.3),
      19px 19px 0 0 rgba(232, 32, 63, 0.4),
      0 27px 0 0 rgba(232, 32, 63, 0.6),
      -19px 19px 0 0 rgba(232, 32, 63, 0.8),
      -27px 0 0 0 #e8203f,
      -19px -19px 0 0 rgba(232, 32, 63, 0.05);
  }
  100% {
    box-shadow:
      0 -27px 0 0 rgba(232, 32, 63, 0.05),
      19px -19px 0 0 rgba(232, 32, 63, 0.1),
      27px 0 0 0 rgba(232, 32, 63, 0.2),
      19px 19px 0 0 rgba(232, 32, 63, 0.3),
      0 27px 0 0 rgba(232, 32, 63, 0.4),
      -19px 19px 0 0 rgba(232, 32, 63, 0.6),
      -27px 0 0 0 rgba(232, 32, 63, 0.8),
      -19px -19px 0 0 #e8203f;
  }
}
/** FONTS **/
/* Importing Bootstrap SCSS file. */
/* datepicker styles  */
/* end datepicker styles  */
/* STYLE PERFIL  */
/* END STYLE PERFIL */
/* boton select */
@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
/********************************Slider NG5 custom*****************************************/
/********************************Scroll****************************************************/
/* Material */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/font-material-icons.woff2') format('woff2');
}
.input-animated {
  animation-duration: 1s !important;
  animation-fill-mode: both !important;
}
html {
  height: 100%;
  min-height: 100%;
  margin: 0;
  transition: 300ms ease;
  overflow-x: hidden !important;
  overflow-y: auto;
}
body {
  min-height: 100%;
  margin: 0;
  transition: 300ms ease;
  overflow-x: hidden !important;
  overflow-y: auto;
}

.inter-regular {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.inter-medium {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.inter-semi-bold {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.inter-bold {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.inter-extra-bold {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.modal-dialog.rounded-lg > .modal-content {
  @extend .rounded-4;
  overflow: hidden;
}
a {
  font-weight: 600;
  font-family: $font-family_5;
  text-decoration: none;
}
label.x-small:not(.form-label):not(.form-check-label) {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  padding-bottom: 26px;
}
.form-control.custom {
  background: #fff;
  font-size: 16px;
  border: 0.5px solid #83898f;
  box-sizing: border-box;
  border-radius: 3px;
  &:focus {
    outline: none;
    border: 0.5px solid #009aed !important;
    box-shadow: none !important;
  }
}

button.plus {
  font-family: $font-family_5;
  letter-spacing: 0.5px;
  font-weight: 600;
  border: 1px solid #df0c30;
  color: $color_2;
  background: transparent;
  &:hover {
    background: #df0c30 !important;
    color: $color_4;
  }
}
button.planes {
  background: #fff;
  color: $color_1;
  font-family: $font-family_6;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 24px;
  letter-spacing: 0.5px;
  border: 1px solid #54d834;
  min-width: 130px;
  &:hover {
    background: #54d834;
    color: $color_4;
  }
}
button.planes.best {
  border: 1px solid #df0c30;
  &:hover {
    background: #fff;
    color: $color_1;
  }
}
.mat-option-text {
  img {
    margin-right: 10px;
  }
}

.btn-placeholder.placeholder,
button.placeholder {
  border-radius: 0.625rem;
}

span.placeholder {
  border-radius: 0.375rem;
}

.card {
  overflow: hidden;
  box-shadow: var(--bs-card-box-shadow);

  &:hover {
    .image-container img {
      transform: scale(1.08);
    }
  }

  .image-container {
    height: 126px;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
      transition: all 250ms ease;
    }
  }
}

.card__internacional {
  height: 190px;
  h1 {
    font-size: 38px;
  }
  > .d-flex {
    height: 210px !important;
  }
}
.card-header {
  background: #fff;
}
.card_pasajero {
  h4 {
    color: $color_1;
    font-family: $font-family_7;
    font-size: 18px;
    font-weight: 700;
    padding-left: 12px;
  }
  label {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    display: block;
    width: 100%;
    padding-bottom: 0;
    font-family: $font-family_5;
    font-style: normal;
    margin: 10px 0;
  }
  input.form-control {
    text-transform: uppercase;
    width: 100%;
    height: 47px;
    background: #fff;
    border: 0.5px solid #e4e5e7;
    box-sizing: border-box;
    border-radius: 5px;
    &::placeholder {
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      font-family: $font-family_5;
      color: $color_5;
    }
    &:focus {
      border: 0.5px solid #009aed !important;
      box-shadow: none;
    }
  }
}
.card.seguro {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  background: #54d834;
  border-radius: 22px;
  overflow: hidden;
  box-sizing: border-box;
  font-family: $font-family_5;
  width: 100%;
  border: none !important;
  margin-top: 15px;
  .card-header {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_4;
    border: 0px;
    background: #54d834;
    text-transform: uppercase;
    text-align: center;
    padding: 4px;
    padding: 8px 4px !important;
  }
  .card-body {
    background: #fff;
    color: $color_1;
    border-radius: 20px 20px 0 0;
    padding: 6px 1rem;
    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: $color_6;
      margin: 0;
    }
    span.now {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_1 !important;
    }
    h2 {
      margin-bottom: 10px;
      font-weight: bold;
      padding-top: 15px;
    }
    p {
      margin-top: 8px;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: $color_6;
      strong {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_6;
      }
    }
    p.now {
      color: $color_1 !important;
      strong {
        font-weight: 600;
        font-size: 30px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $color_1 !important;
        padding-left: 5px;
        padding-left: 5px;
      }
    }
    p.card-text {
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.1px;
      text-align: center;
    }
    .covid {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: $color_7;
      display: block;
      text-transform: capitalize;
      &:first-child {
        font-size: 14px;
      }
    }
    .imgCovid {
      width: 60px;
    }
    a {
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_8;
      text-decoration: none;
      font-size: 12px;
      &:hover {
        text-decoration: underline;
      }
    }
    button {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: $color_4;
      margin: 0 auto;
      border-radius: 3px;
      font-size: 14px;
      font-weight: normal;
      &:hover {
        background-color: $background-color_5;
      }
    }
  }
}
.card.seguro.best {
  background: #f21037;
  .card-header {
    background: #f21037;
  }
  .card-body {
    padding: 1rem;
  }
}
.ng5-slider {
  height: 15px !important;
  font-family: $font-family_5;
  margin: 20px 0px 0px 0px !important;
  .ng5-slider-pointer.ng5-slider-pointer-min {
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    top: -8px !important;
    background-color: $background-color_2;
    z-index: 3;
    border-radius: 16px;
    background-color: $background-color_3 !important;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15) !important;
    width: 14px !important;
    height: 14px !important;
    top: -5px !important;
  }
  .ng5-slider-pointer.ng5-slider-pointer-max {
    cursor: pointer;
    width: 25px !important;
    height: 25px !important;
    top: -10px !important;
    background-color: $background-color_2;
    z-index: 3;
    border-radius: 16px;
    background-color: $background-color_3 !important;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15) !important;
    width: 30px !important;
    height: 30px !important;
    top: -14px !important;
  }
  .ng5-slider-bubble {
    font-size: 14px;
    font-family: $font-family_6;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_1 !important;
    display: none !important;
  }
  .ng5-slider-bar {
    background: #dfe0e2 !important;
    height: 4px !important;
  }
  .ng5-slider-selection {
    background: #6d0617 !important;
  }
  .ng5-slider-tick {
    background: transparent !important;
  }
  .ng5-slider-tick.ng5-slider-selected {
    background: transparent !important;
  }
}
.ng5-slider-pointer.ng5-slider-active {
  &:after {
    opacity: 0 !important;
    background-color: $background-color_3 !important;
    width: 0px !important;
    position: absolute;
  }
}
.ng5-slider-pointer {
  &:after {
    opacity: 0 !important;
    background-color: $background-color_3 !important;
    width: 0px !important;
    position: absolute;
  }
}
.ng5-slider-span.ng5-slider-ceil {
  display: none !important;
  opacity: 1 !important;
  top: 20px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: $color_1 !important;
  font-family: $font-family_5;
}
.ng5-slider-span.ng5-slider-floor {
  display: none !important;
  opacity: 1 !important;
  top: 20px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: $color_1 !important;
  font-family: $font-family_5;
}
.ng5-slider-bubble {
  bottom: -26px !important;
}
.ng5-slider-model-value {
  left: 0 !important;
}
.ng5-slider-active {
  outline: none;
}
.ng5-slider-model-high {
  font-family: $font-family_5;
  right: 0 !important;
  left: auto !important;
}
.ng5-slider-pointer-min {
  &:active {
    border: 1px solid #0db9f0;
  }
}
.ng5-slider-pointer-max {
  &:active {
    border: 1px solid #0db9f0;
  }
}
.mat-tab-body.mat-tab-body-active {
  position: initial !important;
}

.modal-header {
  img {
    margin: 30px 0px 20px 0px;
  }

  h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.1px;
    font-family: $font-family_7;
    text-align: left;
    display: block;
    width: 100%;
    margin-bottom: 0;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    font-family: $font-family_5;
    width: 100%;
  }
}
.modal-body {
  input.form-control:not(.text-center) {
    border: 1px solid #83898f;
    background: #fff;
    box-sizing: border-box;
    border-radius: 5px;
    line-height: 42px;
    margin-bottom: 20px !important;
    font-family: $font-family_5;
    &::placeholder {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_9;
      font-family: $font-family_5;
      text-transform: capitalize;
    }
  }
  .mat-tab-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    font-family: $font-family_5;
    color: $color_1;
    opacity: 1;
  }
  .mat-ink-bar {
    background-color: $background-color_1 !important;
    height: 3px;
    min-width: 212px !important;
  }
  .mat-tab-body-content {
    padding: 2rem 0;
  }
  .mat-checkbox-label {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_8;
    font-family: $font-family_5;
  }
}
#cdr {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 15px 10px 10px 10px;
  list-style: none;
  background-color: $background-color_3;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  li {
    margin-bottom: 10px;
  }
  svg {
    margin-right: 6px;
  }
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1px;
    width: auto;
    margin-bottom: 0;
    padding: 0;
    font-family: $font-family_5;
  }
  span {
    font-size: 10px;
    line-height: 10px;
    padding: 0;
    font-family: $font-family_5;
  }
  input {
    border: 0;
    text-align: center;
    width: 30px;
    max-width: 30px;
    min-width: 30px;
    display: block;
    font-family: $font-family_5;
    font-size: 12px;
  }
  button {
    background: #df0c30;
    font-family: $font-family_5;
  }
  button.cancel {
    background: #ebebeb;
    color: $color_1;
  }
  button.count {
    border: 1px solid #e5e6e7;
    background: #fff;
    color: $color_8;
    &:hover {
      background: #df0c30;
      color: $color_4;
    }
  }
}
#carouselSeguros {
  color: $color_3;
  background: #f6f7f9;
  .carousel-caption {
    top: 180px;
    p {
      color: $color_1;
      font-size: 12px;
      margin-top: 50px;
      margin: 0 auto;
      width: 230px;
      width: 100%;
    }
  }
  .carousel-inner {
    height: 320px;
  }
  .carousel-indicators {
    button {
      border-radius: 50%;
      height: 10px;
      width: 10px;
      border: 1px solid #df0c30;
    }
    button.active {
      background-color: $background-color_1;
    }
  }
  .carousel-control-prev {
    background: url(./assets/prev.png) no-repeat center center !important;
    background: #fff;
    opacity: 1;
    border-radius: 50%;
    color: $color_3;
    height: 53px;
    width: 53px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    top: 35%;
  }
  .carousel-control-prev-icon {
    background-image: none;
    display: none !important;
  }
  .carousel-control-next {
    background: url(./assets/next.png) no-repeat center center !important;
    background: #fff;
    opacity: 1;
    border-radius: 50%;
    color: $color_3;
    height: 53px;
    width: 53px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    top: 35%;
  }
  .carousel-control-next-icon {
    background-image: none;
    display: none !important;
  }
  h2 {
    font-size: 24px;
    color: $color_1;
    span {
      color: $color_3;
    }
  }
  h3 {
    font-size: 24px;
    color: $color_1;
    span {
      color: $color_3;
    }
  }
}
.onlyDate {
  .ngb-dp-month {
    &:first-child {
      border-right: none;
      padding-right: 0;
      padding-bottom: 20px;
    }
  }
}
.btnDatePicker {
  display: flex;
  justify-content: flex-end;
  padding: 0 40px 20px 40px;
  font-family: $font-family_6;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: $color_10;
  button {
    font-family: $font-family_6;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: $color_10;
    border-radius: 3px;
  }
}
.ngb-dp-content {
  padding: 0 40px;
  font-family: $font-family_6;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: $color_1;
}
.ngb-dp-header {
  padding: 10px 30px 0 30px !important;
}
.ngb-dp-arrow {
  .btn-link {
    color: $color_2 !important;
    &:hover {
      color: $color_11 !important;
    }
  }
}
.ngb-dp-navigation-chevron {
  width: 0.5em !important;
  height: 0.5em !important;
}
.ngb-dp-navigation-select {
  select {
    border: none !important;
    font-family: $font-family_6;
    font-style: normal;
    font-weight: 600;
    font-size: 12px !important;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: $color_1;
    text-align: end;
    &:focus {
      outline: none;
    }
  }
}
.ngb-dp-month {
  &:first-child {
    border-right: 1px solid #83898f;
    padding-right: 15px;
  }
}
.ngb-dp-month-name {
  font-family: $font-family_6;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: $color_1;
  text-transform: uppercase;
}
.ngb-dp-weekday {
  font-style: normal !important;
  width: 2.4rem !important;
  height: 2.4rem !important;
}
.ngb-dp-weekdays {
  border-bottom: none !important;
}
.ngb-dp-day {
  width: 2.4rem !important;
  height: 2.4rem !important;
  span {
    width: 2.4rem !important;
    height: 2.4rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.portletFiltro {
  display: flex;
  flex: 1 1 100%;
  .rowFiltro {
    display: flex;
    flex: 1 1 auto;
    .columnFiltro {
      flex: 1 1 100%;
      padding: 10px 0;
      .iconCalendar {
        position: absolute;
        display: flex;
        height: 40px;
        width: 40px;
        align-items: center;
        padding-left: 10px;
        svg {
          path {
            stroke: #009aed;
          }
        }
      }
      input {
        padding: 0 !important;
        height: 49px;
        font-family: $font-family_6;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-transform: capitalize;
        color: $color_1;
        text-indent: 10px;
        border: 0.5px solid #e4e5e7;
        &:focus {
          border: 0.5px solid #009aed !important;
          box-shadow: none;
        }
        &::placeholder {
          color: $color_5 !important;
          font-size: 16px;
        }
        &::-webkit-input-placeholder {
          color: $color_5;
          font-size: 16px;
        }
      }
      input[type='text'] {
        padding: 0 !important;
        height: 49px;
        font-family: $font-family_6;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-transform: capitalize;
        color: $color_1;
        text-indent: 10px;
        border: 0.5px solid #e4e5e7;
        &:focus {
          border: 0.5px solid #009aed !important;
          box-shadow: none;
        }
        &::placeholder {
          color: $color_5 !important;
          font-size: 16px;
        }
        &::-webkit-input-placeholder {
          color: $color_5;
          font-size: 16px;
        }
      }
      input.origen {
        width: 290px;
      }
      input.destino {
        width: 290px;
      }
      input[type='text'].origen {
        width: 290px;
      }
      input[type='text'].destino {
        width: 290px;
      }
      button {
        letter-spacing: 0.5px;
        max-width: 150px;
        height: 48px;
        margin-left: 10px;
        background: #df0c30;
        &:hover {
          background: #b50a27;
        }
      }
    }
    .columnButton {
      padding: 10px 0;
      min-width: 150px;
      button {
        height: 40px;
        &:hover {
          background: #b50a27;
        }
        &:disabled {
          color: $color_12;
          background: #b50a27 !important;
        }
      }
    }
    .columnFiltro.hideColumn {
      max-width: 0 !important;
      width: 0 !important;
      input {
        border: none;
        color: $color_4;
      }
    }
  }
}
.flexCero {
  flex: 0 !important;
}
.mat-tab-body-wrapper {
  z-index: auto !important;
}
.form-group.hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  &:hover {
    background: #009aed;
    border-radius: 3px;
    color: $color_13;
  }
}
.custom-day.focused {
  background-color: $background-color_4;
}
.custom-day.range {
  background: #009aed;
  border-radius: 3px;
  color: $color_13;
}
.custom-day.faded {
  background: #8fd0f3;
  color: $color_8;
  border-radius: 0;
}
.mat-tab-body-content {
  overflow: hidden !important;
}
.mat-tab-body-active {
  overflow: visible !important;
  .mat-tab-body-content {
    overflow: visible !important;
  }
}
.box-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .box-perfil {
    background-color: $background-color_3;
    padding: 20px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    z-index: 10;
    .perfilName {
      font-family: $font-family_8;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      color: $color_1;
    }
    .perfilMail {
      font-family: $font-family_6;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_7;
    }
    .boxContact {
      display: flex;
      flex-direction: column;
      padding: 10px 0 10px 0;
      border-top: 1px solid #e4e5e7;
      margin-top: 20px;
      .contacto {
        display: flex;
        flex-wrap: wrap;
        .titleContact {
          width: 100%;
          text-align: center;
          font-family: $font-family_6;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.1px;
        }
        .linkContact {
          font-family: $font-family_6;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: $color_8;
          width: 100%;
          text-align: center;
          cursor: pointer;
        }
        .linkContact.disabled {
          color: $color_1;
          cursor: default;
        }
      }
    }
  }
  .box-content {
    display: flex;
    width: 100%;
    z-index: 9;
    .portlet-izq {
      display: block;
      width: 100%;
      .iconViaje {
        display: flex;
        justify-content: center;
        padding: 30px 0 19px 0;
        border-bottom: 1px solid #e4e5e7;
        img {
          width: 73px;
          height: 73px;
        }
      }
    }
    .portlet-der {
      flex: 1 1 100%;
      box-sizing: border-box;
      padding: 0 10px 0 10px;
      margin-top: 20px;
      width: 100%;
      .viewPerfil {
        flex: 1 1 100%;
        display: flex;
        flex-wrap: wrap;
        .boxContPerfil {
          background: #ffffff;
          border: 0.5px solid #e4e5e7;
          box-sizing: border-box;
          border-radius: 5px;
          display: flex;
          padding: 15px 27px;
          margin-bottom: 40px;
          flex-direction: column;
          .boxLeft {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            img {
              width: 84px;
            }
          }
          .boxRight {
            text-align: center;
            .subTitle {
              font-family: $font-family_6;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.1px;
              color: $color_14;
            }
            .title {
              font-family: $font-family_8;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 27px;
              letter-spacing: 0.18px;
              color: $color_1;
              padding: 5px 0 12px 0;
            }
            .descrip {
              font-family: $font-family_6;
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.1px;
              color: $color_1;
            }
            .boxBtn {
              display: flex;
              justify-content: center;
            }
          }
          .iconBox {
            width: 50px;
            display: flex;
            justify-content: end;
            align-items: center;
          }
          .boxCenter {
            display: flex;
            flex-wrap: wrap;
            .subTitle {
              font-family: $font-family_6;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.1px;
              color: $color_2;
            }
            .descrip {
              font-family: $font-family_6;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.1px;
              color: $color_6;
              padding: 5px 0;
            }
          }
        }
        .boxContPerfil--row {
          flex-direction: row;
        }
        .boxContPerfil.btnBox {
          cursor: pointer;
        }
      }
      .contentSecurity {
        display: flex;
        .rowContent {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          img {
            width: 38px;
            margin-right: 10px;
          }
          .title {
            font-family: $font-family_8;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            display: flex;
            align-items: center;
            letter-spacing: 0.18px;
            color: $color_1;
            display: flex;
            flex: 1 1 100%;
          }
          .subtitle {
            font-family: $font-family_6;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            letter-spacing: 0.25px;
            color: $color_1;
            display: flex;
            flex: 1 1 100%;
          }
        }
      }
      .personalDocument {
        background: #ffffff;
        border: 0.5px solid #e4e5e7;
        box-sizing: border-box;
        border-radius: 10px;
        .content {
          padding-top: 16px;
          padding-bottom: 25px;
          .rowContent {
            display: flex;
            flex: 1 1 100%;
            padding: 14px 6px 0px 26px;
            flex-direction: column;
            .inputGroup.error {
              .form-control {
                border: 0.5px solid #f44336;
              }
              .form-select {
                border: 0.5px solid #f44336;
              }
              .textError {
                opacity: 1;
              }
            }
            .inputGroup {
              .textError {
                opacity: 0;
                margin-left: 15px;
                font-weight: 300;
                font-size: 11px;
                color: $color_15;
              }
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              margin-right: 20px;
              span {
                font-family: $font-family_6;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                letter-spacing: 0.25px;
                color: $color_1;
                padding-bottom: 9px;
              }
              .nroDoc {
                width: 100%;
                position: relative;
                .iconoPais {
                  position: absolute;
                  left: 10px;
                  top: 42px;
                }
                .textPais {
                  padding-left: 32px;
                }
              }
              label {
                padding-bottom: 0 !important;
                font-family: $font-family_6;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                letter-spacing: 0.25px;
                color: $color_1;
              }
              .form-check-input {
                margin-top: 0 !important;
                margin-right: 10px;
                width: 24px;
                height: 24px;
              }
              .form-check-input[type='radio'] {
                cursor: pointer;
              }
              .form-check-label {
                cursor: pointer;
              }
              .optionLeft {
                border: 0.5px solid #e4e5e7;
                box-sizing: border-box;
                border-radius: 5px 0 0 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 15px;
                height: 47px;
              }
              .optionRight {
                border: 0.5px solid #e4e5e7;
                box-sizing: border-box;
                border-radius: 0 5px 5px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 15px;
                height: 47px;
              }
              .optionCheck {
                flex: 1 1 100%;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 15px;
                height: 47px;
              }
              input[type='text'] {
                border: 0.5px solid #e4e5e7;
                box-sizing: border-box;
                border-radius: 5px;
                height: 47px;
                color: $color_1;
                font-family: $font-family_6;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.25px;
              }
              select {
                border: 0.5px solid #e4e5e7;
                box-sizing: border-box;
                border-radius: 5px;
                height: 47px;
                color: $color_1;
                font-family: $font-family_6;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.25px;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23DF0C30' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
              }
              input {
                &::placeholder {
                  font-family: $font-family_6;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.25px;
                  color: $color_5;
                }
              }
            }
            .boxGrid {
              display: flex;
              flex: 1 1 100%;
              margin-right: 20px;
              flex-wrap: wrap;
              .rowGrid {
                display: flex;
                flex: 1 1 100%;
                padding: 10px 0;
                border-top: 1px solid #e4e5e7;
                &:first-child {
                  border-top: none;
                }
                .descrip {
                  display: flex;
                  align-items: center;
                  flex: 1 1 100%;
                }
                .descrip.titleDesc {
                  font-size: 14px;
                  font-weight: 600;
                }
                .descrip.subtitleDesc {
                  font-size: 12px;
                  font-weight: 500;
                }
              }
              .linkGrid {
                font-family: $font-family_6;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.1px;
                color: $color_8;
                cursor: pointer;
              }
              .descrip {
                .iconFono {
                  padding: 0 12px;
                }
                .textNroFono {
                  display: flex;
                  flex: 1 1 100%;
                }
              }
              .optEliminar {
                width: 32px;
                height: 32px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .optModificar {
                width: 32px;
                height: 32px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .msgWarning {
              font-family: $font-family_6;
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 21px;
              display: flex;
              align-items: center;
              letter-spacing: 0.25px;
              color: $color_3;
            }
            .passLabel {
              font-family: $font-family_6;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.1px;
              color: $color_1;
            }
            .passText {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: $font-family_6;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.1px;
              color: $color_6;
            }
            .inputGroup.fecVen {
              span {
                flex: 1 1 100%;
              }
              .form-control {
                max-width: 140px;
              }
            }
          }
        }
      }
      .cardLogo {
        display: flex;
        justify-content: center;
        padding: 30px;
        .boxCard {
          width: 120px;
          height: 60px;
          margin: 0 10px;
        }
      }
    }
    .headerPortlet {
      display: flex;
      flex-wrap: wrap;
      padding: 11px 26px;
      border-bottom: 1px solid #e4e5e7;
      .titleBoxRight {
        font-family: $font-family_8;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        display: flex;
        align-items: center;
        color: $color_1;
        width: 100%;
      }
      .subtitleBoxRight {
        font-family: $font-family_6;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
      }
    }
  }
}
.contentSecuritySeg {
  padding: 10px 0 20px 0;
  display: flex;
  .rowContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    img {
      width: 38px;
      margin-right: 10px;
    }
    .title {
      font-family: $font-family_8;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      align-items: center;
      letter-spacing: 0.18px;
      color: $color_1;
      display: flex;
      flex: 1 1 100%;
    }
    .subtitle {
      font-family: $font-family_6;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.25px;
      color: $color_1;
      display: flex;
      flex: 1 1 100%;
    }
  }
}
.optionSel {
  display: flex;
  flex: 1 1 100%;
  .optionLeft {
    border: 0.5px solid #e4e5e7;
    box-sizing: border-box;
    border-radius: 5px 0 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 47px;
    width: 50%;
  }
  .optionRight {
    border: 0.5px solid #e4e5e7;
    box-sizing: border-box;
    border-radius: 0 5px 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 47px;
    width: 50%;
  }
  .form-check-input {
    width: 24px;
    height: 24px;
    padding: 0;
    min-width: 24px;
    margin-left: 0px !important;
  }
  .form-check-inline {
    margin-right: 0;
  }
}
.form-check-inline {
  .form-check-label {
    padding-bottom: 0px;
  }
  display: flex;
  .form-check-input {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
.personalDocument {
  .rowContent.gridVuelo {
    padding: 10px 26px !important;
    flex-direction: column !important;
    gap: 15px;
  }
  .rowContent {
    .rowVuelo {
      display: flex;
      flex: 1 1 100%;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid #e4e5e7;
      padding: 10px;
      transition: 0.3s all;
      &:hover {
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
      }
      .colVuelo {
        display: flex;
        align-items: center;
        gap: 5px;
        .msgLabel {
          font-weight: 500;
          font-size: 9px;
          padding: 5px 10px;
          color: $color_10;
        }
        .msgLabel.success {
          background: rgba(19, 191, 166, 0.75);
          border-radius: 10px;
        }
        .msgLabel.disabled {
          visibility: hidden;
        }
        .Labelseguro {
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
        }
        .Descrip {
          font-weight: 400;
          font-size: 10px;
          line-height: 18px;
        }
      }
      .colVuelo.fullAuto {
        flex: 1 1 auto;
        font-weight: 400;
        font-size: 14px;
      }
      .colVuelo.flexCol {
        align-items: flex-start;
        margin-left: 20px;
        flex: 1 1 auto;
        flex-direction: column;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}
.boxMenu {
  width: 100%;
  margin-top: 24px;
  ul {
    padding: 0;
    margin: 0;
    li {
      display: flex;
      height: 35px;
      color: $color_8;
      text-decoration: none;
      width: 100%;
      font-weight: 500;
      cursor: pointer;
      font-family: $font-family_6;
      font-style: normal;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.1px;
      transition: all 0.3s;
      &:hover {
        color: $color_16;
      }
    }
    li.selected {
      color: $color_16;
    }
  }
}
.btnPerfil {
  margin-top: 10px;
  color: $color_10;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 3px;
  font-family: $font-family_6;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
}
.btnPerfil.primary {
  background: #df0c30;
  &:hover {
    background: #df0c30;
  }
}
.btnAgregar {
  font-family: $font-family_6;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: $color_8;
  margin-top: 10px;
  padding: 10px 15px 10px 0;
  cursor: pointer;
  transition: all 0.3s;
}
.btnAgregar.gris {
  color: $color_9;
}
.rowElement {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
}
.marginRight10 {
  margin-right: 10px;
}
.marginLeft10 {
  margin-left: 10px;
}
.marginTop20 {
  margin-top: 20px;
}
.marginTop40 {
  margin-top: 40px;
}
.marginBottom40 {
  margin-bottom: 40px;
}
.flexWrap {
  flex-wrap: wrap !important;
}
.flexNowrap {
  flex-wrap: nowrap !important;
}
.spaceBetween {
  justify-content: space-between;
}
.spaceEnd {
  justify-content: end !important;
}
.flexAuto {
  flex: auto !important;
}
.precioAntes {
  text-decoration: line-through #df0c30;
}
.carousel-item {
  .card {
    .card-body {
      font-family: $font-family_8;
      .cotainer {
        a {
          padding: 10px 14.5px;
        }
        .row {
          font-family: $font-family_6 !important;
          h5 {
            font-family: $font-family_6 !important;
          }
        }
      }
      h5 {
        font-family: $font-family_6 !important;
        font-weight: bold;
      }
    }
  }
}
card-title {
  .carousel-item {
    .card {
      .card-body {
        .cotainer {
          .row {
            h1 {
              font-family: $font-family_8 !important;
              font-style: normal;
              font-weight: 700;
              font-size: 28px;
              line-height: 36px;
              color: $color_1;
            }
          }
        }
      }
    }
  }
}
.filtroBox {
  overflow: hidden;
}
.postCard {
  overflow: hidden !important;
  position: inherit !important;
}
.posFilter {
  z-index: 11;
}
.inputDer {
  border-radius: 3px 0 0 3px !important;
  letter-spacing: 2px;
  &:focus {
    border: 0.5px solid #009aed !important;
    box-shadow: none;
  }
}
.inputIzq {
  border-radius: 0 3px 3px 0 !important;
  letter-spacing: 2px;
  border-left: 0px !important;
  &:focus {
    border: 0.5px solid #009aed !important;
    box-shadow: none;
  }
}
.selectSeguro {
  border: 0.5px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 5px;
  height: 49px;
  color: $color_1;
  font-family: $font-family_6;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23DF0C30' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  &:focus {
    border: 0.5px solid #009aed;
    box-shadow: none;
  }
}
.iconBandera {
  position: absolute;
  margin-top: 9px;
  margin-left: 10px;
}
.width110 {
  select {
    width: 110px;
    text-indent: 40px;
  }
  input {
    width: 110px;
    text-indent: 40px;
  }
}
.accordion {
  .accordion-item {
    .accordion-collapse {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    .footerDet {
      padding: 1rem 1.25rem;
      border-top: 1px solid #e4e5e7;
    }
  }
}
.footerPopup {
  span {
    font-weight: bold;
    font-size: 16px;
  }
  strong {
    font-size: 30px;
  }
  button {
    margin-top: 10px;
  }
  .alignFooterPopup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      color: $color_8;
    }
  }
}
.narginColumn {
  margin-right: 10px;
}
.espaciado {
  padding: 0 12px;
}
.iconFlechaSup {
  display: none;
  position: absolute;
  right: 20px;
  top: 15px;
  transition: all 0.3s;
  transform: rotate(180deg);
}
.textContactenos {
  font-family: $font-family_5;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: $color_1;
}
.boxGraphic {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 19px;
  .row {
    margin: 0;
    .circleBlue {
      background: #009aed;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      padding: 0;
      margin: 0;
    }
  }
  .row.title {
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.18px;
    color: $color_1;
  }
  .row.subtitle {
    font-family: $font-family_6;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: $color_1;
    gap: 10px;
  }
  .contentGraphic {
    display: flex;
    flex-wrap: nowrap;
    padding: 20px 0 0 0;
    border-bottom: 0.5px solid #cfd1d3;
    position: relative;
    .valorHor {
      position: absolute;
      top: 72%;
      border-top: 1px dashed #cfd1d3;
      left: 0;
      width: calc(100% - 185px);
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_1;
      z-index: 4;
    }
    .anioVertical {
      position: absolute;
      font-family: $font-family_6;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_1;
      left: 50%;
      top: -10px;
      height: 100%;
      border-left: 1px solid #e4e5e7;
      padding-left: 10px;
      z-index: 2;
    }
    .gLeft {
      width: 70px;
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_1;
      margin: auto 0;
    }
    .gCenter {
      width: 100%;
      gap: 5px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 230px;
      z-index: 3;
      .column {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        img {
          padding-top: 10px;
        }
        &:first-child {
          border-radius: 5px 0 0 0;
        }
        &:last-child {
          border-radius: 0 5px 0 0;
        }
      }
      .column.gris {
        background: #cfd1d3;
      }
      .column.red {
        background: #b50a27;
      }
      .column.blue {
        background: #009aed;
      }
      .column.height-10 {
        height: 10%;
      }
      .column.height-20 {
        height: 20%;
      }
      .column.height-30 {
        height: 30%;
      }
      .column.height-40 {
        height: 40%;
      }
      .column.height-50 {
        height: 50%;
      }
      .column.height-60 {
        height: 60%;
      }
      .column.height-70 {
        height: 70%;
      }
      .column.height-80 {
        height: 80%;
      }
      .column.height-90 {
        height: 90%;
      }
      .column.height-100 {
        height: 100%;
      }
    }
    .gRight {
      font-family: $font-family_6;
      width: 220px;
      .desde {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_8;
        padding-bottom: 10px;
      }
      .precio {
        font-weight: bold;
        font-size: 36px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_8;
        padding-bottom: 10px;
      }
      .descrip {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_1;
        padding-bottom: 5px;
      }
      .line {
        background: #009aed;
        height: 3px;
        width: 100%;
      }
    }
  }
  .contentGraphic.footer {
    border-bottom: none;
    padding-top: 5px;
    font-family: $font-family_6;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_1;
    .gCenter {
      height: 20px;
    }
    .column {
      text-align: center;
    }
  }
}
.boxPrecioAero {
  background: #ffffff;
  border-radius: 5px;
  table {
    font-family: $font-family_6;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.1px;
    color: $color_1;
    text-align: center;
    thead {
      tr {
        &:first-child {
          &:hover {
            background: none;
          }
        }
      }
    }
    tr {
      &:hover {
        background: rgba(143, 208, 243, 0.2);
      }
      td {
        &:first-child {
          font-weight: bold;
        }
      }
    }
    td.green {
      font-weight: bold;
      letter-spacing: 0.1px;
      color: $color_17;
    }
    td.red {
      letter-spacing: 0.1px;
      color: $color_3;
    }
    td.selected {
      background: #009aed;
      font-weight: bold;
      color: $color_10;
    }
    td.center {
      justify-content: center !important;
    }
  }
  .header {
    font-family: $font-family_6;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: $color_1;
    height: 46px;
    margin: 0;
    text-align: center;
  }
  .row {
    margin: 0;
    &:first-child {
      &:hover {
        background: white;
      }
    }
    &:hover {
      background: rgba(143, 208, 243, 0.2);
    }
    .col {
      font-family: $font-family_6;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.1px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e4e5e7;
      border-left: 1px solid #e4e5e7;
      color: $color_1;
      height: 46px;
      display: flex;
      justify-content: center;
      span {
        font-weight: bold;
        margin-left: 3px;
      }
      &:first-child {
        border-left: none;
        font-weight: bold;
        font-size: 14px;
        justify-content: start;
      }
      img {
        padding-right: 10px;
        + {
          div {
            font-weight: bold;
            white-space: pre-line;
          }
        }
      }
    }
    .col.green {
      font-weight: bold;
      letter-spacing: 0.1px;
      color: $color_17;
    }
    .col.red {
      letter-spacing: 0.1px;
      color: $color_3;
    }
    .col.selected {
      background: #009aed;
      font-weight: bold;
      color: $color_10;
    }
    .col.center {
      justify-content: center !important;
    }
  }
}
.boxPrecios {
  background: #ffffff;
  border-radius: 5px;
  table {
    font-family: $font-family_6;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.1px;
    color: $color_1;
    text-align: center;
    thead {
      tr {
        &:first-child {
          &:hover {
            background: none;
          }
        }
      }
    }
    tr {
      &:hover {
        background: rgba(143, 208, 243, 0.2);
      }
      td {
        &:first-child {
          font-weight: bold;
        }
      }
    }
    td.green {
      font-weight: bold;
      letter-spacing: 0.1px;
      color: $color_17;
    }
    td.red {
      letter-spacing: 0.1px;
      color: $color_3;
    }
    td.selected {
      background: #009aed;
      font-weight: bold;
      color: $color_10;
    }
    td.center {
      justify-content: center !important;
    }
  }
  .header {
    font-family: $font-family_6;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: $color_1;
    height: 46px;
    margin: 0;
    text-align: center;
    font-size: 12px !important;
    .twoRow {
      text-align: left;
      padding-left: 10px;
      font-weight: normal !important;
      display: flex;
      align-items: center;
      &:first-child {
        border-bottom: 1px solid #e4e5e7;
      }
      img {
        margin-left: 10px;
      }
    }
  }
  .row {
    margin: 0;
    &:first-child {
      &:hover {
        background: white;
      }
    }
    &:hover {
      background: rgba(143, 208, 243, 0.2);
    }
    .col {
      font-family: $font-family_6;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.1px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e4e5e7;
      border-left: 1px solid #e4e5e7;
      color: $color_1;
      height: 46px;
      display: flex;
      justify-content: center;
      font-size: 12px !important;
      padding: 0;
      span {
        font-weight: bold;
        margin-left: 3px;
      }
      &:first-child {
        border-left: none;
        font-weight: bold;
        font-size: 14px;
        justify-content: start;
        padding-left: 6px;
        width: 500px;
      }
      img {
        padding-right: 10px;
        + {
          div {
            font-weight: bold;
            white-space: pre-line;
          }
        }
      }
    }
    .col.green {
      font-weight: bold;
      letter-spacing: 0.1px;
      color: $color_17;
    }
    .col.red {
      letter-spacing: 0.1px;
      color: $color_3;
    }
    .col.selected {
      background: #009aed;
      font-weight: bold;
      color: $color_10;
    }
    .col.center {
      justify-content: center !important;
    }
    .col.lessPadLeft {
      padding-left: 0;
    }
  }
}
.viajero {
  .escala {
    padding-bottom: 5px;
  }
  .directo {
    display: flex;
    justify-content: space-between;
    .escala_directo {
      background: #fff;
      border: 1px solid #83898f;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      margin-top: -4px;
    }
  }
  .twoEscala {
    display: flex;
    .escala_punto {
      margin-top: -6px;
    }
  }
  .time_duration {
    cursor: pointer;
  }
}
.boxMasUno {
  position: relative;
  .textMasUno {
    position: absolute;
    font-family: $font-family_6;
    font-size: 11px;
    letter-spacing: 0.1px;
    color: $color_14;
    top: -12px;
    right: 5px;
  }
}
.toolTip {
  position: relative;
  &:hover {
    .boxTooltip2 {
      display: block;
    }
  }
  .boxTooltip2 {
    display: none;
    position: absolute;
    padding: 10px;
    width: 230px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #e4e5e7;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
    bottom: 32px;
    z-index: 20;
    font-family: $font-family_6;
    left: calc(100% - 119px);
    &:after {
      display: block;
      content: ' ';
      position: absolute;
      bottom: -8px;
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      background: #ffffff;
      right: calc(50% - 8px);
      box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
      transform: rotate(45deg);
    }
    .descripTooltip {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_6;
    }
  }
}
.footerLink {
  display: block;
  font-family: $font-family_6;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: $color_1;
  .linkBlue {
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: $color_8;
    cursor: pointer;
  }
}
#politicaModal {
  .modal-dialog {
    max-width: 60% !important;
    .modal-header {
      padding-bottom: 2rem;
      border-bottom: 1px solid #e4e5e7;
      .titleMod {
        font-family: $font-family_8;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: $color_1;
      }
    }
    .modal-body {
      padding: 30px 80px;
      p {
        font-family: $font-family_6;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_1;
      }
      .listModal {
        li {
          font-family: $font-family_6;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: $color_1;
          &::marker {
            color: $color_3;
          }
        }
      }
      .redLink {
        font-family: $font-family_6;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_3;
      }
    }
  }
}
#reservaModal {
  .modal-dialog {
    max-width: 60% !important;
    max-width: 50% !important;
    .modal-header {
      padding-bottom: 2rem;
      border-bottom: 1px solid #e4e5e7;
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
      border-bottom: 1px solid #e4e5e7;
      background: #1eaea5;
      border-radius: 10px 10px 0 0;
      color: $color_10;
      .titleMod {
        font-family: $font-family_8;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: $color_1;
      }
    }
    .modal-body {
      padding: 30px 80px;
      p {
        font-family: $font-family_6;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_1;
      }
      .listModal {
        li {
          font-family: $font-family_6;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: $color_1;
          &::marker {
            color: $color_3;
          }
        }
      }
      .redLink {
        font-family: $font-family_6;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_3;
      }
    }
    .titleMod {
      color: $color_10 !important;
    }
    .titleModal {
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_1;
    }
    .subtitleMod {
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.1px;
      border-bottom: 1px solid #e4e5e7;
      color: $color_16;
      margin: 45px 0;
      text-align: center;
      padding-bottom: 10px;
    }
    .listModal {
      padding-top: 15px;
      li {
        padding-bottom: 10px;
      }
    }
  }
}
#Modaltermino {
  .modal-dialog {
    max-width: 100% !important;
    .modal-content {
      .headerRojo {
        background: #b50a27;
        height: 70px;
        min-height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 55px;
      }
      .cabeceraModal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 55px;
        background: #ffffff;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        height: 128px;
        min-height: 128px;
        font-family: $font-family_8;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        color: $color_1;
        .btnModal {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          margin-left: 15px;
          font-family: $font-family_6;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: $color_2;
          border: 1px solid #df0c30;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 0 10px;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background: #df0c30;
            color: $color_10;
          }
        }
      }
    }
    .modal-body {
      padding: 55px;
      .titleModal {
        font-family: $font-family_8;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 30px;
        color: $color_1;
      }
      .linkRed {
        font-family: $font-family_6;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        padding-bottom: 10px;
        color: $color_3;
      }
      .subtitleModal {
        font-family: $font-family_6;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.1px;
        padding: 30px 0;
      }
      .listaModal {
        li {
          font-family: $font-family_6;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0.1px;
          list-style-type: decimal;
        }
      }
      p {
        font-family: $font-family_6;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.1px;
      }
    }
  }
}
#ModalCondicion {
  .modal-dialog {
    max-width: 100% !important;
    .modal-content {
      .headerRojo {
        background: #b50a27;
        height: 70px;
        min-height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 55px;
      }
      .cabeceraModal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 55px;
        background: #ffffff;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        height: 128px;
        min-height: 128px;
        font-family: $font-family_8;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        color: $color_1;
        .btnModal {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          margin-left: 15px;
          font-family: $font-family_6;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: $color_2;
          border: 1px solid #df0c30;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 0 10px;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background: #df0c30;
            color: $color_10;
          }
        }
      }
    }
    .modal-body {
      padding: 55px;
      .titleModal {
        font-family: $font-family_8;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 30px;
        color: $color_1;
      }
      .linkRed {
        font-family: $font-family_6;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        padding-bottom: 10px;
        color: $color_3;
      }
      .subtitleModal {
        font-family: $font-family_6;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.1px;
        padding: 30px 0;
      }
      .listaModal {
        li {
          font-family: $font-family_6;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0.1px;
          list-style-type: decimal;
        }
      }
      p {
        font-family: $font-family_6;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.1px;
      }
    }
  }
  .modal-content {
    .modal-body {
      padding: 0;
      background: #e5e5e5;
      .contentModal {
        padding: 55px;
        background: #ffffff;
        .imgCentral {
          display: flex;
          flex: 1 1 100%;
          align-items: flex-end;
          gap: 20px;
          margin-bottom: 40px;
          justify-content: center;
        }
      }
      .contentModBox {
        border: 0.5px solid #e4e5e7;
        margin: 55px;
        border-radius: 10px;
        background: #ffffff;
        .textTitle {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px 10px 0px 0px;
          font-family: $font-family_8;
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
          color: $color_10;
          background: #1eaea5;
          height: 60px;
        }
        .listModal {
          padding: 30px 60px;
        }
        .listModal.lessPad {
          padding: 30px 15px;
        }
        .padLateral {
          padding: 0 120px;
        }
        .titleCondicion {
          font-family: $font-family_6;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
          padding-left: 45px;
          color: $color_1;
        }
        .tablaModal {
          padding: 0 150px;
          .rowModal {
            display: flex;
            flex: 1 1 auto;
            .colModal {
              display: flex;
              flex: 1 1 15%;
              padding-bottom: 30px;
            }
          }
        }
      }
      .contentRed {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 55px 55px 55px;
        font-family: $font-family_6;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: justify;
        letter-spacing: 0.1px;
        color: $color_1;
        height: 60px;
        border: 1px solid #df0c30;
        box-sizing: border-box;
        border-radius: 10px;
      }
    }
  }
}
.widthDesktop {
  width: 950px !important;
}
.hideDesktop {
  display: none !important;
}
#modalDetVuelo {
  .modal-dialog {
    min-width: 70% !important;
  }
  .titleModal {
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 22px;
    color: $color_1;
  }
  .boxVuelos {
    .content {
      display: flex;
      flex-wrap: nowrap;
    }
    .boxCenter {
      display: flex;
      justify-content: center;
      .boxDescrip {
        background: #f7f7f7;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        width: 320px;
        text-align: center;
        padding: 10px;
        font-family: $font-family_6;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_8;
      }
    }
    .boxFooter {
      background: #f7f7f7;
      border-radius: 5px;
      width: 100%;
      padding: 20px;
      font-family: $font-family_6;
      font-style: normal;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_1;
      margin-bottom: 30px;
      z-index: 1;
      .title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .elemento {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-bottom: 7px;
      }
    }
    .titleModal {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 22px;
      line-height: 36px;
      color: $color_1;
    }
    .subtitle {
      font-family: $font-family_6;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.15px;
      color: $color_1;
      display: flex;
      gap: 10px;
      padding-bottom: 30px;
    }
    .column {
      flex: 1 1 100%;
      .boxSeg {
        margin-bottom: 50px;
        position: relative;
        .text {
          font-family: $font-family_6;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: $color_1;
          img {
            padding-bottom: 5px;
          }
        }
        .text.blue {
          color: $color_8;
        }
        .text.red {
          color: $color_2;
        }
        .text.borde {
          color: $color_8;
          font-weight: 600;
          background-color: $background-color_6;
          border-radius: 5px;
          padding: 0px 7px;
          text-align: center;
        }
        .iconImg {
          position: absolute;
          right: -39px;
          top: 30%;
        }
        .iconImg.firstIcon {
          background: #fff;
          padding-top: 18px;
          top: 0;
        }
        .iconImg.lastIcon {
          background: #fff;
          padding-bottom: 150px;
        }
      }
    }
    .column.alignRight {
      border-right: 2px dashed #8fd0f3;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 30px;
      .text {
        text-align: right;
      }
    }
    .column.alignLeft {
      margin-left: 30px;
      display: flex;
      align-items: center;
    }
    .column.paddingTop {
      padding-top: 50px;
    }
  }
}
.nav-item {
  .nav-link {
    padding: 0;
  }
}
#ModalCoverageI {
  .modal-dialog {
    min-width: 60% !important;
  }
  .modal-header {
    background: #54d834;
    .modal-title {
      font-family: $font-family_7;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: $color_10;
    }
    .modal-subtitle {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_10;
    }
  }
  .modal-body {
    border-radius: 20px;
    margin-top: -20px;
  }
  .boxTableCoverage {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    .rowTableCover {
      display: flex;
      border-bottom: 1px solid #cfd1d3;
      .colTabletCover {
        display: flex;
        align-items: center;
        flex: 1 1 50%;
        padding: 15px;
        &:first-child {
          border-right: 1px solid #cfd1d3;
        }
      }
    }
  }
}
#ModalCoverage {
  .modal-dialog {
    min-width: 60% !important;
  }
  .modal-header {
    background: #54d834;
    .modal-title {
      font-family: $font-family_7;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: $color_10;
    }
    .modal-subtitle {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_10;
    }
  }
  .modal-body {
    border-radius: 20px;
    margin-top: -20px;
  }
  .boxTableCoverage {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    .rowTableCover {
      display: flex;
      border-bottom: 1px solid #cfd1d3;
      .colTabletCover {
        display: flex;
        align-items: center;
        flex: 1 1 50%;
        padding: 15px;
        &:first-child {
          border-right: 1px solid #cfd1d3;
        }
      }
    }
  }
}
#ModalDetResarva {
  .modal-dialog {
    min-width: 80% !important;
  }
  .modal-header {
    font-family: $font-family_9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px !important;
    background: #f6f7f9;
    border-radius: 10px;
    .title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding-right: 60px;
      .textTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: $color_18;
      }
      .msgTag {
        padding: 15px;
        background: rgba(19, 191, 166, 0.75);
        border-radius: 5px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
    }
  }
  .modal-body {
    padding: 20px !important;
    background: #f6f7f9;
    border-radius: 10px;
    .msgAlert {
      background: #fff3cd;
      border: 1px solid #ffecb5;
      border-radius: 5px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.25px;
      color: $color_19;
      text-align: center;
      padding: 15px;
    }
    .portletReserva {
      display: flex;
      flex-direction: column;
      margin: 20px 0;
      gap: 20px;
      .boxDetReserva {
        background: #ffffff;
        border: 0.5px solid #e4e5e7;
        box-sizing: border-box;
        border-radius: 5px;
        .headerPortlet {
          display: flex;
          flex-wrap: wrap;
          padding: 11px 26px;
          border-bottom: 1px solid #e4e5e7;
          .titleBoxRight {
            font-family: $font-family_8;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 36px;
            display: flex;
            align-items: center;
            color: $color_1;
            width: 100%;
          }
        }
        .contentPortlet {
          margin: 20px;
          ul {
            font-size: 14px;
            line-height: 26px;
            li {
              span {
                font-weight: 600;
              }
            }
          }
          .footerReserva {
            display: flex;
            .alignLeft {
              display: flex;
              justify-content: flex-start;
              flex: 1 1 auto;
              margin-left: 20px;
              .totalSafetypay {
                flex: 1 1 auto;
                .labelTotal {
                  font-family: $font-family_10;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                }
                .montoTotal {
                  font-family: $font-family_10;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 32px;
                  line-height: 24px;
                }
              }
            }
            .alignRigth {
              display: flex;
              justify-content: flex-end;
              .totalInfoPago {
                width: 250px;
                .labelTotal {
                  font-family: $font-family_10;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                }
                .montoTotal {
                  font-family: $font-family_10;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 32px;
                  line-height: 24px;
                }
              }
            }
          }
          .rowEquipaje {
            gap: 10px;
            display: flex;
            flex-direction: column;
            .itemEquipaje {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .itemPasajero {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .colPasajero {
              flex: 1 1 20%;
              &:first-child {
                flex: 0 1 80px;
              }
            }
          }
          .rowPasajero {
            gap: 10px;
            display: flex;
            flex-direction: column;
            .itemEquipaje {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .itemPasajero {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .colPasajero {
              flex: 1 1 20%;
              &:first-child {
                flex: 0 1 80px;
              }
            }
          }
          .rowVuelo {
            gap: 10px;
            display: flex;
            flex-direction: column;
            .itemEquipaje {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .itemPasajero {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .colPasajero {
              flex: 1 1 20%;
              &:first-child {
                flex: 0 1 80px;
              }
            }
          }
          .rowEquipaje.headCol {
            flex-direction: row;
            padding: 10px 0;
            .colPasajero {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .rowPasajero.headCol {
            flex-direction: row;
            padding: 10px 0;
            .colPasajero {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .rowVuelo.headCol {
            flex-direction: row;
            padding: 10px 0;
            .colPasajero {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .rowEquipaje.rowItem {
            flex-direction: row;
            .colPasajero {
              font-size: 14px;
            }
          }
          .rowPasajero.rowItem {
            flex-direction: row;
            .colPasajero {
              font-size: 14px;
            }
          }
          .rowVuelo.rowItem {
            flex-direction: row;
            .colPasajero {
              font-size: 14px;
            }
          }
          .rowEquipaje.headVueloIda {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowEquipaje.headVueloVuelta {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            background-color: $background-color_8;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
              border: 1px solid #05c3fb;
              color: $color_21;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowPasajero.headVueloIda {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowPasajero.headVueloVuelta {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            background-color: $background-color_8;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
              border: 1px solid #05c3fb;
              color: $color_21;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowVuelo.headVueloIda {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowVuelo.headVueloVuelta {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            background-color: $background-color_8;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
              border: 1px solid #05c3fb;
              color: $color_21;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowEquipaje.contentVuelo {
            display: flex;
            flex-direction: row;
            padding: 15px;
            .colVuelo {
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
              .icoIda {
                align-items: center;
              }
              .aerolinea {
                font-weight: 600;
                font-size: 14px;
              }
              .codigo {
                span {
                  border: 1px solid #09ad95;
                  color: $color_20;
                  border-radius: 5px;
                  padding: 2px 10px;
                  font-size: 12px;
                }
              }
              .operador {
                font-size: 12px;
              }
              .descrip {
                font-size: 12px;
              }
              .fecha {
                font-size: 14px;
                span {
                  color: $color_2;
                  font-weight: 600;
                }
              }
              .lugar {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
          .rowPasajero.contentVuelo {
            display: flex;
            flex-direction: row;
            padding: 15px;
            .colVuelo {
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
              .icoIda {
                align-items: center;
              }
              .aerolinea {
                font-weight: 600;
                font-size: 14px;
              }
              .codigo {
                span {
                  border: 1px solid #09ad95;
                  color: $color_20;
                  border-radius: 5px;
                  padding: 2px 10px;
                  font-size: 12px;
                }
              }
              .operador {
                font-size: 12px;
              }
              .descrip {
                font-size: 12px;
              }
              .fecha {
                font-size: 14px;
                span {
                  color: $color_2;
                  font-weight: 600;
                }
              }
              .lugar {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
          .rowVuelo.contentVuelo {
            display: flex;
            flex-direction: row;
            padding: 15px;
            .colVuelo {
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
              .icoIda {
                align-items: center;
              }
              .aerolinea {
                font-weight: 600;
                font-size: 14px;
              }
              .codigo {
                span {
                  border: 1px solid #09ad95;
                  color: $color_20;
                  border-radius: 5px;
                  padding: 2px 10px;
                  font-size: 12px;
                }
              }
              .operador {
                font-size: 12px;
              }
              .descrip {
                font-size: 12px;
              }
              .fecha {
                font-size: 14px;
                span {
                  color: $color_2;
                  font-weight: 600;
                }
              }
              .lugar {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .portletReserva.lessMargin {
      margin: 0 !important;
    }
  }
}
#ModalDetSeguro {
  .modal-dialog {
    min-width: 80% !important;
  }
  .modal-header {
    font-family: $font-family_9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px !important;
    background: #f6f7f9;
    border-radius: 10px;
    .title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding-right: 60px;
      .textTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: $color_18;
      }
      .msgTag {
        padding: 15px;
        background: rgba(19, 191, 166, 0.75);
        border-radius: 5px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
    }
  }
  .modal-body {
    padding: 20px !important;
    background: #f6f7f9;
    border-radius: 10px;
    .msgAlert {
      background: #fff3cd;
      border: 1px solid #ffecb5;
      border-radius: 5px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.25px;
      color: $color_19;
      text-align: center;
      padding: 15px;
    }
    .portletReserva {
      display: flex;
      flex-direction: column;
      margin: 20px 0;
      gap: 20px;
      .boxDetReserva {
        background: #ffffff;
        border: 0.5px solid #e4e5e7;
        box-sizing: border-box;
        border-radius: 5px;
        .headerPortlet {
          display: flex;
          flex-wrap: wrap;
          padding: 11px 26px;
          border-bottom: 1px solid #e4e5e7;
          .titleBoxRight {
            font-family: $font-family_8;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 36px;
            display: flex;
            align-items: center;
            color: $color_1;
            width: 100%;
          }
        }
        .contentPortlet {
          margin: 20px;
          ul {
            font-size: 14px;
            line-height: 26px;
            li {
              span {
                font-weight: 600;
              }
            }
          }
          .footerReserva {
            display: flex;
            .alignLeft {
              display: flex;
              justify-content: flex-start;
              flex: 1 1 auto;
              margin-left: 20px;
              .totalSafetypay {
                flex: 1 1 auto;
                .labelTotal {
                  font-family: $font-family_10;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                }
                .montoTotal {
                  font-family: $font-family_10;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 32px;
                  line-height: 24px;
                }
              }
            }
            .alignRigth {
              display: flex;
              justify-content: flex-end;
              .totalInfoPago {
                width: 250px;
                .labelTotal {
                  font-family: $font-family_10;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                }
                .montoTotal {
                  font-family: $font-family_10;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 32px;
                  line-height: 24px;
                }
              }
            }
          }
          .rowEquipaje {
            gap: 10px;
            display: flex;
            flex-direction: column;
            .itemEquipaje {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .itemPasajero {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .colPasajero {
              flex: 1 1 20%;
              &:first-child {
                flex: 0 1 80px;
              }
            }
          }
          .rowPasajero {
            gap: 10px;
            display: flex;
            flex-direction: column;
            .itemEquipaje {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .itemPasajero {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .colPasajero {
              flex: 1 1 20%;
              &:first-child {
                flex: 0 1 80px;
              }
            }
          }
          .rowVuelo {
            gap: 10px;
            display: flex;
            flex-direction: column;
            .itemEquipaje {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .itemPasajero {
              display: flex;
              gap: 10px;
              .iconEquipaje {
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .labelEquipaje {
                font-family: $font-family_10;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }
            }
            .colPasajero {
              flex: 1 1 20%;
              &:first-child {
                flex: 0 1 80px;
              }
            }
          }
          .rowEquipaje.headCol {
            flex-direction: row;
            padding: 10px 0;
            .colPasajero {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .rowPasajero.headCol {
            flex-direction: row;
            padding: 10px 0;
            .colPasajero {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .rowVuelo.headCol {
            flex-direction: row;
            padding: 10px 0;
            .colPasajero {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .rowEquipaje.rowItem {
            flex-direction: row;
            .colPasajero {
              font-size: 14px;
            }
          }
          .rowPasajero.rowItem {
            flex-direction: row;
            .colPasajero {
              font-size: 14px;
            }
          }
          .rowVuelo.rowItem {
            flex-direction: row;
            .colPasajero {
              font-size: 14px;
            }
          }
          .rowEquipaje.headVueloIda {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowEquipaje.headVueloVuelta {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            background-color: $background-color_8;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
              border: 1px solid #05c3fb;
              color: $color_21;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowPasajero.headVueloIda {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowPasajero.headVueloVuelta {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            background-color: $background-color_8;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
              border: 1px solid #05c3fb;
              color: $color_21;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowVuelo.headVueloIda {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowVuelo.headVueloVuelta {
            flex-direction: row;
            background-color: $background-color_7;
            padding: 15px;
            border-radius: 5px;
            background-color: $background-color_8;
            .colVuelo {
              display: flex;
              flex: 1 1 auto;
              font-size: 14px;
              align-items: center;
            }
            .colVuelo.iconVuelo {
              width: 50px;
              max-width: 50px;
            }
            .colVuelo.labelIda {
              flex: 1 1 auto;
              font-weight: 600;
            }
            .colVuelo.labelvuelo {
              flex: 1 1 auto;
              border: 1px solid #09ad95;
              color: $color_20;
              justify-content: center;
              border-radius: 5px;
              padding: 5px 10px;
              border: 1px solid #05c3fb;
              color: $color_21;
            }
            .colVuelo.duracion {
              flex: 1 1 50%;
              justify-content: flex-end;
              gap: 10px;
            }
          }
          .rowEquipaje.contentVuelo {
            display: flex;
            flex-direction: row;
            padding: 15px;
            .colVuelo {
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
              .icoIda {
                align-items: center;
              }
              .aerolinea {
                font-weight: 600;
                font-size: 14px;
              }
              .codigo {
                span {
                  border: 1px solid #09ad95;
                  color: $color_20;
                  border-radius: 5px;
                  padding: 2px 10px;
                  font-size: 12px;
                }
              }
              .operador {
                font-size: 12px;
              }
              .descrip {
                font-size: 12px;
              }
              .fecha {
                font-size: 14px;
                span {
                  color: $color_2;
                  font-weight: 600;
                }
              }
              .lugar {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
          .rowPasajero.contentVuelo {
            display: flex;
            flex-direction: row;
            padding: 15px;
            .colVuelo {
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
              .icoIda {
                align-items: center;
              }
              .aerolinea {
                font-weight: 600;
                font-size: 14px;
              }
              .codigo {
                span {
                  border: 1px solid #09ad95;
                  color: $color_20;
                  border-radius: 5px;
                  padding: 2px 10px;
                  font-size: 12px;
                }
              }
              .operador {
                font-size: 12px;
              }
              .descrip {
                font-size: 12px;
              }
              .fecha {
                font-size: 14px;
                span {
                  color: $color_2;
                  font-weight: 600;
                }
              }
              .lugar {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
          .rowVuelo.contentVuelo {
            display: flex;
            flex-direction: row;
            padding: 15px;
            .colVuelo {
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
              .icoIda {
                align-items: center;
              }
              .aerolinea {
                font-weight: 600;
                font-size: 14px;
              }
              .codigo {
                span {
                  border: 1px solid #09ad95;
                  color: $color_20;
                  border-radius: 5px;
                  padding: 2px 10px;
                  font-size: 12px;
                }
              }
              .operador {
                font-size: 12px;
              }
              .descrip {
                font-size: 12px;
              }
              .fecha {
                font-size: 14px;
                span {
                  color: $color_2;
                  font-weight: 600;
                }
              }
              .lugar {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .portletReserva.lessMargin {
      margin: 0 !important;
    }
  }
}
#ModalBlue {
  .modal-dialog {
    min-width: 70% !important;
  }
  .modal-header {
    background: #009aed;
    color: $color_4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 176px;
    padding: 16px !important;
    .modal-title {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      display: flex;
      align-items: center;
      color: $color_10;
    }
    .modal-subtitle {
      font-family: $font-family_8;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      color: $color_10;
    }
  }
  .contentRow {
    display: flex;
    flex: 1 1 100%;
    margin-top: -76px;
    .circleSafety {
      width: 152px;
      height: 152px;
      background: #ffffff;
      border-radius: 76px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    }
  }
  .contentRow.centerCont {
    justify-content: center;
  }
  .modal-body {
    border-radius: 0px;
    .rowModal {
      display: flex;
      flex: 1 1 100%;
      justify-content: space-evenly;
      .columnModal {
        position: relative;
        .headPaso {
          height: 80px;
          .number {
            display: flex;
            background: #009aed;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            color: $color_10;
            align-items: center;
            justify-content: center;
            font-family: $font-family_7;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
          }
        }
        .textPaso {
          font-family: $font-family_8;
          font-size: 18px;
          line-height: 27px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.18px;
          color: $color_1;
          flex-direction: column;
        }
        input[type='radio'] {
          display: flex !important;
          position: absolute;
          left: 50px;
          top: 45px;
          width: 18px;
          height: 18px;
        }
        label {
          padding-bottom: 0 !important;
        }
      }
      .columnModal.colCenter {
        display: flex;
        flex: 1 1 100%;
        flex-wrap: wrap;
        text-align: center;
        margin: 10px;
        background: #ffffff;
        padding: 42px;
        border: 1px solid #e4e5e7;
        box-sizing: border-box;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
      .columnModal.inputCheck.activo {
        background: #ffffff;
        border: 1px solid #009aed;
        box-sizing: border-box;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        transition: all 0.3s;
      }
      .columnModal.inputCheck {
        span {
          font-family: $font-family_8;
          font-weight: bold;
          font-size: 18px;
          line-height: 27px;
          display: flex;
          align-items: center;
          letter-spacing: 0.18px;
          color: $color_1;
          background: rgba(235, 235, 235, 0.2);
          border-radius: 10px;
          width: 233px;
          height: 106px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s;
        }
      }
    }
    .rowModal.padBottom50 {
      padding-bottom: 50px;
    }
    .rowModal.padBottom10 {
      padding-bottom: 10px;
    }
  }
}
.atribCard {
  padding: 0;
  background: transparent;
  border: none;
  overflow: hidden;
}
.checkPopup {
  padding: 12px;
}
.btnMobile {
  display: none;
  padding: 20px 0 10px 0;
  justify-content: center;
}
.btnFooter {
  font-family: $font-family_5;
  font-size: 16px;
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.colFono {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.colFono.mTop {
  margin-top: -25px;
}
.accordion-body {
  .textAcor {
    font-family: $font-family_6;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_6;
  }
  .text-end {
    font-family: $font-family_6;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_6;
  }
  .price_old {
    text-decoration: line-through red;
    padding-right: 10px;
  }
  .textDesc {
    font-family: $font-family_6;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_8;
  }
  .price_Desc {
    font-family: $font-family_6;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_8;
  }
  .row {
    min-height: 32px;
  }
}
.totalItem {
  margin: 1rem 0.5rem !important;
  align-items: center;
}
.boxFilter {
  .bodyFilter {
    font-family: $font-family_6;
    font-size: 16px;
    line-height: 18px;
    color: $color_1;
    img {
      margin-right: 10px;
    }
    .textBold {
      font-weight: bold;
      letter-spacing: 0.5px;
    }
  }
}
.boxOferta {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 0.5px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 10px;
  .ofertaRow {
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 0 20px 20px 20px;
    .imgFondo {
      width: 100%;
      border-radius: 10px 10px 0 0;
      transition: all 0.5s ease-in-out;
    }
    .flotante {
      position: absolute;
      color: $color_10;
      top: 50%;
      z-index: 2;
      transition: all 0.5s ease-in-out;
      left: 0;
      .rowFlota {
        font-family: $font-family_6;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.1px;
        padding-left: 15px;
        position: relative;
        .line {
          position: absolute;
          background: #df0c30;
          height: 2px;
          width: 70px;
          top: 8px;
          left: 10px;
        }
      }
      .rowFlota.red {
        font-family: $font-family_8;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        height: 45px;
        background: #df0c30;
        border-radius: 5px 0px 0px 5px;
        display: flex;
        align-items: center;
        border-radius: 0 5px 5px 0;
      }
      .rowFlota.descrip {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1px;
        padding-left: 5px;
      }
    }
    .iconOferta {
      margin: 0 5px;
    }
  }
  .ofertaRow.zoom {
    overflow: hidden;
    position: relative;
    padding: 0;
    margin-bottom: 20px;
    &:hover {
      .imgFondo {
        transform: scale(1.1);
      }
      .flotante {
        transform: scale(1.1);
        left: 6px;
      }
    }
  }
  .ofertaRow.title {
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: $color_1;
  }
  .ofertaRow.descrip {
    font-family: $font-family_6;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_7;
  }
}
.btnVerMas {
  font-family: $font-family_6;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
}
.edad {
  display: flex;
  flex: 1 1 100%;
  gap: 10px;
  margin-top: 7px;
  .titleEdad {
    display: flex;
    min-width: 140px;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: $color_22;
    font-family: $font-family_5;
  }
  .inputEdad {
    width: 40px;
    height: 40px;
    padding: 0;
    text-align: center;
    border: 0.5px solid #e4e5e7;
  }
}
.boxFlotante {
  width: 330px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: absolute;
  top: 25px;
  z-index: 2;
  .textBtn {
    .inputPasajero {
      width: 25px;
      height: 28px;
      padding: 0;
      margin: 0;
      text-align: center;
      border-radius: 0;
      font-family: $font-family_6;
      font-weight: 600;
      font-size: 12px;
      border: none;
    }
    .inputEdadPasajero {
      box-sizing: border-box;
      border-radius: 3px;
      width: 50px;
      font-family: $font-family_6;
      font-weight: normal;
      font-size: 12px;
      color: $color_1;
      height: 29px;
      padding: 0;
      margin: 0;
      text-align: center;
      &::placeholder {
        font-family: $font-family_6;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
  .opcionBtn {
    width: 30px;
    cursor: pointer;
    img {
      width: 12px;
      height: 15px;
    }
  }
  .boxTwoPasajero {
    border-bottom: 0.5px solid #e4e5e7;
    padding: 15px 20px !important;
    .groupItem {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      gap: 5px;
      position: relative;
      .closeBtn {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s all;
        border-radius: 3px;
        &:hover {
          background: #e4e5e7;
        }
      }
      .boxNumber {
        width: auto;
        display: flex;
        align-items: center;
        margin-right: 10px;
        .numberItem {
          background: #df0c30;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color_10;
          font-size: 10px;
          border-radius: 8px;
          margin-right: 5px;
        }
        .cerrarbtn {
          padding: 5px;
          position: absolute;
          top: 0;
          left: 0;
          width: 22px;
          height: 22px;
          background: rgba(223, 12, 48, 0.4);
          border-radius: 2px;
          transition: 0.3s all;
          cursor: pointer;
          &:hover {
            background: #df0c30;
          }
        }
        .cerrarbtn.inactive {
          display: none;
        }
      }
      .itemPasajero {
        width: 100%;
        display: flex;
        align-items: center;
        .inputEdad {
          border: 0.5px solid #e4e5e7;
          border-radius: 3px;
          width: 100%;
          text-align: center;
        }
        span {
          font-family: $font-family_6;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0.4px;
          color: $color_1;
        }
      }
      .itemPasajero.espacio {
        gap: 10px;
      }
      .itemPasajero.w100 {
        width: 100px;
      }
    }
    .groupItem.inLine {
      flex-direction: row;
    }
    .iconTwo {
      width: 25px;
    }
    .textSection {
      flex: 1 1 auto;
    }
    .text1 {
      font-family: $font-family_6;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: $color_1;
    }
    .text2 {
      font-family: $font-family_6;
      font-weight: 300;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.25px;
      color: $color_1;
    }
    .number {
      display: flex;
      justify-content: space-between;
      font-family: $font-family_6;
      gap: 5px;
      .btnNWhite {
        display: flex;
        border: 0.5px solid #df0c30;
        box-sizing: border-box;
        border-radius: 3px;
        font-weight: 600;
        font-size: 14px;
        color: $color_6;
        width: 28px;
        min-width: 28px;
        height: 28px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: #df0c30;
          color: $color_10;
        }
      }
      .textBtn {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: $color_1;
        width: 28px;
        height: 28px;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .btnPasajero {
    button {
      font-family: $font-family_6;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      &:hover {
        background: #b50a27;
      }
    }
    button.gray {
      background: #ebebeb;
      color: $color_1;
      &:hover {
        background: #cfd1d3;
      }
    }
    button.green {
      background: #54d834;
      color: $color_10;
      &:hover {
        background: #3eb322;
      }
    }
    .Agregar {
      background: transparent;
      font-family: $font-family_6;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.1px;
      color: $color_8;
      &:hover {
        background: transparent;
      }
      &:disabled {
        color: $color_1;
      }
    }
  }
}
.boxFlotante.seguro {
  top: inherit;
  padding: 10px 10px 0 10px;
  margin-top: 50px;
  .boxTwoPasajero {
    .text1 {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.15px;
      align-items: center;
      gap: 10px;
      img {
        width: 14px;
        height: 14px;
      }
    }
    border-bottom: none;
  }
  .btnPasajero {
    border-top: 0.5px solid #e4e5e7;
    margin: 10px 0 0 0;
  }
}
.titleHome {
  font-size: 24px;
  font-weight: 400;
  font-family: $font-family_8;
  .red {
    color: $color_2;
  }
}
.titleHome.bold {
  font-weight: 700;
}
.titleHome.red {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: $color_2;
  margin-bottom: 20px;
}
.titleHome.packageTitle {
  margin: 40px 0 10px 0 !important;
}
.subTitleHome {
  font-size: 18px;
  font-weight: 400;
  font-family: $font-family_10;
}
.boxItemPackages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1 1 100%;
  gap: 20px;
  margin-top: 20px;
  .boxItem {
    width: calc(25% - 20px);
    max-width: calc(25% - 20px);
    position: relative;
    border-radius: 6px;
    transition: all 0.6s;
    overflow: hidden;
    img {
      width: 100%;
    }
    .groupCardTour {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0px;
      right: 0px;
      overflow: hidden;
      transition: all 0.6s;
      background: rgba(0, 0, 0, 0.2);
      &:hover {
        .cardTour {
          top: 0;
          background: rgba(0, 0, 0, 0.6);
          .titleCard {
            background: none;
          }
          .descripCard {
            display: flex;
          }
          .btnSearch {
            display: flex;
          }
        }
      }
      .cardTour {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 5px;
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        .titleCard {
          text-align: center;
          font-size: 22px;
          color: $color_10;
          width: 100%;
          font-weight: 600;
          font-family: $font-family_8;
          padding-bottom: 10px;
        }
        .descripCard {
          display: none;
          text-align: center;
          font-size: 12px;
          color: $color_10;
          margin-bottom: 10px;
        }
        .btnSearch {
          display: none;
          align-items: center;
          border-radius: 15px;
          color: $color_10;
          background: #d40e2d;
          height: 30px;
          padding: 0 18px;
          font-size: 14px;
          font-family: $font-family_8;
          cursor: pointer;
        }
      }
    }
  }
}
.boxMailing {
  margin: 40px 0;
}
.boxCanalPackage {
  display: flex;
  flex: 1 1 100%;
  justify-content: space-between;
  margin: 40px 0 20px 0;
  .boxCanal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 15%;
    flex-direction: column;
    .iconCanal {
      display: flex;
      height: 48px;
    }
    .titleCanal {
      padding: 20px 0;
      font-size: 16px;
      font-weight: 600;
      font-family: $font-family_8;
    }
    .boxAction {
      display: flex;
      a {
        height: 44px;
        border-radius: 22px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family_5;
        background-color: $background-color_1;
        color: $color_10;
        font-size: 14px;
        font-weight: 600;
        transition: all 0.3s;
        &:hover {
          background: #b50a27;
        }
      }
    }
  }
}
.comprarHideTitle {
  .textTitle {
    display: none;
  }
}
.titleMob {
  line-height: 36px;
  padding-bottom: 10px;
}
.boxImgDesktop {
  margin-bottom: 25px;
  img {
    width: 100%;
    height: 70px;
    border-radius: 5px;
  }
}
.rowBoxTab {
  display: flex;
  padding: 4px 0 10px 0;
  position: relative;
  label {
    font-family: $font-family_8;
    font-style: normal;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.18px;
  }
  .padRight {
    padding-right: 20px;
  }
  .fullWidht {
    width: 100%;
    height: 49px;
  }
  .colBoxtab {
    display: flex;
    flex: 1 1 auto;
    padding: 10px 0;
    .rowTab {
      display: flex;
      width: 100%;
      gap: 10px;
      .colTab {
        flex: 1 1 100%;
        .w-15 {
          width: 100%;
        }
        .d-left {
          border-radius: 3px;
          border: 0.5px solid #e4e5e7;
        }
        .form-check-input {
          width: 24px;
          height: 24px;
        }
        .form-check-label {
          cursor: pointer;
        }
      }
    }
    .d-left {
      border-radius: 3px 0 0 3px;
      border: 0.5px solid #e4e5e7;
      border-right: 0px;
    }
    .d-right {
      border-radius: 0 3px 3px 0;
      border: 0.5px solid #e4e5e7;
    }
    input {
      width: 100%;
      padding: 0 !important;
      height: 49px;
      font-family: $font-family_6;
      font-size: 16px;
      text-transform: capitalize;
      color: $color_1;
      text-indent: 40px;
      border: 0.5px solid #e4e5e7;
      border-radius: 3px;
    }
    input.icono {
      text-indent: 40px;
      padding-right: 33px !important;
      background: url('./assets/origen.png') no-repeat 10px center;
    }
    input.icono-no {
      text-indent: 10px;
      background: none;
    }
    .iconCalendar {
      position: relative;
      svg {
        position: absolute;
        top: 12px;
        left: 10px;
      }
    }
  }
  .colBoxtab.w-30 {
    width: 30%;
  }
  .colBoxtab.w-10 {
    width: 10%;
  }
  .colBoxtab.w-15 {
    width: 15%;
  }
  .colBoxtab.widthBtn {
    align-items: center;
    width: 180px;
    min-width: 180px;
  }
  .colBoxtab.colGrid {
    flex-wrap: wrap;
    gap: 10px;
    margin-right: 10px;
  }
}
.rowBoxTab.right {
  justify-content: end;
}
.rowBoxTab.text {
  font-family: $font-family_8;
  font-style: normal;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.18px;
}
.onlydesktop {
  display: flex !important;
}
.onlyMovTablet {
  display: none !important;
}
.onlyMovil {
  display: none !important;
}
.boxVuelos {
  width: 100%;
  padding: 35px;
  .rowVuelo {
    display: flex;
    align-items: center;
    font-family: $font-family_6;
    height: 53px;
    border-bottom: 1px solid #cfd1d3;
    transition: all 0.3s;
    cursor: pointer;
    &:first-child {
      border-top: 1px solid #cfd1d3;
    }
    &:hover {
      background: #ffffff;
      filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.15));
    }
    .lugar {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $color_25;
      padding-left: 45px;
      width: 100%;
    }
    .desde {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_25;
      width: 80px;
    }
    .precio {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $color_25;
      width: 100px;
    }
    .btnCircle {
      display: none;
    }
  }
}
.padTitle {
  padding: 20px 0 40px 0;
}
.boxImgVuelo {
  padding: 0;
  .gridVuelo {
    display: grid;
    grid-template-rows: 15rem 8rem 15rem;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    .gridItem1 {
      grid-column: 1/2;
      grid-row: 1/1;
    }
    .gridItem2 {
      grid-row: 1/3;
    }
    .gridItem3 {
      grid-row: 2/4;
    }
    .gridItem4 {
      grid-column: 2/3;
    }
    img {
      height: 100%;
      object-fit: cover;
      object-position: bottom;
      width: 100%;
    }
  }
}
.viajaBox {
  height: 550px;
  background-image: url('./assets/vuelos/footer-vuelos.png');
  background-color: $background-color_1;
  background-repeat: no-repeat;
  background-position: center;
  .mnuViaja {
    padding-top: 190px;
    margin: 0;
    display: flex;
    .padLeft70 {
      padding-left: 70px;
      img {
        width: 100%;
      }
    }
    .subMenu {
      display: flex;
      align-items: center;
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $color_10;
      cursor: pointer;
      height: 42px;
      padding: 0 15px;
      transition: all 0.5s;
      margin-bottom: 10px;
      &:hover {
        background: #f7f7f7;
        border-radius: 20px;
        color: $color_1;
        svg {
          fill: #4b4b4b;
        }
      }
      svg {
        margin-right: 15px;
        fill: #ffffff;
      }
    }
  }
}
.sliderOfertas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 10px;
  height: 105px;
  .btnSteps {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid #df0c30;
    cursor: pointer;
  }
  .contentSliderImg {
    transition: opacity 1s ease-out;
    opacity: 0;
    width: 0;
    overflow: hidden;
    a {
      cursor: pointer;
      img {
        max-height: 30px;
      }
    }
  }
  .contentSliderImg.active {
    width: 90%;
    display: flex;
    opacity: 1;
    align-items: center;
    justify-content: space-between;
  }
  .contentSliderImg.onlyMovil {
    display: none;
  }
}
.boxDestino {
  flex-wrap: wrap;
  width: 100%;
  .rowDestino {
    display: flex;
    flex: 1 1 100%;
    .colDestino {
      display: flex;
      gap: 15px;
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 13%;
      }
      &:nth-child(4) {
        width: 13%;
      }
      &:nth-child(5) {
        width: 13%;
      }
      &:first-child {
        padding-left: 60px;
      }
      &:last-child {
        padding-right: 60px;
      }
    }
    .colAero {
      display: flex;
      gap: 15px;
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:first-child {
        padding-left: 60px;
      }
      &:last-child {
        padding-right: 60px;
      }
    }
    .ida {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_1;
      margin-right: 20px;
      svg {
        margin-right: 10px;
      }
    }
    .vuelta {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_1;
      margin-right: 20px;
      svg {
        margin-right: 10px;
      }
    }
    .descrip {
      flex: auto;
      text-align: right;
    }
  }
  .rowAero {
    display: flex;
    flex: 1 1 100%;
    .colDestino {
      display: flex;
      gap: 15px;
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 13%;
      }
      &:nth-child(4) {
        width: 13%;
      }
      &:nth-child(5) {
        width: 13%;
      }
      &:first-child {
        padding-left: 60px;
      }
      &:last-child {
        padding-right: 60px;
      }
    }
    .colAero {
      display: flex;
      gap: 15px;
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:first-child {
        padding-left: 60px;
      }
      &:last-child {
        padding-right: 60px;
      }
    }
    .ida {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_1;
      margin-right: 20px;
      svg {
        margin-right: 10px;
      }
    }
    .vuelta {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_1;
      margin-right: 20px;
      svg {
        margin-right: 10px;
      }
    }
    .descrip {
      flex: auto;
      text-align: right;
    }
  }
  .rowDestino.center {
    justify-content: center;
  }
  .rowAero.center {
    justify-content: center;
  }
  .rowDestino.ordenCol {
    flex-direction: column;
    .boxVuelos {
      padding: 20px 0 50px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 0 50px;
      .rowVuelo {
        width: calc(33% - 35px);
        cursor: pointer !important;
        border-bottom: 1px solid #cfd1d3;
        border-top: 1px solid #cfd1d3;
        min-height: 53px;
        height: auto;
      }
    }
  }
  .rowAero.ordenCol {
    flex-direction: column;
    .boxVuelos {
      padding: 20px 0 50px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 0 50px;
      .rowVuelo {
        width: calc(33% - 35px);
        cursor: pointer !important;
        border-bottom: 1px solid #cfd1d3;
        border-top: 1px solid #cfd1d3;
        min-height: 53px;
        height: auto;
      }
    }
  }
  .tableDestino {
    display: flex;
    align-items: center;
    height: 70px;
    font-family: $font-family_5;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    border-bottom: 1px solid #cfd1d3;
    justify-content: space-between;
    transition: all 0.3s;
    &:hover {
      filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.15));
      background: #ffffff;
    }
    .boldTarifa {
      font-family: $font-family_5;
      font-weight: bold;
      font-size: 40px;
      line-height: 36px;
      color: $color_1;
    }
    .btnBuscar {
      display: flex;
      align-items: center;
      background: #df0c30;
      border-radius: 3px;
      cursor: pointer;
      height: 43px;
      color: $color_10;
      padding: 0 15px;
      transition: all 0.3ms;
      &:hover {
        background: #b50a27;
      }
    }
  }
  .tableDestino.bold {
    font-weight: bold;
    height: 50px;
    filter: none;
    background: transparent !important;
  }
  .footerTable {
    margin: 40px 0;
    padding-bottom: 20px;
    text-align: center;
    &.text,
    .text {
      font-family: $font-family_6;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_1;
    }
    .masOfertas {
      margin-right: 60px;
    }
  }
  .titulo {
    display: flex;
    justify-content: center;
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 36px;
    margin-top: 20px;
    color: $color_1;
  }
  .subtitulo {
    display: flex;
    justify-content: center;
    font-family: $font-family_8;
    font-size: 20px;
    line-height: 36px;
    color: $color_1;
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
  .boxSearchHotel {
    width: 100%;
    background: #df0c30;
    padding: 60px 50px;
    margin-bottom: 50px;
    .titulo {
      color: $color_10 !important;
    }
    .subtitulo {
      color: $color_10 !important;
    }
  }
}
.boxAero {
  flex-wrap: wrap;
  width: 100%;
  .rowDestino {
    display: flex;
    flex: 1 1 100%;
    .colDestino {
      display: flex;
      gap: 15px;
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 13%;
      }
      &:nth-child(4) {
        width: 13%;
      }
      &:nth-child(5) {
        width: 13%;
      }
      &:first-child {
        padding-left: 60px;
      }
      &:last-child {
        padding-right: 60px;
      }
    }
    .colAero {
      display: flex;
      gap: 15px;
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:first-child {
        padding-left: 60px;
      }
      &:last-child {
        padding-right: 60px;
      }
    }
    .ida {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_1;
      margin-right: 20px;
      svg {
        margin-right: 10px;
      }
    }
    .vuelta {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_1;
      margin-right: 20px;
      svg {
        margin-right: 10px;
      }
    }
    .descrip {
      flex: auto;
      text-align: right;
    }
  }
  .rowAero {
    display: flex;
    flex: 1 1 100%;
    .colDestino {
      display: flex;
      gap: 15px;
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 13%;
      }
      &:nth-child(4) {
        width: 13%;
      }
      &:nth-child(5) {
        width: 13%;
      }
      &:first-child {
        padding-left: 60px;
      }
      &:last-child {
        padding-right: 60px;
      }
    }
    .colAero {
      display: flex;
      gap: 15px;
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:first-child {
        padding-left: 60px;
      }
      &:last-child {
        padding-right: 60px;
      }
    }
    .ida {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_1;
      margin-right: 20px;
      svg {
        margin-right: 10px;
      }
    }
    .vuelta {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.18px;
      color: $color_1;
      margin-right: 20px;
      svg {
        margin-right: 10px;
      }
    }
    .descrip {
      flex: auto;
      text-align: right;
    }
  }
  .rowDestino.center {
    justify-content: center;
  }
  .rowAero.center {
    justify-content: center;
  }
  .rowDestino.ordenCol {
    flex-direction: column;
    .boxVuelos {
      padding: 20px 0 50px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 0 50px;
      .rowVuelo {
        width: calc(33% - 35px);
        cursor: pointer !important;
        border-bottom: 1px solid #cfd1d3;
        border-top: 1px solid #cfd1d3;
        min-height: 53px;
        height: auto;
      }
    }
  }
  .rowAero.ordenCol {
    flex-direction: column;
    .boxVuelos {
      padding: 20px 0 50px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 0 50px;
      .rowVuelo {
        width: calc(33% - 35px);
        cursor: pointer !important;
        border-bottom: 1px solid #cfd1d3;
        border-top: 1px solid #cfd1d3;
        min-height: 53px;
        height: auto;
      }
    }
  }
  .tableDestino {
    display: flex;
    align-items: center;
    height: 70px;
    font-family: $font-family_6;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    border-bottom: 1px solid #cfd1d3;
    transition: all 0.3s;
    &:hover {
      filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.15));
      background: #ffffff;
    }
    .boldTarifa {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      color: $color_1;
    }
    .btnBuscar {
      display: flex;
      align-items: center;
      background: #df0c30;
      border-radius: 3px;
      cursor: pointer;
      height: 43px;
      color: $color_10;
      padding: 0 15px;
      transition: all 0.3ms;
      &:hover {
        background: #b50a27;
      }
    }
  }
  .tableDestino.bold {
    font-weight: bold;
    margin-top: 40px;
    height: 50px;
    filter: none;
    background: transparent !important;
  }
  .footerTable {
    margin: 40px 0;
    padding-bottom: 20px;
    .text {
      font-family: $font-family_6;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_1;
      margin: 0 20px 0 60px;
    }
    .masOfertas {
      margin-right: 60px;
    }
  }
  .titulo {
    display: flex;
    justify-content: center;
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-top: 20px;
    color: $color_1;
  }
  .subtitulo {
    display: flex;
    justify-content: center;
    font-family: $font-family_8;
    font-size: 20px;
    line-height: 36px;
    color: $color_1;
    margin-bottom: 50px;
  }
  .boxSearchHotel {
    width: 100%;
    background: #df0c30;
    padding: 60px 50px;
    margin-bottom: 50px;
    .titulo {
      color: $color_10 !important;
    }
    .subtitulo {
      color: $color_10 !important;
    }
  }
  .logoAero {
    justify-content: center;
    margin: 70px 0 40px 0;
  }
  .textAbout {
    width: 800px;
    font-family: $font-family_6;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: $color_1;
    text-align: center;
  }
  .boxAbout {
    display: none;
    width: 100%;
    background: #b50a27 url('./assets/aerolineas/lineas.png') no-repeat top left;
    padding: 25px 0;
    margin: 70px 0 0 0;
    .descrip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 10%;
      p {
        text-align: center;
        align-items: center;
        font-family: $font-family_6;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: $color_10;
      }
    }
    .sliderAero {
      margin-right: 5%;
      #carouselAero {
        height: auto;
        width: 581px;
        .carousel-control-prev {
          top: 40%;
          width: 47px;
          background: #4b4b4b;
          opacity: 0.5;
          height: 78px;
          border-radius: 0;
        }
        .carousel-control-next {
          top: 40%;
          width: 47px;
          background: #4b4b4b;
          opacity: 0.5;
          height: 78px;
          border-radius: 0;
        }
      }
    }
  }
  .tabSalidas {
    width: 100%;
    padding: 0 10%;
    background: #ffffff;
    padding-top: 45px;
    .contentTab {
      .footerTable {
        justify-content: flex-end;
      }
    }
    .opcionTab {
      margin: 48px 0;
    }
    .tabName {
      display: flex;
      align-items: center;
      background: #f7f7f7;
      border: 1px solid #e4e5e7;
      box-sizing: border-box;
      border-radius: 15px;
      font-family: $font-family_6;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      height: 44px;
      padding: 0 20px;
      cursor: pointer;
      transition: all 0.3s;
      color: $color_1;
      &:hover {
        background: #df0c30;
        border: 1px solid #df0c30;
        color: $color_10;
      }
      &:first-child {
        margin-right: 30px;
      }
    }
    .tabName.active {
      background: #df0c30;
      border: 1px solid #df0c30;
      color: $color_10;
    }
  }
}
.moreInfo {
  padding: 20px 26px 0 26px;
  .title {
    font-family: $font-family_8;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: $color_1;
    width: 100%;
  }
  .question {
    margin-top: 20px;
    font-family: $font-family_6;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: $color_1;
    padding-bottom: 9px;
    span {
      font-weight: 300;
      font-size: 12px;
      margin-left: 10px;
    }
  }
  .groupOption {
    display: flex;
    flex-wrap: wrap;
    .checkOption {
      font-family: $font-family_6;
      width: 20%;
      display: flex;
      label {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
        color: $color_1;
        padding-bottom: 9px;
      }
    }
    .groupRadio {
      display: flex;
      width: 100%;
    }
  }
}
.newBoxTab {
  display: flex;
  flex-wrap: wrap;
  padding-right: 23px;
  font-family: $font-family_10;
  .sectionTab {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 100%;
    gap: 15px;
    &:first-child {
      margin-bottom: 15px;
    }
    .columnBox {
      display: flex;
      flex: 1 1 25%;
      align-items: center;
      .boxFlotante {
        top: 130px;
      }
      app-pop-up-pasajero-vuelos {
        width: 100%;
        .colBoxtab {
          gap: 0;
        }
      }
      .fullWidht {
        width: 100%;
        height: 50px;
      }
      .boxDestino {
        flex: 1 1 100%;
        position: relative;
        .iconDestino {
          position: absolute;
          left: 10px;
          top: 12px;
          z-index: 1;
        }
      }
      .boxDate {
        flex: 1 1 100%;
        position: relative;
        .iconCalendar {
          position: absolute;
          left: 10px;
          top: 10px;
        }
      }
      .titleBox {
        font-weight: 500;
        margin-right: 50px;
      }
      .form-check {
        margin: 0;
        display: flex;
        gap: 5px;
        align-items: center;
        label {
          padding: 5px 0 0 0;
          font-weight: 600;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .form-control {
        width: 100%;
        padding: 0 !important;
        height: 50px;
        font-family: $font-family_6;
        font-size: 16px;
        text-transform: capitalize;
        color: $color_1;
        text-indent: 40px;
        border: 0.5px solid #e4e5e7;
        border-radius: 3px;
      }
      .colBoxtab {
        position: relative;
        display: flex;
        flex: 1 1 100%;
        .hideDate {
          left: 10px;
          .dropdown-menu {
            transform: initial !important;
            top: 50px !important;
          }
        }
        .form-control {
          z-index: 1;
        }
        .iconCalendar {
          position: absolute;
          left: 10px;
          top: 10px;
          z-index: 2;
        }
      }
    }
    .columnBox.gap20 {
      gap: 20px;
    }
    .columnBox.bntFullW {
      display: flex;
      flex: 1 1 auto;
      height: 50px;
      justify-content: center;
      align-items: center;
      .btn {
        display: flex;
        height: 50px;
        flex: 1 1 100%;
        justify-content: center;
        align-items: center;
      }
    }
    .columnBox.maxW {
      flex: 1 1 10%;
      max-width: 15%;
    }
    .colTabFilter {
      width: 10%;
      .fullWidht {
        height: 50px;
        width: 100%;
      }
    }
    app-tab-vuelos-multicity {
      width: 100%;
      .colBoxtab {
        position: relative;
        .hideDate {
          left: 10px;
          .dropdown-menu {
            transform: initial !important;
            top: 50px !important;
          }
        }
        .hideInput {
          left: -10px;
          position: absolute;
        }
        .form-control {
          text-indent: 40px;
        }
        .d-left {
          border: 0.5px solid #e4e5e7 !important;
          border-radius: 0 3px 3px 0;
        }
        .tab-autocomplete {
          .iconDestino {
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 2;
          }
        }
      }
      .colBoxtab.gap10 {
        gap: 10px;
      }
    }
  }
  .sectionTab.active {
    flex-direction: row-reverse;
    .columnBox {
      position: relative;
      .boxFlotante {
        top: 50px !important;
      }
    }
  }
}
.contentTabMobile {
  display: none;
}
.boxPackage {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
  .package {
    display: flex;
    align-items: center;
    width: 310px;
    height: 90px;
    background: #ffffff;
    border: 1px solid #e4e5e7;
    box-sizing: border-box;
    border-radius: 10px 0;
    padding: 40px;
    gap: 30px;
    .icono {
      position: relative;
      .eclipse {
        position: absolute;
        right: -12px;
        bottom: -5px;
      }
    }
  }
  .contentBox {
    display: none;
  }
}
.ofrecido {
  font-family: $font-family_6;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: $color_1;
  margin-bottom: 50px;
}
.cardDestino {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
  .boxCards {
    max-width: 434px;
    background: #ffffff;
    border: 1px solid #e4e5e7;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.2));
    }
    .imageHead {
      position: relative;
      height: auto;
      img {
        width: 100%;
        height: 264px;
      }
    }
  }
  .loadingBoxCard {
    display: none;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    padding: 20px;
    .iconLoading {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
      }
      .texto {
        color: $color_2;
      }
    }
  }
  .loadingBoxCard.show {
    display: flex;
  }
  .p-carousel-prev {
    background: url('./assets/prev.png') no-repeat;
    cursor: pointer;
  }
  .p-galleria-item-prev {
    background: url('./assets/prev.png') no-repeat;
    cursor: pointer;
  }
  .p-carousel-next {
    background: url('./assets/next.png') no-repeat;
    cursor: pointer;
  }
  .p-galleria-item-next {
    background: url('./assets/next.png') no-repeat;
    cursor: pointer;
  }
  .p-galleria-item {
    height: 340px;
    img {
      height: 100%;
    }
  }
  .cardTitulo {
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: $color_1;
    padding: 0px 15px 15px;
  }
  .cardDuracion {
    font-family: $font-family_6;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_1;
    padding: 0 25px 15px 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .cardStar {
    display: flex;
    padding: 0 25px 20px 25px;
    gap: 5px;
    .star {
      width: 18px;
      height: 17px;
      background: url('./assets/vuelos/star.png') no-repeat;
    }
    .star-mid {
      width: 18px;
      height: 17px;
      background: url('./assets/vuelos/star-mid.png') no-repeat;
    }
    .star-null {
      width: 18px;
      height: 17px;
      background: url('./assets/vuelos/star-null.png') no-repeat;
    }
  }
  .cardActividad {
    font-family: $font-family_6;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_1;
    padding: 0 25px 20px 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .cardOpinion {
    font-family: $font-family_6;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: $color_1;
    padding: 0 25px 20px 25px;
  }
}
.cardDestino.show {
  .p-carousel-prev {
    display: flex;
    padding: 18px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
    background-color: $background-color_10 !important;
    margin-bottom: 115px;
    background: url(./assets/left-white.png) center center no-repeat;
    &:hover {
      background-color: $background-color_1 !important;
    }
  }
  .p-carousel-next {
    display: flex;
    padding: 18px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
    background-color: $background-color_10 !important;
    margin-bottom: 115px;
    background: url(./assets/right-white.png) center center no-repeat;
    &:hover {
      background-color: $background-color_1 !important;
    }
  }
  .p-carousel-container {
    .p-carousel-items-content {
      .p-carousel-items-container {
        .p-carousel-item {
          .p-galleria-content {
            .p-galleria-item-prev {
              display: flex;
              padding: 18px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background-position: center center;
            }
            .p-galleria-item-next {
              display: flex;
              padding: 18px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background-position: center center;
            }
          }
        }
      }
    }
  }
}
.tabDestino {
  .p-galleria {
    .p-galleria-close {
      margin: 0.5rem;
      background: transparent;
      color: $color_26;
      width: 4rem;
      height: 4rem;
      transition:
        background-color 0.2s,
        color 0.2s,
        box-shadow 0.2s;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_26;
      }
    }
    .p-galleria-item-nav {
      background: transparent;
      color: $color_26;
      width: 4rem;
      height: 4rem;
      transition:
        background-color 0.2s,
        color 0.2s,
        box-shadow 0.2s;
      border-radius: 6px;
      margin: 0 0.5rem;
      cursor: pointer;
      .p-galleria-item-prev-icon {
        background: url(./assets/left-white.png) center center no-repeat;
        width: 24px;
        height: 24px;
      }
      .p-galleria-item-next-icon {
        background: url(./assets/right-white.png) center center no-repeat;
        width: 24px;
        height: 24px;
      }
      &:not(.p-disabled) {
        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: $color_26;
        }
      }
    }
    .p-galleria-caption {
      background: rgba(0, 0, 0, 0.5);
      color: $color_26;
      padding: 1rem;
    }
    .p-galleria-indicators {
      padding: 1rem;
      .p-galleria-indicator {
        button {
          background-color: $background-color_11;
          width: 1rem;
          height: 1rem;
          transition:
            background-color 0.2s,
            color 0.2s,
            box-shadow 0.2s;
          border-radius: 50%;
          &:hover {
            background: #adb5bd;
          }
        }
      }
      .p-galleria-indicator.p-highlight {
        button {
          background: #eff6ff;
          color: $color_27;
        }
      }
    }
  }
  .p-galleria-mask {
    background: rgba(0, 0, 0, 0.8);
  }
  .p-galleria.p-galleria-indicators-bottom {
    .p-galleria-indicator {
      margin-right: 0.5rem;
    }
  }
  .p-galleria.p-galleria-indicators-top {
    .p-galleria-indicator {
      margin-right: 0.5rem;
    }
  }
  .p-galleria.p-galleria-indicators-left {
    .p-galleria-indicator {
      margin-bottom: 0.5rem;
    }
  }
  .p-galleria.p-galleria-indicators-right {
    .p-galleria-indicator {
      margin-bottom: 0.5rem;
    }
  }
  .p-galleria.p-galleria-indicator-onitem {
    .p-galleria-indicators {
      background: rgba(0, 0, 0, 0.5);
      .p-galleria-indicator {
        button {
          background: rgba(255, 255, 255, 0.4);
          &:hover {
            background: rgba(255, 255, 255, 0.6);
          }
        }
      }
      .p-galleria-indicator.p-highlight {
        button {
          background: #eff6ff;
          color: $color_27;
        }
      }
    }
  }
  display: flex;
  padding: 50px 0 10px 0;
  .tabImgDest {
    border-radius: 50px 0 0 50px;
    overflow: hidden;
    flex: 1 1 50%;
    position: relative;
    .mascara {
      position: absolute;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 44.18%);
      border-radius: 0px 50px 50px 0px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .galeriaText {
      position: absolute;
      right: 40px;
      bottom: 20px;
      cursor: pointer;
      .texto {
        font-family: $font-family_8;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.18px;
        color: $color_10;
        svg {
          margin-left: 10px;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.cardHotel {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
  .boxCards {
    max-width: 434px;
    background: #ffffff;
    border: 1px solid #e4e5e7;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.2));
    }
    .imageHead {
      position: relative;
      height: auto;
      img {
        width: 100%;
        height: 264px;
      }
      .precio {
        position: absolute;
        left: 0;
        bottom: 63px;
        .desde {
          position: relative;
          font-family: $font-family_6;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.1px;
          padding-left: 15px;
          padding-bottom: 5px;
          color: $color_10;
          .linea {
            position: absolute;
            background: #df0c30;
            height: 2px;
            bottom: 12px;
            left: 10px;
            width: 70px;
          }
        }
        .tarifa {
          display: flex;
          align-items: center;
          font-family: $font-family_8;
          font-weight: bold;
          font-size: 30px;
          line-height: 36px;
          display: flex;
          align-items: center;
          color: $color_10;
          background: #df0c30;
          border-radius: 0 5px 5px 0;
          height: 45px;
          padding: 0 20px;
        }
      }
    }
  }
  .loadingBoxCard {
    display: none;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    padding: 20px;
    .iconLoading {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
      }
      .texto {
        color: $color_2;
      }
    }
  }
  .loadingBoxCard.show {
    display: flex;
  }
  .p-carousel-prev {
    background: url('./assets/prev.png') no-repeat;
  }
  .p-galleria-item-prev {
    background: url('./assets/prev.png') no-repeat;
  }
  .p-carousel-next {
    background: url('./assets/next.png') no-repeat;
  }
  .p-galleria-item-next {
    background: url('./assets/next.png') no-repeat;
  }
  .p-galleria-item {
    height: 215px;
    border-radius: 11px 11px 0 0;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
  .cardTitulo {
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    color: $color_1;
    padding: 0px 15px;
  }
  .cardStar {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 20px 25px;
    flex-direction: column;
    gap: 12px;
    .estrellas {
      display: flex;
      width: 100%;
      gap: 5px;
      .star {
        width: 18px;
        height: 17px;
        background: url('./assets/vuelos/star.png') no-repeat;
      }
      .star-mid {
        width: 18px;
        height: 17px;
        background: url('./assets/vuelos/star-mid.png') no-repeat;
      }
      .star-null {
        width: 18px;
        height: 17px;
        background: url('./assets/vuelos/star-null.png') no-repeat;
      }
    }
    .iconos {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 5px;
    }
  }
  .cardOpinion {
    font-family: $font-family_6;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $color_7;
    padding: 0 25px 20px 25px;
  }
  .cardEvaluacion {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 25px 10px 25px;
    .groupItems {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      .itemsCardEvaluacion {
        margin-bottom: 10px;
        width: 50%;
        img {
          height: 32px;
        }
      }
      .itemsCardEvaluacion.aRight {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .p-carousel-items-container {
    gap: 20px;
    .p-carousel-item {
      flex: 1 1 30% !important;
      .product-item {
        background: #ffffff;
        border-radius: 11px;
        .product-item-content {
          margin: 0;
        }
      }
      .cardTituloNext {
        display: flex;
        justify-content: space-between;
        padding: 10px 25px;
        .nameTitle {
          font-family: $font-family_8;
          font-weight: bold;
          font-size: 20px;
          line-height: 36px;
          color: $color_1;
        }
        .btnNext {
          width: 38px;
          min-width: 38px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-family: $font-family_7;
          border-radius: 50%;
          background: #df0c30;
          padding: 0;
          color: $color_10 !important;
          text-decoration: none;
          cursor: pointer;
          transition: all 200ms ease-in-out;
        }
      }
    }
  }
  .p-element {
    .p-carousel-content {
      .p-carousel-container {
        .p-carousel-prev {
          display: none !important;
        }
        .p-carousel-next {
          display: none !important;
        }
        .p-carousel-items-content {
          padding-bottom: 30px;
        }
      }
    }
  }
}
.cardHotel.show {
  .p-carousel-prev {
    display: flex;
    padding: 18px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
    background-color: $background-color_10 !important;
    background: url(./assets/left-white.png) center center no-repeat;
    &:hover {
      background-color: $background-color_1 !important;
    }
  }
  .p-carousel-next {
    display: flex;
    padding: 18px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
    background-color: $background-color_10 !important;
    background: url(./assets/right-white.png) center center no-repeat;
    &:hover {
      background-color: $background-color_1 !important;
    }
  }
  .p-carousel-container {
    .p-carousel-items-content {
      .p-carousel-items-container {
        .p-carousel-item {
          .p-galleria-content {
            .p-galleria-item-prev {
              display: flex;
              padding: 18px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background-position: center center;
            }
            .p-galleria-item-next {
              display: flex;
              padding: 18px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background-position: center center;
            }
          }
        }
      }
    }
  }
}
.companyCar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 0;
  background: #ffffff;
  .iconCenter {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .carTitulo {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: $color_1;
  }
  .carSubtitulo {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: $font-family_8;
    font-size: 20px;
    line-height: 36px;
    color: $color_1;
    margin-bottom: 50px;
  }
  .contentCompany {
    display: flex;
    .imgCar {
      display: flex;
      align-items: end;
      width: 50%;
      img {
        width: 100%;
        height: fit-content;
      }
    }
    .logosCompany {
      width: 50%;
      .imgLogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
      }
      .centerCard {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .btnReservar {
          display: flex;
          align-items: center;
          background: #df0c30;
          border-radius: 3px;
          color: $color_10;
          padding: 0 40px;
          height: 50px;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            background: #b50a27;
          }
        }
      }
    }
  }
}
.tabContentDest {
  flex: 1 1 40%;
  padding: 0 45px;
  .titleTabDest {
    display: flex;
    width: 100%;
    padding: 40px 0 10px 0;
    gap: 15px;
    font-family: $font-family_6;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: $color_1;
    border-bottom: 1px solid #e4e5e7;
  }
  .nameCity {
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: $color_1;
    margin: 30px 0 30px 20px;
  }
  .opcionTab {
    display: flex;
    gap: 30px;
    margin-bottom: 35px;
    margin-left: 20px;
    .tabName {
      display: flex;
      align-items: center;
      background: #f7f7f7;
      border: 1px solid #e4e5e7;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: $font-family_6;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      height: 44px;
      padding: 0 20px;
      cursor: pointer;
      transition: all 0.3s;
      color: $color_1;
      &:hover {
        background: #df0c30;
        border: 1px solid #df0c30;
        color: $color_10;
      }
    }
    .tabName.active {
      background: #df0c30;
      border: 1px solid #df0c30;
      color: $color_10;
    }
  }
  .tabColumna {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 180px;
    .contenidoTab {
      flex: 1 1 45%;
      padding-top: 30px;
    }
    .imagenTab {
      display: flex;
      justify-content: flex-end;
      flex: 1 1 auto;
      .galeria {
        width: 100%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
  .contOpcion {
    font-family: $font-family_6;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.15px;
    margin-left: 20px;
    color: $color_1;
  }
}
.portadaAero {
  width: 100%;
  position: relative;
  margin-top: 0;
  img {
    width: 100%;
  }
  .textoPortada {
    position: absolute;
    right: 15%;
    top: calc(50% - 50px);
    .aeroTitle {
      font-family: $font-family_8;
      font-weight: 700;
      font-size: 38px;
      line-height: 72px;
      text-align: center;
      letter-spacing: -0.5px;
      color: $color_2;
    }
    .aeroDescrip {
      font-family: $font-family_8;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.18px;
      color: $color_1;
    }
  }
}
.select-box {
  position: relative;
  display: block;
  width: 100%;
}
.select-box__current {
  position: relative;
  border: 1px solid #e4e5e7;
  height: 45px;
  border-radius: 5px;
  font-family: $font-family_6;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.25px;
  font-size: 14px;
  overflow: hidden;
  color: $color_5;
  cursor: pointer;
  outline: none;
  &:focus {
    + {
      .select-box__list {
        opacity: 1;
        animation-name: none;
        background: #ffffff;
        margin-top: 6px;
        .select-box__option {
          cursor: pointer;
          padding-bottom: 0;
        }
      }
    }
    .select-box__icon {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  transition: 0.2s ease;
}
.select-box__value {
  display: flex;
}
.select-box__input {
  display: none;
  &:checked {
    + {
      .select-box__input-text {
        display: flex;
        align-items: center;
        margin: 0;
        height: 47px;
      }
    }
  }
}
.select-box__input-text {
  font-family: $font-family_11;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.25px;
  font-size: 14px;
  color: $color_5;
  display: none;
  width: 100%;
  margin: 0;
  padding: 0 0 0 8px;
  height: 48px;
  cursor: pointer;
}
.select-box__list {
  filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.15));
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  animation-name: HideList;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  .select-box__option {
    display: flex;
    align-items: center;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.25px;
    font-family: $font-family_11;
    height: 35px;
    padding-left: 12px;
    background: #ffffff;
    font-size: 14px;
    color: $color_1;
    &:hover {
      color: $color_1;
      background-color: $background-color_12;
    }
    &:focus {
      color: $color_1;
      background-color: $background-color_12;
    }
  }
  li {
    margin-bottom: 0 !important;
    background: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
span.valid {
  font-size: 10px !important;
  color: $color_28 !important;
}
.isvalid {
  border: 1px solid red !important;
}
.ngb-dp-arrow-btn {
  background: transparent !important;
}
.font-12 {
  font-size: 12px !important;
}
input.inputDer {
  text-indent: 40px !important;
}
input.inputIzq {
  text-indent: 40px !important;
}
.hideDate {
  max-width: 0 !important;
  width: 0 !important;
  position: absolute;
}
.ignore-template {
  display: contents;
}
.ngb-dp-week {
  [role='gridcell'].disabled {
    background: #e0e0e0;
  }
}
.contentImg {
  top: 0;
  position: absolute;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  img {
    position: absolute;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
    height: 100%;
    max-width: 100%;
    width: 100%;
  }
}
.banner {
  > .content {
    top: 0;
    position: absolute;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    height: 440px;
    border-radius: 0px 0px 20px 20px;
    img {
      position: absolute;
      object-fit: cover;
      -o-object-position: top;
      object-position: top;
      height: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
  > .contentEmpresa {
    top: 0;
    position: absolute;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    height: 659px;
    img {
      position: absolute;
      object-fit: cover;
      -o-object-position: top;
      object-position: top;
      height: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
  width: 100%;
  height: 540px;
  padding: 0 !important;
  position: relative;
  overflow: inherit;
  display: flex;
  align-items: flex-end;
}
.capital {
  font-family: $font-family_8;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: -0.5px;
  color: $color_1;
  line-height: 54px;
  .raya {
    background: #f29704;
    height: 2px;
    width: 43px;
    margin-bottom: 10px;
  }
  &::first-letter {
    color: $color_28 !important;
    text-indent: 50px;
  }
  span {
    font-family: $font-family_6;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: $color_1;
    line-height: 16px;
  }
  .f-12 {
    font-size: 12px;
    line-height: 20px;
  }
}
.mat-icon.icon {
  + {
    div {
      font-family: $font-family_8;
      font-weight: 700;
      font-size: 40px;
      letter-spacing: -0.5px;
      color: $color_1;
      line-height: 54px;
      .raya {
        background: #f29704;
        height: 2px;
        width: 43px;
        margin-bottom: 10px;
      }
      &::first-letter {
        color: $color_28 !important;
        text-indent: 50px;
      }
      span {
        font-family: $font-family_6;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: $color_1;
        line-height: 16px;
      }
      .f-12 {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  font-size: 56px;
  width: 56px;
  height: 56px;
  display: block;
  margin: 20px;
}
.h659 {
  height: 659px !important;
}
.video-responsive {
  margin-top: 260px;
}
.text-white {
  color: $color_13;
}
.text-justify {
  text-align: justify;
}
.btn-agencias {
  border: 1px solid #e4e5e7;
  background: white;
  color: $color_1;
  border-radius: 10px;
  letter-spacing: 0.15px;
  font-family: $font-family_8;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  &:hover {
    background: #e4e5e7;
  }
}
.btn-agencias.activa {
  border: 1px solid #df0c30;
  background: #df0c30;
  color: $color_13;
}
.btn.primary {
  font-family: $font-family_6;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #df0c30;
  background: #df0c30;
  color: $color_13;
  border-radius: 3px;
  padding: 10px 16px;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background: #b50a27;
  }
}
.btn.secundary {
  font-family: $font-family_6;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #ebebeb;
  background: #ebebeb;
  color: $color_1;
  border-radius: 3px;
  padding: 10px 16px;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background: #e4e5e7;
  }
}
.carousel-indicators {
  z-index: 1;
}
button.pasajero {
  position: relative;
  width: 100%;
  font-weight: 600;
  border: 0px;
  background-color: $background-color_3;
  font-family: $font-family_5;
  height: 50px;
  font-size: 16px;
  color: $color_1;
  text-align: center;
  border: 0.5px solid #e4e5e7;
  border-radius: 3px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  transition: 0.3s all;
  &:hover {
    background: #e4e5e7;
  }
}
button.customers {
  position: relative;
  width: 190px;
  font-weight: 600;
  border: 0px;
  font-size: 14px;
  color: $color_22;
  margin-top: 7px;
  background-color: $background-color_3;
  padding-left: 0;
  text-align: left;
  font-family: $font-family_5;
  padding-top: 13px;
  &:before {
    content: url(./assets/arrow.svg);
    right: 0;
    position: absolute;
  }
}
.show-scrollTop {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  background-color: $background-color_13;
}
#ModalSesion {
  .mat-tab-label {
    width: 50%;
  }
  .modal-header {
    p {
      font-size: 14px;
    }
  }
  .modal-dialog {
    width: 490px;
  }
  .modal-body {
    .elementRow {
      display: flex;
      position: relative;
      .msgError {
        display: none;
        margin-left: 15px;
        font-weight: 300;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: $color_15;
        margin-bottom: 10px;
      }
      .form-control {
        margin-bottom: 20px;
        font-family: $font-family_6;
        font-size: 14px;
        border-radius: 5px;
        height: 47px;
        border: 1px solid #ced4da;
      }
      .iconPass {
        position: absolute;
        cursor: pointer;
        right: 25px;
        top: 10px;
      }
    }
    .elementRow.error {
      flex-direction: column;
      .form-control {
        margin-bottom: 0px !important;
        border: 0.5px solid #f44336;
      }
      .msgError {
        display: flex;
      }
    }
    .subRayado {
      text-decoration: underline;
    }
    .mat-ink-bar {
      height: 3px;
    }
    hr {
      border: 1px solid #e4e5e7;
    }
    .textConecta {
      font-family: $font-family_6;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_1;
    }
    .socialMedia {
      display: flex;
      gap: 20px;
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.15px;
      color: $color_10;
      .btnFacebook {
        width: 100%;
        height: 47px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #4267b2;
        img {
          min-width: 25.19px;
          min-height: 25.19px;
          max-width: 25.19px;
          max-height: 25.19px;
          margin-right: 12px;
        }
      }
      .btnGoogle {
        width: 100%;
        height: 47px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #518ef8;
        img {
          min-width: 25.19px;
          min-height: 25.19px;
          max-width: 25.19px;
          max-height: 25.19px;
          margin-right: 12px;
        }
      }
    }
  }
  .modal-footer {
    padding: 0rem 2rem 0rem 2rem;
    margin: 0 0 18px 0;
    border-top: none;
    button {
      background: #df0c30;
      border-radius: 5px;
      margin: 0 0 18px 0;
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.18px;
      color: $color_10;
    }
    .account {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 10px;
      .notRegister {
        font-family: $font-family_6;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: $color_1;
      }
      .newAccount {
        cursor: pointer;
        font-family: $font-family_6;
        font-weight: 600;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $color_8;
        text-decoration: underline;
      }
    }
  }
}
.forgot-password-modal {
  .modal-dialog {
    width: 918px;
    max-width: 918px;
    .modal-content {
      .modal-content {
        flex-direction: row;
        border: none;
        border-radius: 10px;
        .recoveryLeft {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 419px;
          height: 363px;
          background: url('./assets/modal/bg-recovery.png');
          .titleRecovery {
            font-family: $font-family_12;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.1px;
            color: $color_10;
            margin-top: 40px;
            padding-bottom: 13px;
          }
          img {
            width: 127.02px;
          }
        }
        .recoveryRight {
          flex: 1 1 auto;
          padding: 0 30px;
          position: relative;
          .modal-header {
            border: none;
          }
          .btn-close {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 12px;
          }
          .titleRecovery {
            font-family: $font-family_8;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: $color_1;
            margin: 40px 0 25px 0;
          }
          .elementRow {
            display: flex;
            margin-bottom: 0;
            .textError {
              display: none;
              margin-left: 15px;
              font-weight: 300;
              font-size: 11px;
              font-family: $font-family_13;
              line-height: 18px;
              color: $color_15;
              margin-bottom: 10px;
            }
            .form-control {
              margin-bottom: 20px;
              font-family: $font-family_6;
              font-size: 14px;
              border-radius: 5px;
              height: 41px;
              border: 1px solid #ced4da;
              margin-bottom: 30px;
            }
            .divValida {
              flex-direction: column;
              flex: 1 1 auto;
              position: relative;
              .textError {
                display: none;
                position: absolute;
                margin-left: 15px;
                font-weight: 300;
                font-size: 11px;
                color: $color_15;
                bottom: 3px;
              }
            }
            .divValida.error {
              .form-control {
                border: 0.5px solid #f44336;
              }
              .form-select {
                border: 0.5px solid #f44336;
              }
              .textError {
                display: flex;
              }
            }
          }
          .elementRow.error {
            flex-direction: column;
            .form-select {
              margin-bottom: 0px !important;
              border: 0.5px solid #f44336;
            }
            .form-control {
              margin-bottom: 0px !important;
              border: 0.5px solid #f44336;
            }
            .textError {
              display: flex;
            }
          }
          button {
            margin-bottom: 20px;
            font-family: $font-family_8;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.15px;
            border-radius: 5px;
          }
          .account {
            display: flex;
            justify-content: center;
            width: 100%;
            gap: 10px;
            margin-bottom: 20px;
            .notRegister {
              font-family: $font-family_6;
              font-weight: 300;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.25px;
              color: $color_1;
            }
            .newAccount {
              cursor: pointer;
              font-family: $font-family_6;
              font-weight: 600;
              font-size: 12px;
              line-height: 21px;
              letter-spacing: 0.1px;
              color: $color_8;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
.new-account-modal {
  .modal-dialog {
    width: 918px;
    max-width: 918px;
    .modal-content {
      flex-direction: row;
      border: none;
      border-radius: 10px;
      .recoveryLeft {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 419px;
        height: 363px;
        background: url('./assets/modal/bg-recovery.png');
        background: url('./assets/modal/bg-newAccount.png');
        height: auto;
        background-size: cover;
        .titleRecovery {
          font-family: $font-family_12;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.1px;
          color: $color_10;
          margin-top: 40px;
          padding-bottom: 13px;
        }
        img {
          width: 127.02px;
        }
      }
      .recoveryRight {
        flex: 1 1 auto;
        padding: 0 30px;
        position: relative;
        .modal-header {
          border: none;
        }
        .btn-close {
          position: absolute;
          right: 20px;
          top: 20px;
          font-size: 12px;
        }
        .titleRecovery {
          font-family: $font-family_8;
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          color: $color_1;
          margin: 40px 0 25px 0;
          margin: 10px 0 10px 0;
        }
        .elementRow {
          display: flex;
          margin-bottom: 0;
          display: flex;
          gap: 15px;
          .textError {
            display: none;
            margin-left: 15px;
            font-weight: 300;
            font-size: 11px;
            font-family: $font-family_13;
            line-height: 18px;
            color: $color_15;
            margin-bottom: 10px;
          }
          .form-control {
            margin-bottom: 20px;
            font-family: $font-family_6;
            font-size: 14px;
            border-radius: 5px;
            height: 41px;
            border: 1px solid #ced4da;
            margin-bottom: 30px;
            font-size: 14px;
            border-radius: 5px;
            height: 41px;
            border: 1px solid #ced4da;
          }
          .divValida {
            flex-direction: column;
            flex: 1 1 auto;
            position: relative;
            .textError {
              display: none;
              position: absolute;
              margin-left: 15px;
              font-weight: 300;
              font-size: 11px;
              color: $color_15;
              bottom: 3px;
            }
          }
          .divValida.error {
            .form-control {
              border: 0.5px solid #f44336;
            }
            .form-select {
              border: 0.5px solid #f44336;
            }
            .textError {
              display: flex;
            }
          }
          .form-select {
            margin-bottom: 30px;
            font-size: 14px;
            border-radius: 5px;
            height: 41px;
            border: 1px solid #ced4da;
          }
          .form-email-input {
            text-transform: none;
          }
          .divValida.wSelect {
            min-width: calc(50% - 8px);
          }
        }
        .elementRow.error {
          flex-direction: column;
          .form-select {
            margin-bottom: 0px !important;
            border: 0.5px solid #f44336;
          }
          .form-control {
            margin-bottom: 0px !important;
            border: 0.5px solid #f44336;
          }
          .textError {
            display: flex;
          }
        }
        button {
          margin-bottom: 20px;
          font-family: $font-family_8;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.15px;
          border-radius: 5px;
          margin-bottom: 10px;
        }
        .account {
          display: flex;
          justify-content: center;
          width: 100%;
          gap: 10px;
          margin-bottom: 20px;
          .notRegister {
            font-family: $font-family_6;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.25px;
            color: $color_1;
          }
          .newAccount {
            cursor: pointer;
            font-family: $font-family_6;
            font-weight: 600;
            font-size: 12px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: $color_8;
            text-decoration: underline;
          }
        }
        .mat-tab-header {
          margin-bottom: 25px;
        }
        .mat-tab-label {
          width: 50%;
        }
        .mat-ink-bar {
          background-color: $background-color_1 !important;
          height: 3px !important;
          min-width: 219px !important;
        }
        .textPass {
          font-family: $font-family_6;
          font-weight: 300;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.25px;
          margin-bottom: 10px;
        }
        .textPolitica {
          display: block;
          font-family: $font-family_12;
          font-weight: 300;
          font-size: 12px;
          letter-spacing: 0.25px;
          text-align: center;
          a {
            font-family: $font-family_6;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.1px;
            font-weight: 600;
            color: $color_8;
          }
        }
        .socialMedia {
          display: none;
          flex-direction: column;
          gap: 11px;
          font-family: $font-family_8;
          font-weight: bold;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.15px;
          color: $color_10;
          margin-bottom: 10px;
          .btnFacebook {
            position: relative;
            width: 100%;
            height: 41px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #4267b2;
            img {
              position: absolute;
              left: 33px;
              min-width: 25.19px;
              min-height: 25.19px;
              max-width: 25.19px;
              max-height: 25.19px;
              margin-right: 12px;
            }
          }
          .btnGoogle {
            position: relative;
            width: 100%;
            height: 41px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #518ef8;
            img {
              position: absolute;
              left: 33px;
              min-width: 25.19px;
              min-height: 25.19px;
              max-width: 25.19px;
              max-height: 25.19px;
              margin-right: 12px;
            }
          }
        }
        .socialMedia.active {
          display: flex;
        }
      }
    }
  }
}
#ModalDatosOpcionales {
  .modal-header {
    padding: 20px 2rem;
  }
  .modal-body {
    .personalDocument {
      border: none;
      .headerPortlet {
        padding: 0;
        .titleBoxRight {
          margin: 10px 0;
        }
      }
      .rowContent {
        .inputGroup {
          display: block !important;
        }
      }
      .msgBox {
        flex: 1 1 auto;
        padding: 10px;
        border-radius: 10px;
      }
      .msgBox.success {
        color: $color_29;
        background-color: $background-color_14;
        border-color: $border-color_1;
      }
      .mat-checkbox {
        label {
          padding-bottom: 0 !important;
        }
      }
      span.option {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  .titleVerifica {
    font-family: $font-family_7;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: $color_1;
  }
  .portlet-der {
    margin: 0;
  }
  .headerPortlet {
    .imgHead {
      width: 100%;
    }
  }
  input.form-control {
    margin-bottom: 0 !important;
  }
}
.ModalVerificaCorreo {
  .modal-dialog {
    width: 490px;
  }
}
.ModalUsuarioVerificado {
  .modal-dialog {
    width: 490px;
  }
}
#ModalEliminaCorreo {
  .modal-header {
    .iconCenter {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .titleVerifica {
      display: flex;
      justify-content: center;
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      color: $color_1;
      margin-bottom: 10px;
      width: 100%;
    }
    img {
      margin: 0 0 20px 0;
    }
  }
  .modal-dialog {
    width: 490px;
  }
  .modal-body {
    .descripText {
      display: block;
      text-align: center;
      font-family: $font-family_6;
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_1;
      padding-bottom: 20px;
      span {
        font-weight: 600;
        font-size: 12px;
        color: $color_8;
      }
    }
    .action {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      gap: 65px;
    }
  }
}
.ModalChangePass {
  .modal-dialog {
    width: 490px;
  }
}
.boxReclamaciones {
  display: flex;
  .contLeft {
    width: 35%;
    .virtual {
      color: $color_1;
      font-family: $font-family_6;
      padding: 68px 30px 30px 50px;
      .titleVirtual {
        display: block;
        font-family: $font-family_8;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 48px;
        padding-bottom: 45px;
        span {
          color: $color_3;
          font-weight: bold;
        }
      }
      .contentVirtual {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 27px;
        text-align: justify;
        letter-spacing: 0.18px;
        padding-bottom: 41px;
      }
      .marcaOrange {
        width: 43px;
        height: 2px;
        left: 59px;
        top: 476px;
        background: #f29704;
        margin-bottom: 41px;
      }
      .boxImg {
        width: 100%;
        margin-bottom: 41px;
        img {
          width: 100%;
        }
      }
      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.18px;
        margin-bottom: 25px;
      }
      .btnHelp {
        background: #df0c30;
        border-radius: 3px;
        padding: 14px 0;
        cursor: pointer;
        width: 180px;
        color: $color_10;
        font-family: $font-family_6;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        margin: 0 auto;
      }
    }
  }
  .contRight {
    width: 65%;
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin: 68px 60px 20px 30px;
    padding: 45px 70px;
    color: $color_1;
    font-family: $font-family_6;
    .rowGroup {
      display: flex;
      margin-bottom: 20px;
      .colLeft {
        width: 40%;
        font-weight: 600;
        font-size: 14px;
        line-height: 27px;
        letter-spacing: 0.18px;
      }
      .colRight {
        width: 60%;
        font-size: 14px;
        line-height: 27px;
        letter-spacing: 0.18px;
      }
      .texto {
        font-size: 14px;
        line-height: 27px;
        letter-spacing: 0.18px;
      }
      .texto.mTop {
        margin-top: 30px;
      }
      .subTitle {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.25px;
      }
    }
    .rowGroup.columna {
      flex-direction: column;
      .col {
        &:first-child {
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
        }
      }
      .subtitle {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.18px;
        margin-bottom: 10px;
      }
      .twoCol {
        display: flex;
        gap: 20px;
        .tipo {
          .text {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.25px;
          }
          width: 30%;
        }
        .numero {
          .text {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.25px;
          }
          width: 70%;
          .campo {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.18px;
          }
        }
        .tipo.dFlex {
          display: flex;
        }
        .numero.dFlex {
          display: flex;
        }
      }
      .twoCol.onlyMovil {
        flex-wrap: wrap;
      }
    }
    .form-control {
      border: 1px solid #e4e5e7;
      border-radius: 5px;
      height: 45px;
    }
    textarea {
      border: 1px solid #e4e5e7;
      border-radius: 5px;
      width: 100%;
    }
    .separador {
      margin: 42px 0;
      width: 100%;
      height: 1px;
      background: #e4e5e7;
    }
    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.18px;
      margin-bottom: 30px;
    }
    .form-check-input {
      width: 21px;
      height: 21px;
    }
    .form-check-label {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.25px;
      color: $color_8;
      a {
        color: $color_3;
      }
    }
    .form-radio-label {
      font-weight: normal;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: 0.18px;
      padding-bottom: 0;
    }
    .btnEnviar {
      background: #df0c30;
      border-radius: 3px;
      padding: 14px 0;
      cursor: pointer;
      width: 140px;
      color: $color_10;
      font-family: $font-family_6;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5px;
    }
  }
}
.boxUser {
  position: relative;
  .userlogged {
    padding-right: 15px;
    padding-left: 10px;
    cursor: pointer;
  }
  .divFlotante {
    position: absolute;
    right: 0;
    z-index: 101;
    top: 45px;
    background: #ffffff;
    border-radius: 5px;
    filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.15));
    padding: 10px 20px;
    &:after {
      display: block;
      content: ' ';
      position: absolute;
      top: -6px;
      border-top: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid transparent;
      background: #ffffff;
      right: 23px;
      transform: rotate(45deg);
    }
    .option {
      font-family: $font-family_6;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: $color_8;
      cursor: pointer;
      transition: 0.3s all;
      padding: 2px 0;
      &:hover {
        color: $color_16;
      }
    }
    .option.red {
      color: $color_2;
      &:hover {
        color: $color_3;
      }
    }
    .line {
      height: 1px;
      background: #e4e5e7;
      margin: 2px 0;
    }
    .correo {
      display: flex;
      font-family: $font-family_6;
      font-weight: 500;
      height: 10px;
      font-size: 10px;
      line-height: 10px;
      color: $color_16;
      img {
        margin-right: 5px;
      }
    }
  }
}
.cursorPointer {
  cursor: pointer;
}
mat-dialog-container {
  padding: 20px;
  .title {
    font-family: $font-family_8;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: $color_1;
    text-align: center;
    margin-bottom: 15px;
  }
  .descrip {
    font-family: $font-family_6;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: $color_1;
    padding-bottom: 20px;
  }
  .groupBtn {
    display: flex;
    justify-content: center;
    gap: 20px;
    .boton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      font-family: $font-family_5;
      font-size: 16px;
      border-radius: 3px;
      height: 40px;
      cursor: pointer;
      transition: 300ms ease;
    }
    .boton.primary {
      background-color: $background-color_1;
      border: 1px solid #b60a35;
      color: $color_10;
      &:hover {
        background: #b60a35;
      }
    }
    .boton.secundary {
      background: #d9e2e8;
      border: 1px solid #bbcbd4;
      color: $color_30;
      &:hover {
        background: #bbcbd4;
      }
    }
  }
}
#carouselExampleIndicators {
  .carousel-control-prev {
    z-index: unset !important;
    &:hover {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
      border-radius: 10px 0 0 10px;
      .carousel-prev-icon {
        opacity: 1;
      }
    }
  }
  .carousel-control-next {
    z-index: unset !important;
    &:hover {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      border-radius: 0;
      .carousel-next-icon {
        opacity: 1;
      }
    }
  }
  .carousel-prev-icon {
    opacity: 0;
    transition: 0.3s all;
  }
  .carousel-next-icon {
    opacity: 0;
    transition: 0.3s all;
  }
}
.msgBoxBoletin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
  .titleBol {
    font-family: $font-family_1;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .iconBoletin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-bottom: 10px;
  }
}
.msgBoxBoletin.success {
  .iconBoletin {
    background: #add172;
  }
  .titleBol {
    color: $color_31;
  }
}
.msgBoxBoletin.error {
  .iconBoletin {
    background: #ff6969;
  }
  .titleBol {
    color: $color_2;
  }
}
.p-galleria-item-wrapper {
  .p-galleria-indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    font-size: 14px;
    list-style: none;
    padding: 10px 0;
    bottom: -310px !important;
    .p-galleria-indicator {
      margin-right: 0.5rem;
      height: 14px;
      .p-link {
        display: inline-flex;
        align-items: center;
        background-color: $background-color_16;
        transition:
          background-color 0.2s,
          color 0.2s,
          box-shadow 0.2s;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        padding: 0;
      }
    }
    .p-galleria-indicator.p-highlight {
      .p-link {
        background-color: $background-color_1;
      }
    }
  }
}
app-flight-deals {
  button {
    background: none !important;
  }
}
.p-galleria {
  .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: $color_26;
    width: 4rem;
    height: 4rem;
    transition:
      background-color 0.2s,
      color 0.2s,
      box-shadow 0.2s;
    border-radius: 50%;
    cursor: pointer;
  }
}
.p-galleria-mask {
  background: rgba(0, 0, 0, 0.8) !important;
  .p-galleria {
    .p-galleria-close {
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_26;
      }
    }
    .p-galleria-item-nav {
      background: transparent;
      color: $color_26;
      width: 4rem;
      height: 4rem;
      transition:
        background-color 0.2s,
        color 0.2s,
        box-shadow 0.2s;
      border-radius: 6px;
      margin: 0 0.5rem;
      cursor: pointer;
      .p-galleria-item-prev-icon {
        background: url(./assets/left-white.png) center center no-repeat;
        width: 24px;
        height: 24px;
      }
      .p-galleria-item-next-icon {
        background: url(./assets/right-white.png) center center no-repeat;
        width: 24px;
        height: 24px;
      }
      &:not(.p-disabled) {
        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: $color_26;
        }
      }
    }
  }
}
.oneWayCalendar {
  .ngb-dp-month {
    &:first-child {
      border-right: 0 !important;
      padding-right: 0 !important;
    }
  }
}
.boxBotones {
  gap: 10px;
  .btnMovil {
    background: #ffffff;
    border: 1px solid #e4e5e7;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: $font-family_6;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: $color_1;
    img {
      margin-right: 7px;
    }
  }
}
.pointer {
  cursor: pointer;
}
.gridTab {
  display: flex;
  flex: 1 1 100%;
  .rowGrid {
    display: flex;
    width: 100%;
    gap: 10px;
    .colGrid {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex: 1 1 auto;
      gap: 10px;
      .colElement {
        display: flex;
        width: 100%;
        input {
          border: 0.5px solid #e4e5e7;
          border-radius: 5px;
          height: 48px;
          padding: 0 10px;
        }
      }
    }
    .colGrid.fullWidht {
      button {
        width: 100%;
        height: 49px;
      }
    }
  }
}

.boxCalendar {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 600px;
  padding: 20px;
  position: absolute;
  z-index: 100;
  top: 130px;
  left: 36.5% !important;
  .groupCalendario {
    display: flex;
    font-family: $font-family_10;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: $color_18;
    .calendarStart {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #83898f;
      .headCalendar {
        display: flex;
        width: 260px;
        .iconMes {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 37px;
          cursor: pointer;
          transition: 0.3s all;
          &:hover {
            .red {
              display: flex;
            }
            .gray {
              display: none;
            }
          }
          .red {
            display: none;
          }
        }
        .iconMes.alignRigth {
          justify-content: flex-end;
        }
        .nameMes {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - 74px);
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: 0.5px;
          text-transform: capitalize;
          color: $color_18;
          gap: 5px;
          .anio {
            .form-select {
              border: none;
              font-weight: 600;
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.5px;
              text-transform: capitalize;
              color: $color_18;
              padding: 5px 2.25rem 5px 5px;
              cursor: pointer;
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
      .bodyCalendar {
        display: flex;
        flex-direction: column;
        width: 260px;
        .groupName {
          display: flex;
          .nameDay {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 37px;
            height: 40px;
          }
        }
        .groupDay {
          display: flex;
          margin-bottom: 4px;
          .dayNumber {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            transition: 0.3s all;
            width: 37px;
            height: 40px;
            span {
              color: $color_33;
              width: 37px;
              height: 20px;
              font-weight: 500;
              font-size: 9px;
              text-align: center;
              border-radius: 0 0 3px 3px;
            }
          }
          .dayNumber.disabled {
            color: $color_32;
          }
          .dayNumber.selectStar {
            background: #009aed;
            border-radius: 3px;
            color: $color_10;
            span {
              background: #54d834;
              color: $color_10;
            }
          }
          .dayNumber.selectEnd {
            flex-direction: column;
            background: #009aed;
            border-radius: 3px;
            color: $color_10;
            span {
              background: #54d834;
              color: $color_10;
            }
          }
          .dayNumber.bgBlue {
            background: #8fd0f3;
            color: $color_4;
            span {
              color: $color_10;
            }
          }
        }
      }
    }
    .calendarEnd {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .headCalendar {
        display: flex;
        width: 260px;
        justify-content: flex-end;
        .iconMes {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 37px;
          cursor: pointer;
          transition: 0.3s all;
          justify-content: flex-end;
          &:hover {
            .red {
              display: flex;
            }
            .gray {
              display: none;
            }
          }
          .red {
            display: none;
          }
        }
        .iconMes.alignRigth {
          justify-content: flex-end;
        }
        .nameMes {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - 74px);
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: 0.5px;
          text-transform: capitalize;
          color: $color_18;
          gap: 5px;
          .anio {
            .form-select {
              border: none;
              font-weight: 600;
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.5px;
              text-transform: capitalize;
              color: $color_18;
              padding: 5px 2.25rem 5px 5px;
              cursor: pointer;
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
      .bodyCalendar {
        display: flex;
        flex-direction: column;
        width: 260px;
        .groupName {
          display: flex;
          .nameDay {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 37px;
            height: 40px;
          }
        }
        .groupDay {
          display: flex;
          margin-bottom: 4px;
          .dayNumber {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            transition: 0.3s all;
            width: 37px;
            height: 40px;
            span {
              color: $color_33;
              width: 37px;
              height: 20px;
              font-weight: 500;
              font-size: 9px;
              text-align: center;
              border-radius: 0 0 3px 3px;
            }
          }
          .dayNumber.disabled {
            color: $color_32;
          }
          .dayNumber.selectStar {
            background: #009aed;
            border-radius: 3px;
            color: $color_10;
            span {
              background: #54d834;
              color: $color_10;
            }
          }
          .dayNumber.selectEnd {
            flex-direction: column;
            background: #009aed;
            border-radius: 3px;
            color: $color_10;
            span {
              background: #54d834;
              color: $color_10;
            }
          }
          .dayNumber.bgBlue {
            background: #8fd0f3;
            color: $color_4;
            span {
              color: $color_10;
            }
          }
        }
      }
    }
    .btnAction {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .btnReady {
        background: #df0c30;
        border-radius: 3px;
        cursor: pointer;
        transition: 0.3s all;
        padding: 9px 16px;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: $color_34;
      }
    }
  }
}
.onlyMobile {
  display: none !important;
}
.preloader {
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .loaderSpin {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: relative;
    animation: loaderCircle 1s linear infinite;
    top: 50%;
    margin: -8px auto 0;
  }
  .textPreload {
    color: $color_10;
    font-size: 12px;
    margin: 0 auto;
    width: 50%;
    text-align: center;
    top: 40%;
    position: relative;
  }
}
.preloader.show {
  display: block;
}
.promociones {
  max-width: 1370px;
  width: 80%;
  margin: auto;
  .titleM {
    border-left: 6px solid #b01630;
    padding-left: 9px;
    margin: 30px 0 40px;
    font-size: 25px;
    text-transform: uppercase;
    color: $color_35;
    font-weight: 300;
    line-height: 33px;
    letter-spacing: 0.7px;
    padding-bottom: 0;
    border-bottom: none;
  }
  ul {
    li {
      color: $color_36;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 29px;
      text-align: justify;
      h1 {
        font-size: 16px;
        font-weight: 600;
      }
      .subtitleP {
        margin-bottom: 20px;
        border-bottom: 1px solid #000;
      }
    }
  }
}
.boxCita {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  margin: 0;
  background: url('./assets/img/background.webp') no-repeat 50% 50%;
  background-size: cover;
  .boxLogo {
    width: 80%;
    margin: 0 auto 40px auto;
  }
  .boxGroupCita {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .boxOption {
      width: calc(50% - 30px);
      background: #ffffff;
      padding: 20px 30px;
      .titleOption {
        font-weight: 700;
        font-size: 22px;
        color: $color_37;
        margin-bottom: 15px;
      }
      .contentOption {
        display: flex;
        gap: 30px;
        margin-bottom: 20px;
        .iconLeft {
          min-width: 80px;
          img {
            width: 100%;
          }
        }
        .textContent {
          font-size: 16px;
          text-align: justify;
        }
      }
      .boxActionCita {
        display: flex;
        justify-content: flex-end;
        .btn {
          font-size: 14px;
        }
      }
    }
  }
}
.divPasMov {
  .divPas {
    .groupPasajero {
      display: flex;
      align-items: center;
      gap: 30px;
      .boxHelp {
        display: flex;
        align-items: center;
        margin-top: 7px;
        padding: 10px 20px;
        background-color: $background-color_17;
        color: $color_38;
        border-radius: 5px;
        gap: 10px;
        .iconArrow {
          display: flex;
          align-items: center;
        }
        .descrip {
          font-size: 14px;
        }
      }
    }
  }
}
.containerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24em;
    background: url(/assets/citas/header-desktop.webp);
    background-position: 50% 50%;
    background-size: cover;
  }
  .titleCanal {
    width: 100%;
    font-size: 32px;
    padding: 0 5%;
    justify-content: flex-end;
    display: flex;
    color: $color_4;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .boxCanales {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 5% 108px;
    .boxCardCanal {
      flex: 1 1 15%;
      flex-wrap: wrap;
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      border-radius: 15px;
      box-shadow: 0px 3px 12px #00000029;
      .boxIconCard {
        display: flex;
        justify-content: center;
        flex: 1 1 100%;
        .iconCard {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px;
          border-radius: 24px;
          margin-bottom: 20px;
        }
      }
      .titleBoxCanal {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
      }
      .descripCanal {
        width: 100%;
        min-height: 100px;
        text-align: center;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .buttonCard {
        width: 100%;
        .btnCanal {
          padding: 12px 0;
          border-radius: 12px;
          width: 100%;
          a {
            font-family: $font-family_5;
            font-size: 16px;
            color: $color_4;
            font-weight: normal;
          }
        }
      }
    }
  }
}
.containerError {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #b50a27 0%, #df0c30 100%);
  height: 100vh;
  width: 100%;
  gap: 100px;
  padding: 0 10%;
  .imgBoxError {
    display: flex;
    width: 150px;
    position: absolute;
    top: 18%;
    left: calc(50% - 75px);
    img {
      width: 100%;
    }
  }
  .msgError {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: $font-family_10;
    color: $color_10;
    position: relative;
    .titulo {
      font-weight: 700;
      font-size: 250px;
    }
    .mensaje {
      font-weight: 500;
      text-align: center;
      font-size: 24px;
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .boton {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      transition: 0.3s all;
      font-weight: 400;
      gap: 15px;
      font-size: 14px;
      padding: 10px 20px;
      margin-top: 30px;
      cursor: pointer;
      border: 1px solid #ffffff;
      color: $color_10;
      &:hover {
        background: #ffffff;
        color: $color_1;
        .white {
          display: none;
        }
        .gris {
          display: block;
        }
      }
      .gris {
        display: none;
      }
    }
  }
}
.boxAlertMsg {
  padding: 15px 20px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin-bottom: 30px;
  img {
    margin-right: 12px;
  }
}
.boxAlertMsg.warning {
  border-radius: 5px;
  background: #fff3cd;
  border: 1px solid #ffecb5;
  box-sizing: border-box;
  color: $color_39;
}
.boxAlertMsg.error {
  color: $color_40;
  background: #fde1e1;
  border: 1px solid #fcd2d2;
  border-radius: 5px;
  box-sizing: border-box;
}
.nexBoxTabs {
  display: block;
  transition: opacity 0.2s;
  overflow-y: hidden;
  overflow-x: scroll;
  width: 100%;
  padding: 10px 0;
  margin: 20px 0 0 0;
  scroll-behavior: smooth;
  position: relative;
  scrollbar-width: none;
  .boxTabList {
    display: flex;
    transition: all 0.3s ease-out;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 10px;
    .tabItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100px;
      min-width: 100px;
      background: #fff;
      border-radius: 10px;
      border: 1px solid #e4e5e7;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      padding: 5px;
      span {
        font-size: 12px;
      }
    }
    .tabItem.active {
      color: $color_41;
      border: 1px solid #df0c30;
      svg {
        fill: #df0c30;
        path {
          fill: #df0c30;
        }
      }
    }
  }
}
.boxVideo {
  .row {
    height: 100%;
    .col-12 {
      height: 100%;
      .videoYoutube {
        height: 100%;
      }
    }
  }
}
.p-carousel-prev {
  height: 0;
  top: 40%;
  z-index: 2;
}
.p-carousel-next {
  height: 0;
  top: 40%;
  z-index: 2;
}
.tile {
  ul.dots {
    list-style-type: none;
    text-align: center;
    padding: 12px;
    margin: 0;
    white-space: nowrap;
    overflow: auto;
    box-sizing: border-box;
    li {
      width: 14px;
      height: 14px;
      display: inline-block;
      border-radius: 50%;
      background-color: $background-color_16;
      cursor: pointer;
      margin: 0 0.25rem;
      transition: background-color 0.25s;
    }
    li.active {
      background-color: $background-color_1;
    }
  }
}
.ng5-slider-span.ng5-slider-pointer.ng5-slider-pointer-min {
  width: 20px !important;
  height: 20px !important;
  background-color: $background-color_18 !important;
  border-radius: 50% !important;
  top: -8px !important;
  &:active {
    border: none !important;
  }
}
.ng5-slider-span.ng5-slider-pointer.ng5-slider-pointer-max {
  width: 20px !important;
  height: 20px !important;
  background-color: $background-color_18 !important;
  border-radius: 50% !important;
  top: -8px !important;
  &:active {
    border: none !important;
  }
}
input {
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
input[type='number'] {
  -moz-appearance: textfield;
}
ul.dots.bars {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: auto;
  box-sizing: border-box;
  position: absolute;
  transition: opacity 0.3s;
  bottom: 0 !important;
  visibility: visible;
  z-index: 10;
  > li {
    width: 40px;
    height: 8px;
    border-radius: 8px;
    background-color: $background-color_20;
    margin: 0 12px !important;
    opacity: 1;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.25s;
  }
  > li.active {
    background: #787d83;
    width: 80px;
    height: 12px;
    margin: -2px 12px !important;
  }
}
.material-icons {
  font-family: $font-family_14;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 768px) {
  button {
    font-size: 14px;
  }
  .card__internacional {
    > img {
      width: 100% !important;
      height: 100%;
    }
    button.hidden {
      margin-bottom: 32px !important;
    }
  }
  .card.seguro {
    width: 100%;
    margin: 0;
  }
  .rowBoxTab {
    .colBoxtab.w-30 {
      width: 100%;
    }
    .colBoxtab.w-10 {
      width: 100%;
    }
    .colBoxtab.w-15 {
      width: 100%;
    }
    .colBoxtab.widthBtn {
      width: 100%;
    }
    .colBoxtab {
      .rowTab {
        flex-wrap: wrap;
        .colTab {
          .colBoxtab {
            padding: 0;
          }
        }
      }
      .rowTab.width50 {
        .colTab {
          flex: 1 1 45%;
        }
      }
    }
  }
  .boxDestino {
    .boxSearchHotel {
      padding: 50px 10px;
    }
  }
  .boxAero {
    .boxSearchHotel {
      padding: 50px 10px;
    }
  }
  .capital {
    font-size: 32px;
    line-height: 32px;
    .raya {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    span {
      font-size: 10px;
      line-height: 10px;
    }
  }
  .mat-icon.icon {
    + {
      div {
        font-size: 32px;
        line-height: 32px;
        .raya {
          margin-top: 10px !important;
          margin-bottom: 10px !important;
        }
        span {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
    margin-right: 20px;
    font-size: 28px;
    width: 28px;
    height: 28px;
    display: block;
    margin-left: 0px;
    margin-top: 10px;
  }
  .h659 {
    height: 165px !important;
  }
  .bg-img {
    padding-bottom: 150px;
  }
  .img-responsive {
    width: 93% !important;
    padding: 0;
    padding-top: 30px;
  }
  .btn-agencias {
    font-size: 10px;
  }
  button.customers {
    font-size: 12px;
  }
}
@media (max-width: 640px) {
  .card__internacional {
    height: auto;
  }
  .modal-body {
    .mat-ink-bar {
      min-width: 140px !important;
    }
  }
  .ngb-dp-content {
    .ngb-dp-month {
      &:nth-child(2) {
        display: none;
      }
    }
    padding: 0 8px;
  }
  .personalDocument {
    .rowContent {
      .rowVuelo {
        .colVuelo.fullAuto {
          flex: 1 1 calc(50% - 10px);
          font-size: 12px;
        }
        .colVuelo.flexCol {
          font-size: 12px;
        }
        flex-wrap: wrap;
      }
    }
  }
  .filtroBox {
    .container {
      .portletFiltro {
        .rowFiltro {
          .columnFiltro {
            &:last-child {
              margin-top: 15px;
            }
          }
        }
      }
      padding: 0;
    }
  }
  .inputDer {
    border-left: 0.5px solid #e4e5e7 !important;
  }
  .inputIzq {
    border-left: 0.5px solid #e4e5e7 !important;
  }
  .titleMobile {
    h1 {
      font-size: 14px !important;
      font-weight: bold;
    }
  }
  .narginColumn {
    margin-right: 0px;
  }
  .width110 {
    select {
      width: 100%;
    }
  }
  .selectSeguro {
    margin-bottom: 15px;
  }
  .rowFiltro {
    flex-direction: column !important;
  }
  .portletFiltro {
    .rowFiltro {
      .columnFiltro {
        padding: 0 !important;
        input[type='text'] {
          padding-bottom: 0 !important;
          border: 0.5px solid #e4e5e7;
        }
      }
      .columnFiltro.hideColumn {
        height: 0;
        flex: auto;
      }
    }
  }
  .columnButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #ModalCoverageI {
    .modal-dialog {
      width: calc(100% - 15px) !important;
    }
    .modal-header {
      .modal-title {
        font-size: 18px;
      }
      .modal-subtitle {
        font-size: 12px;
      }
    }
    .modal-body {
      .table-bordered {
        .row {
          .col {
            font-size: 12px;
          }
        }
      }
    }
    .footerPopup {
      .borderRight {
        button {
          width: 100% !important;
          font-size: 18px;
        }
        width: 100% !important;
      }
      padding: 0 !important;
      margin: 0 !important;
    }
    .boxTableCoverage {
      .rowTableCover {
        &:nth-child(2n + 1) {
          background: #f0f3f5;
        }
        .colTabletCover {
          &:first-child {
            border-right: none;
          }
          padding: 0;
          flex: 1 1 100%;
        }
        border-bottom: none;
        padding: 10px;
        flex-wrap: wrap;
      }
    }
  }
  #ModalCoverage {
    .modal-dialog {
      width: calc(100% - 15px) !important;
    }
    .modal-header {
      .modal-title {
        font-size: 18px;
      }
      .modal-subtitle {
        font-size: 12px;
      }
    }
    .modal-body {
      .table-bordered {
        .row {
          .col {
            font-size: 12px;
          }
        }
      }
    }
    .footerPopup {
      .borderRight {
        button {
          width: 100% !important;
          font-size: 18px;
        }
        width: 100% !important;
      }
      padding: 0 !important;
      margin: 0 !important;
    }
    .boxTableCoverage {
      .rowTableCover {
        &:nth-child(2n + 1) {
          background: #f0f3f5;
        }
        .colTabletCover {
          &:first-child {
            border-right: none;
          }
          padding: 0;
          flex: 1 1 100%;
        }
        border-bottom: none;
        padding: 10px;
        flex-wrap: wrap;
      }
    }
  }
  #ModalDetResarva {
    .modal-body {
      .msgAlert {
        font-size: 12px;
      }
      .portletReserva {
        .boxDetReserva {
          .headerPortlet {
            .titleBoxRight {
              font-size: 14px;
            }
          }
          .contentPortlet.tablet {
            .rowPasajero {
              min-width: 700px;
            }
            overflow-x: scroll;
          }
          .contentPortlet {
            ul {
              font-size: 12px;
            }
            .footerReserva {
              .alignLeft {
                .totalSafetypay {
                  .labelTotal {
                    font-size: 12px;
                  }
                }
              }
              .alignRigth {
                .totalInfoPago {
                  .labelTotal {
                    font-size: 12px;
                  }
                  .montoTotal {
                    font-size: 18px;
                  }
                }
              }
            }
            .rowEquipaje.headCol {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowPasajero.headCol {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowVuelo.headCol {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowEquipaje.rowItem {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowPasajero.rowItem {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowVuelo.rowItem {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowEquipaje.headVueloIda {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowEquipaje.headVueloVuelta {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowPasajero.headVueloIda {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowPasajero.headVueloVuelta {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowVuelo.headVueloIda {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowVuelo.headVueloVuelta {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowEquipaje.contentVuelo {
              .colVuelo {
                .aerolinea {
                  font-size: 12px;
                }
                .fecha {
                  font-size: 12px;
                }
              }
            }
            .rowPasajero.contentVuelo {
              .colVuelo {
                .aerolinea {
                  font-size: 12px;
                }
                .fecha {
                  font-size: 12px;
                }
              }
            }
            .rowVuelo.contentVuelo {
              .colVuelo {
                .aerolinea {
                  font-size: 12px;
                }
                .fecha {
                  font-size: 12px;
                }
              }
            }
            .rowEquipaje {
              .itemEquipaje {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
              .itemPasajero {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
            }
            .rowPasajero {
              .itemEquipaje {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
              .itemPasajero {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
            }
            .rowVuelo {
              .itemEquipaje {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
              .itemPasajero {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
            }
            margin: 10px;
          }
        }
      }
    }
  }
  #ModalDetSeguro {
    .modal-body {
      .msgAlert {
        font-size: 12px;
      }
      .portletReserva {
        .boxDetReserva {
          .headerPortlet {
            .titleBoxRight {
              font-size: 14px;
            }
          }
          .contentPortlet.tablet {
            .rowPasajero {
              min-width: 700px;
            }
            overflow-x: scroll;
          }
          .contentPortlet {
            ul {
              font-size: 12px;
            }
            .footerReserva {
              .alignLeft {
                .totalSafetypay {
                  .labelTotal {
                    font-size: 12px;
                  }
                }
              }
              .alignRigth {
                .totalInfoPago {
                  .labelTotal {
                    font-size: 12px;
                  }
                  .montoTotal {
                    font-size: 18px;
                  }
                }
              }
            }
            .rowEquipaje.headCol {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowPasajero.headCol {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowVuelo.headCol {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowEquipaje.rowItem {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowPasajero.rowItem {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowVuelo.rowItem {
              .colPasajero {
                font-size: 12px;
              }
            }
            .rowEquipaje.headVueloIda {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowEquipaje.headVueloVuelta {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowPasajero.headVueloIda {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowPasajero.headVueloVuelta {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowVuelo.headVueloIda {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowVuelo.headVueloVuelta {
              .colVuelo {
                font-size: 12px;
              }
            }
            .rowEquipaje.contentVuelo {
              .colVuelo {
                .aerolinea {
                  font-size: 12px;
                }
                .fecha {
                  font-size: 12px;
                }
              }
            }
            .rowPasajero.contentVuelo {
              .colVuelo {
                .aerolinea {
                  font-size: 12px;
                }
                .fecha {
                  font-size: 12px;
                }
              }
            }
            .rowVuelo.contentVuelo {
              .colVuelo {
                .aerolinea {
                  font-size: 12px;
                }
                .fecha {
                  font-size: 12px;
                }
              }
            }
            .rowEquipaje {
              .itemEquipaje {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
              .itemPasajero {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
            }
            .rowPasajero {
              .itemEquipaje {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
              .itemPasajero {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
            }
            .rowVuelo {
              .itemEquipaje {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
              .itemPasajero {
                .labelEquipaje {
                  font-size: 12px;
                }
              }
            }
            margin: 10px;
          }
        }
      }
    }
  }
  .checkPopup {
    font-size: 12px;
    .form-check-input {
      width: 15px;
      height: 15px;
      min-width: 15px;
      min-height: 15px;
    }
    a {
      color: $color_8 !important;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .boxFilter {
    .bodyFilter {
      .d-flex {
        margin-bottom: 10px;
      }
      flex-wrap: wrap;
      padding-bottom: 0 !important;
    }
    padding: 25px 25px 15px 25px !important;
  }
  .boxFlotante {
    left: 15px;
    top: 55px;
  }
  .titleHome.marginMovil {
    margin: 0 !important;
    padding: 15px 0 15px 0;
  }
  .titleHome.packageTitle {
    margin: 30px 0 10px 0 !important;
  }
  .subTitleHome {
    font-size: 13px;
  }
  .boxItemPackages {
    .boxItem {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .boxCanalPackage {
    .boxCanal {
      flex: 1 1 100%;
    }
    flex-wrap: wrap;
    gap: 50px;
  }
  .rowBoxTab {
    display: flex;
    flex-wrap: wrap;
    app-calendar {
      width: 100%;
      flex-wrap: wrap;
      .colBoxtab {
        width: 100% !important;
      }
    }
    .colBoxtab {
      .d-left {
        border-right: 0.5px solid #e4e5e7;
      }
    }
  }
  .onlydesktop {
    display: none !important;
  }
  .onlyMovTablet {
    display: block !important;
  }
  .onlyMovil {
    display: block !important;
  }
  #ModalBlue {
    .modal-dialog {
      width: 90% !important;
      min-width: auto !important;
    }
    .modal-body {
      .rowModal {
        justify-content: space-between;
        .columnModal.inputCheck {
          span {
            width: 160px;
            height: 80px;
          }
        }
        .columnModal {
          input[type='radio'] {
            left: 15px;
            top: 32px;
          }
        }
      }
      .rowModal.viewModal {
        flex-direction: column;
        margin-bottom: 30px;
      }
    }
  }
  .ngb-dp-months {
    flex-direction: column;
  }
  .ngb-dp-month {
    &:first-child {
      border-right: none;
      padding-right: 0;
    }
  }
  #carouselSeguros {
    h2 {
      font-size: 14px;
    }
    .carousel-caption {
      p {
        width: 100%;
        font-weight: 500;
        line-height: 19px;
        align-items: center;
        letter-spacing: 0.1px;
      }
    }
  }
  .viewMobileWidth {
    width: 100% !important;
    .form-check {
      display: flex !important;
      align-items: center !important;
      margin-bottom: 10px;
    }
  }

  .lessViewMob {
    width: 100% !important;
    margin: 0 !important;
  }
  .card_pasajero {
    .rowMobileCard {
      display: flex;
      flex-direction: column !important;
      .fecNacTab {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        margin: 0;
        .col-3 {
          width: 100% !important;
          margin: 0;
          padding: 0;
        }
        .col-6 {
          width: 100% !important;
          margin: 0;
          padding: 0;
        }
      }
    }
    .rowMobileCard.hideMobile {
      display: none;
    }
    .rowMobileCard.hideMobile.activoBox {
      display: flex;
    }
    label {
      font-size: 12px !important;
    }
    .form-check-label {
      margin: 0 !important;
    }
  }
  .card-header {
    white-space: inherit !important;
    line-height: 15px !important;
    font-size: 11px !important;
    padding-bottom: 20px;
    h2 {
      font-size: 18px !important;
      line-height: 27px !important;
      padding-bottom: 7px;
    }
    span {
      font-size: 11px !important;
    }
  }
  .form-control.custom {
    font-size: 12px !important;
  }
  .marginLat {
    a {
      font-size: 12px !important;
    }
  }
  .textDire {
    font-size: 12px !important;
  }
  .contentCenterMobile {
    display: flex;
    justify-content: center;
  }
  .stepCard {
    .mat-horizontal-content-container {
      padding: 0 !important;
    }
    h2 {
      font-size: 18px !important;
    }
    p {
      font-size: 12px !important;
    }
    .columnDest {
      font-size: 12px !important;
    }
  }
  .titleMob {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.18px;
    padding: 15px 0 10px 0;
    font-size: 18px !important;
  }
  .cardPagoMobile {
    background: transparent;
    border: none;
    box-shadow: none;
    .card-header {
      background: transparent;
      box-shadow: none !important;
      border-bottom: none;
    }
    .card-body {
      box-shadow: none !important;
    }
  }
  .medioPagoMobile {
    padding: 0;
    .cardMob {
      width: 100%;
      flex-direction: column;
      padding: 0;
      margin: 0;
      .boxMob {
        width: 100%;
        display: flex !important;
        align-items: center;
        justify-content: space-evenly;
      }
    }
  }
  .btnSafe {
    flex-direction: column;
    justify-content: flex-start;
    div {
      padding-bottom: 20px;
    }
  }
  .textSafMob {
    width: 100%;
    padding: 0;
    margin: 0;
    p {
      font-size: 11px !important;
    }
  }
  .boxAgencia {
    margin-top: 0px;
    .imgBanca {
      padding-bottom: 0px;
      img {
        width: 15%;
      }
    }
  }
  .lessMarginMobile {
    padding: 0;
    margin: 0;
  }
  .titleCobertura {
    font-size: 18px !important;
  }
  .titleBodyCober {
    font-size: 18px !important;
    p {
      font-size: 11px !important;
    }
  }
  .rightHeader {
    font-size: 12px !important;
  }
  .card {
    .card-body {
      .titleAsegurado {
        font-size: 15px !important;
      }
      .fechaAsegurado {
        font-size: 12px !important;
      }
      .descripAsegurado {
        font-size: 12px !important;
      }
      .card-title svg {
        margin-right: 8px;
      }
      h5.card-title {
        font-size: 10px !important;
      }
    }
    .py-4 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  .card.pasajeros {
    background: transparent;
    border: none;
    box-shadow: none;
    .card-header {
      background: transparent;
      border-bottom: none;
    }
    .card-body {
      background: #ffffff;
      border: 0.5px solid #e4e5e7;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
  .selectBank {
    width: 100%;
    width: 100% !important;
  }
  .agregarAdultoPas {
    display: flex !important;
    background: #f21037;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    .textAgregar {
      font-family: $font-family_6;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: $color_10;
      padding: 10px 10px;
    }
  }
  .atribCard {
    padding: 18px;
    background: #ffffff;
    border: 0.5px solid #e4e5e7;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .btnMobile.activoBox {
    display: flex !important;
  }
  .btnFooter {
    font-family: $font-family_6;
    background: #df0c30 !important;
    color: $color_10 !important;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxHideM {
    display: none;
  }
  .boxHideM.activoBoxContact {
    display: block;
  }
  .mat-horizontal-stepper-header-container {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .mat-step-header {
    padding-top: 20px !important;
  }
  .iconFlechaSup {
    display: block;
  }
  .iconFlechaSup.activeIcon {
    transform: rotate(0deg);
  }
  .versionMobile {
    justify-content: space-between;
    .selectDestino {
      display: flex;
      .selectSeguro {
        text-indent: 40px;
      }
    }
  }
  .contentSecuritySeg {
    .vermovil {
      display: flex;
      flex-wrap: nowrap;
    }
  }
  .hideViewMobile {
    display: none;
  }
  .mat-stepper-horizontal {
    background: #f6f7f9;
    .mat-horizontal-stepper-header-container {
      background: #ffffff;
    }
  }
  .card_ {
    .option {
      margin-bottom: 20px;
      flex-wrap: nowrap !important;
      height: 70px;
      .dot {
        min-width: 20px;
      }
      .py-4 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }
  .textEmision {
    font-family: $font-family_6;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: $color_2;
  }
  .card.padCard {
    padding: 10px 25px !important;
    .rowCard {
      flex-wrap: wrap;
      &:first-child {
        padding-bottom: 10px !important;
      }
    }
    .columnCard {
      font-size: 12px !important;
      &:last-child {
        margin-left: 30px !important;
      }
    }
    .columnCard.fontOpen {
      font-size: 14px !important;
    }
    .boxFlotante {
      width: 330px;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      position: absolute;
      top: 25px;
      z-index: 2;
      .textBtn {
        .inputPasajero {
          width: 25px;
          height: 28px;
          padding: 0;
          margin: 0;
          text-align: center;
          border-radius: 0;
          font-family: $font-family_6;
          font-weight: 600;
          font-size: 12px;
          border: none;
        }
        .inputEdadPasajero {
          box-sizing: border-box;
          border-radius: 3px;
          width: 50px;
          font-family: $font-family_6;
          font-weight: normal;
          font-size: 12px;
          color: $color_1;
          height: 29px;
          padding: 0;
          margin: 0;
          text-align: center;
          &::placeholder {
            font-family: $font-family_6;
            font-weight: normal;
            font-size: 12px;
          }
        }
      }
      .opcionBtn {
        width: 30px;
        cursor: pointer;
        img {
          width: 12px;
          height: 15px;
        }
      }
      .boxTwoPasajero {
        border-bottom: 0.5px solid #e4e5e7;
        padding: 15px 20px !important;
        .groupItem {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 100%;
          gap: 5px;
          position: relative;
          .closeBtn {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: 0.3s all;
            border-radius: 3px;
            &:hover {
              background: #e4e5e7;
            }
          }
          .boxNumber {
            width: auto;
            display: flex;
            align-items: center;
            margin-right: 10px;
            .numberItem {
              background: #df0c30;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $color_10;
              font-size: 10px;
              border-radius: 8px;
              margin-right: 5px;
            }
            .cerrarbtn {
              padding: 5px;
              position: absolute;
              top: 0;
              left: 0;
              width: 22px;
              height: 22px;
              background: rgba(223, 12, 48, 0.4);
              border-radius: 2px;
              transition: 0.3s all;
              cursor: pointer;
              &:hover {
                background: #df0c30;
              }
            }
            .cerrarbtn.inactive {
              display: none;
            }
          }
          .itemPasajero {
            width: 100%;
            display: flex;
            align-items: center;
            .inputEdad {
              border: 0.5px solid #e4e5e7;
              border-radius: 3px;
              width: 100%;
              text-align: center;
            }
            span {
              font-family: $font-family_6;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 0.4px;
              color: $color_1;
            }
          }
          .itemPasajero.espacio {
            gap: 10px;
          }
          .itemPasajero.w100 {
            width: 100px;
          }
        }
        .groupItem.inLine {
          flex-direction: row;
        }
        .iconTwo {
          width: 25px;
          padding-right: 10px;
        }
        .textSection {
          flex: 1 1 auto;
        }
        .text1 {
          font-family: $font-family_6;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0.4px;
          color: $color_1;
        }
        .text2 {
          font-family: $font-family_6;
          font-weight: 300;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.25px;
          color: $color_1;
        }
        .number {
          display: flex;
          justify-content: space-between;
          font-family: $font-family_6;
          gap: 5px;
          .btnNWhite {
            display: flex;
            border: 0.5px solid #df0c30;
            box-sizing: border-box;
            border-radius: 3px;
            font-weight: 600;
            font-size: 14px;
            color: $color_6;
            width: 28px;
            min-width: 28px;
            height: 28px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: #df0c30;
              color: $color_10;
            }
          }
          .textBtn {
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: $color_1;
            width: 28px;
            height: 28px;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .btnPasajero {
        button {
          font-family: $font-family_6;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          &:hover {
            background: #b50a27;
          }
        }
        button.gray {
          background: #ebebeb;
          color: $color_1;
          &:hover {
            background: #cfd1d3;
          }
        }
        button.green {
          background: #54d834;
          color: $color_10;
          &:hover {
            background: #3eb322;
          }
        }
        .Agregar {
          background: transparent;
          font-family: $font-family_6;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0.1px;
          color: $color_8;
          &:hover {
            background: transparent;
          }
          &:disabled {
            color: $color_1;
          }
        }
      }
    }
    .boxFlotante.seguro {
      top: inherit;
      padding: 10px 10px 0 10px;
      margin-top: 50px;
      .boxTwoPasajero {
        .text1 {
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.15px;
          align-items: center;
          gap: 10px;
          img {
            width: 14px;
            height: 14px;
          }
        }
        border-bottom: none;
      }
      .btnPasajero {
        border-top: 0.5px solid #e4e5e7;
        margin: 10px 0 0 0;
      }
    }
  }
  .boxViaje {
    gap: 10px;
    font-family: $font-family_6;
    img {
      padding: 0 5px;
    }
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_1;
    }
    .subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_1;
    }
  }
  .edad {
    display: none;
  }
  .boxComprar {
    .precio {
      font-family: $font-family_8;
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;
      color: $color_1;
    }
    .descrip {
      font-family: $font-family_6;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_1;
    }
    .btnDetalles {
      font-family: $font-family_6;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.1px;
      color: $color_1;
      background: #f7f7f7;
      border-radius: 5px;
      cursor: pointer;
      padding: 0px 20px;
      margin-right: 15px;
    }
    button {
      width: 100%;
    }
  }
  .boxConveniente {
    font-family: $font-family_6;
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.15px;
      color: $color_1;
    }
    label {
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: $color_1;
      padding-bottom: 0;
      font-weight: normal;
    }
    .flex-row {
      border-bottom: 1px solid #e4e5e7;
      &:first-child {
        padding: 1rem 0 !important;
      }
      &:nth-last-child(-n + 2) {
        border-bottom: none;
      }
    }
    button {
      width: 80%;
    }
    .btn-close {
      width: 12px;
    }
  }
  .boxMoneda {
    font-family: $font-family_6;
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.15px;
      color: $color_1;
    }
    label {
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: $color_1;
      padding-bottom: 0;
      font-weight: normal;
    }
    .flex-row {
      border-bottom: 1px solid #e4e5e7;
      &:first-child {
        padding: 1rem 0 !important;
      }
      &:nth-last-child(-n + 2) {
        border-bottom: none;
      }
    }
    button {
      width: 80%;
    }
    .btn-close {
      width: 12px;
    }
  }
  .boxFlights {
    .equipajes {
      span {
        &:before {
          content: none !important;
        }
      }
    }
    .card-body {
      .col-9 {
        width: 100% !important;
      }
      .col-3 {
        width: 100% !important;
      }
      .border-start {
        border-left: none;
      }
    }
  }
  .boxModalDetalle {
    .header {
      border-bottom: 1px solid #e4e5e7;
      margin-bottom: 10px;
      font-family: $font-family_6;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
      letter-spacing: 0.15px;
    }
    .price {
      align-items: baseline;
      font-family: $font-family_6;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_1;
      h2 {
        font-family: $font-family_8;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        color: $color_1;
      }
      span {
        font-family: $font-family_6;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: $color_1;
      }
    }
    .descrip {
      span {
        font-family: $font-family_6;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1px;
        font-weight: 500;
      }
    }
    .priceMont {
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 25px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_1;
    }
  }
  #modalConveniente {
    .modal-body {
      padding: 0rem 1rem 0rem 1rem;
    }
  }
  #modalMoneda {
    .modal-body {
      padding: 0rem 1rem 0rem 1rem;
    }
  }
  #modalViajes {
    .header {
      border-bottom: 1px solid #e4e5e7;
      .title {
        font-family: $font-family_6;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $color_1;
      }
    }
    .modal-body {
      padding: 0;
      .boxTipoViaje {
        gap: 30px;
      }
      .tipo {
        gap: 15px;
      }
      .titleTramo {
        font-family: $font-family_6;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $color_1;
        padding-left: 30px;
      }
      .agregaTramo {
        font-family: $font-family_6;
        font-weight: 300;
        font-size: 10px;
        line-height: 20px;
        color: $color_24;
        margin-left: 30px;
        padding: 10px 10px 10px 0;
        cursor: pointer;
        transition: all 0.3s;
        width: 110px;
      }
      .btnTipoViaje {
        background: #ffffff;
        border: 1px solid #e4e5e7;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: $font-family_6;
        font-size: 10px;
        line-height: 24px;
        height: 33px;
        align-items: center;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: $color_1;
        cursor: pointer;
        transition: all 0.3s;
        padding: 0 15px;
        &:hover {
          background: #f7f7f7;
        }
      }
      .contentViaje {
        width: 100%;
        padding: 10px 30px 0px 10px;
        .iconViaje {
          width: 70px;
        }
        .inputViaje {
          input {
            margin-bottom: 0 !important;
            height: 35px;
            font-size: 10px !important;
          }
        }
        .btnConfirm {
          background: #df0c30;
          border-radius: 3px;
          width: 80%;
          font-family: $font-family_6;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.5px;
          height: 35px;
          color: $color_10;
          cursor: pointer;
          margin-bottom: 15px;
        }
      }
    }
  }
  #modalTwoPasajero {
    .header {
      border-bottom: 0.5px solid #e4e5e7;
    }
    .modal-body {
      padding: 0 20px;
    }
    .title {
      font-family: $font-family_6;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $color_1;
    }
    .boxTwoPasajero {
      border-bottom: 0.5px solid #e4e5e7;
      .iconTwo {
        width: 40px;
      }
      .text1 {
        font-family: $font-family_6;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.4px;
        color: $color_1;
      }
      .text2 {
        font-family: $font-family_6;
        font-weight: 300;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.25px;
        color: $color_1;
      }
      .number {
        font-family: $font-family_6;
        gap: 10px;
        .btnNWhite {
          border: 0.5px solid #df0c30;
          box-sizing: border-box;
          border-radius: 3px;
          font-weight: 600;
          font-size: 14px;
          color: $color_6;
          width: 22.5px;
          height: 22.5px;
          align-items: center;
          justify-content: center;
        }
        .textBtn {
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: $color_1;
          width: 22.5px;
          height: 22.5px;
          align-items: center;
          justify-content: center;
        }
        .btnNRed {
          background: #df0c30;
          border-radius: 3px;
          font-weight: 600;
          font-size: 14px;
          color: $color_10;
          width: 22.5px;
          height: 22.5px;
          align-items: center;
          justify-content: center;
        }
      }
    }
    button {
      width: 80%;
    }
  }
  #modalFiltro {
    .modal-body {
      padding: 0;
    }
    .accordion-item {
      background-color: $background-color_9 !important;
      font-size: 14px !important;
    }
    .accordion-button {
      background-color: $background-color_9 !important;
      font-size: 14px !important;
    }
    .title {
      font-family: $font-family_6;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      align-items: center;
      letter-spacing: 0.15px;
      color: $color_1;
    }
    .btnFiltro {
      border: 1px solid #506370;
      box-sizing: border-box;
      border-radius: 10px;
      font-family: $font-family_6;
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: $color_16;
      padding: 0px 15px;
      height: 35px;
    }
    .accordion-body {
      span {
        font-size: 12px !important;
        font-weight: normal !important;
        strong {
          font-size: 12px !important;
          font-weight: normal !important;
        }
      }
    }
  }
  #politicaModal {
    .modal-dialog {
      max-width: 100% !important;
    }
    .modal-header {
      padding-bottom: 1rem !important;
      .titleMod {
        font-size: 20px !important;
      }
    }
    .modal-body {
      padding: 20px !important;
    }
  }
  #Modaltermino {
    font-size: 14px;
    .cabeceraModal {
      padding: 0 10px !important;
      font-size: 14px !important;
      height: 80px !important;
      min-height: 80px !important;
      .btnModal {
        font-size: 12px !important;
      }
    }
    .modal-body {
      padding: 20px !important;
    }
  }
  #reservaModal {
    .modal-dialog {
      max-width: 100% !important;
      .titleMod {
        font-size: 20px !important;
      }
      .modal-body {
        padding: 30px 20px !important;
      }
    }
  }
  #ModalCondicion {
    .modal-content {
      font-size: 14px;
      .cabeceraModal {
        padding: 0 10px !important;
        font-size: 14px !important;
        height: 80px !important;
        min-height: 80px !important;
        .btnModal {
          font-size: 12px !important;
        }
      }
      .contentModal {
        padding: 10px !important;
        p {
          font-size: 12px !important;
        }
      }
      .contentModBox {
        margin: 10px !important;
        .textTitle {
          font-size: 12px !important;
        }
        .padLateral {
          padding: 0 40px !important;
          .titleCondicion {
            padding-left: 10px !important;
          }
        }
        .tablaModal {
          padding: 0 10px !important;
        }
      }
      .contentRed {
        margin: 0 10px 10px 10px !important;
        padding: 10px;
        font-size: 12px !important;
        height: auto !important;
      }
    }
  }
  .boxImagen {
    padding: 20px 10px 0 10px;
    img {
      width: 100%;
      height: 110px;
      border-radius: 10px;
    }
  }
  .titleHome {
    font-size: 14px !important;
    margin: 20px 0 !important;
  }
  .container-fluid {
    .mb-5 {
      margin-bottom: 0px !important;
    }
  }
  .customers {
    margin-bottom: 10px;
  }
  app-card-info {
    .col-2 {
      img {
        width: 42px;
      }
    }
  }
  .boxResult {
    padding-top: 10px !important;
  }
  .hideMov {
    display: none !important;
  }
  .hideDesktop {
    display: flex !important;
  }
  .viajaBox {
    .mnuViaja {
      .padLeft70 {
        img {
          width: 75%;
        }
      }
      .vuelos {
        padding-bottom: 20px;
      }
      .subMenu {
        font-size: 12px;
        margin: 0 0 0 20px;
        font-size: 14px;
      }
      padding-top: 100px;
      .col-3 {
        width: 100% !important;
      }
      .col-4 {
        width: 100% !important;
        margin-bottom: 20px;
      }
      .col-5 {
        width: 100% !important;
      }
    }
    background-size: auto 70%;
    background-position-x: center;
    background-position-y: 0;
    height: auto;
    .padLeft70 {
      padding-left: 45px !important;
      margin-bottom: 30px;
      img {
        width: 220px;
      }
    }
  }
  .verMovil {
    .col-5 {
      width: 100% !important;
    }
    .col-7 {
      width: 100% !important;
    }
    .boxVuelos {
      padding: 10px 15px;
    }
    .boxImgVuelo {
      width: 100%;
      padding: 0 15px;
      .gridVuelo {
        grid-column-gap: 10px;
        grid-template-rows: 150px 60px 150px;
        img {
          width: 100%;
        }
      }
    }
  }
  .padTitle {
    padding: 20px 0 20px 0;
  }
  .sliderOfertas {
    padding: 0 15px;
    .btnSteps {
      display: none;
    }
    .contentSliderImg {
      display: none;
      overflow: hidden;
      gap: 15px;
      width: 100%;
    }
    .contentSliderImg.active {
      width: 100%;
    }
  }
  .boxVuelos {
    padding: 10px 15px 10px 0 !important;
    .lugar {
      padding-left: 20px !important;
    }
    .desde {
      width: 100px !important;
    }
    .btnCircle {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 30px;
      min-width: 30px;
      height: 30px;
      min-height: 30px;
      border-radius: 15px;
      background: #df0c30;
    }
  }
  .boxDestino {
    .rowDestino.ordenCol {
      .boxVuelos {
        .rowVuelo {
          width: 100%;
        }
        padding: 20px 0 50px 0 !important;
      }
    }
    .rowAero.ordenCol {
      .boxVuelos {
        .rowVuelo {
          width: 100%;
        }
        padding: 20px 0 50px 0 !important;
      }
    }
    .titulo {
      font-size: 14px;
      line-height: 27px;
    }
    .subtitulo {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
    .footerTable {
      margin: 30px 0;
      flex-direction: column-reverse;
      .text {
        margin: 20px 0 0 0;
        font-size: 1.2rem;
        text-align: center;
      }
      .masOfertas {
        display: flex;
        justify-content: flex-end;
        margin-right: 0;
        .plus {
          background: #df0c30;
          color: $color_10 !important;
        }
      }
    }
    .boxPackage {
      .package {
        display: none;
      }
      .packageMov {
        display: flex;
        align-items: center;
        width: 100%;
        background: #ffffff;
        border: 1px solid #e4e5e7;
        box-sizing: border-box;
        border-radius: 10px 0px;
        padding: 10px 20px;
        gap: 10px;
        .contentBox {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .icono {
            position: relative;
            .eclipse {
              position: absolute;
              right: -12px;
              bottom: -5px;
            }
          }
          .texto {
            font-family: $font-family_6;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            color: $color_1;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .boxAero {
    .rowDestino.ordenCol {
      .boxVuelos {
        .rowVuelo {
          width: 100%;
        }
        padding: 20px 0 50px 0 !important;
      }
    }
    .rowAero.ordenCol {
      .boxVuelos {
        .rowVuelo {
          width: 100%;
        }
        padding: 20px 0 50px 0 !important;
      }
    }
    .logoAero {
      margin: 20px 0;
    }
    .textAbout {
      font-size: 12px;
      line-height: 18px;
      width: 100%;
      padding: 0 20px;
    }
    .boxAbout {
      .sliderAero {
        #carouselAero {
          width: 100%;
        }
        margin-right: 0;
        img {
          height: auto;
          width: 100%;
        }
      }
      margin: 30px 0 0 0;
      flex-direction: column;
      padding: 85px 30px 120px 30px;
      background: #b50a27 url('./assets/aerolineas/lineas-movil.png') no-repeat top left;
      .descrip {
        p {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .tabSalidas {
      padding: 25px 15px 0 15px;
      background: transparent;
      .descripTab {
        text-align: center;
      }
      .opcionTab {
        margin: 25px 0;
        justify-content: center;
        .tabName {
          font-size: 12px;
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
    .footerTable {
      margin: 25px 0;
      .masOfertas {
        margin-right: 0;
        background: #df0c30;
      }
      button.plus {
        color: $color_10 !important;
      }
    }
    .ida {
      font-size: 14px !important;
      line-height: 21px !important;
    }
    .vuelta {
      font-size: 14px !important;
      line-height: 21px !important;
    }
  }
  .verMov {
    flex-wrap: wrap;
    .ida {
      font-size: 14px !important;
      line-height: 21px !important;
    }
    .vuelta {
      font-size: 14px !important;
      line-height: 21px !important;
    }
    .descrip {
      font-size: 12px;
      line-height: 15px;
      width: 100%;
      margin: 5px 0 20px 0;
    }
  }
  .newBoxTab {
    .sectionTab {
      .columnBox.gap20 {
        gap: 10px;
      }
      .columnBox.maxW {
        flex: 1 1 100%;
        max-width: 100%;
      }
      .columnBox {
        .boxFlotante {
          top: 50px;
          left: auto;
          right: 0;
        }
        .titleBox {
          flex: 1 1 100%;
        }
        button {
          font-size: 14px;
        }
        flex-wrap: wrap;
        gap: 10px;
        flex: 1 1 100%;
        position: relative;
      }
      .colTabFilter {
        width: 100%;
        order: 3;
      }
      app-tab-vuelos-multicity {
        .rowBoxTab {
          padding: 0;
          .colBoxtab.w-10 {
            max-width: 120px;
          }
          .colBoxtab {
            padding: 0 0 10px 0 !important;
          }
        }
      }
      flex-wrap: wrap;
      gap: 10px;
      flex-wrap: wrap;
    }
    padding: 0;
  }
  .verDesktop {
    display: none !important;
  }
  .contentTabMobile {
    display: block;
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    padding: 25px 24px;
    .headerRow {
      margin-bottom: 15px;
    }
    .tableDestino {
      flex-wrap: wrap;
      height: auto;
      padding-bottom: 15px;
      padding-top: 25px;
      &:hover {
        filter: none;
      }
      .buscar {
        padding: 0 40px;
      }
      .city {
        width: 100%;
        font-family: $font-family_6;
        font-size: 14px;
        color: $color_1;
        margin-bottom: 5px;
        margin-top: 10px;
      }
      .rowTab {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        justify-content: space-between;
        svg {
          margin-right: 10px;
        }
        .cLeft {
          display: flex;
          align-items: center;
          flex: 1 1 60%;
        }
        .cRight {
          display: flex;
          align-items: center;
          flex: 1 1 40%;
          justify-content: flex-end;
        }
      }
    }
  }
  .cardDestino {
    flex-direction: column;
    gap: 13px;
    .boxCards {
      display: flex;
      width: 100%;
      max-width: 100%;
      .imageHead {
        display: flex;
        justify-content: center;
        width: 100px;
        min-width: 100px;
        overflow: hidden;
        height: 135px;
        border-radius: 10px 0 0 10px;
        img {
          width: fit-content;
          height: 100%;
        }
      }
      .contCardDest {
        .cardTitulo {
          padding: 10px;
          font-size: 12px;
          line-height: 20px;
        }
        .cardDuracion {
          padding: 0 10px 10px 10px;
          font-size: 10px;
          line-height: 15px;
        }
        .cardOpinion {
          padding: 0 10px 10px 10px;
          font-size: 10px;
          line-height: 15px;
        }
        .cardActividad {
          padding: 0 10px 10px 10px;
          font-size: 10px;
          line-height: 15px;
        }
      }
    }
  }
  .ofrecido {
    margin-bottom: 35px;
  }
  .ofertas {
    .col-12 {
      padding: 0;
    }
    .col-12.padLateral {
      padding: 0 15px;
    }
    .sliderOfertas {
      .contentImg.active {
        display: none !important;
      }
      .banner {
        > .active.content {
          display: none !important;
        }
        > .active.contentEmpresa {
          display: none !important;
        }
        > .onlyMovil.content {
          width: 100%;
          display: flex;
          opacity: 1;
          align-items: center;
          justify-content: space-between;
        }
        > .onlyMovil.contentEmpresa {
          width: 100%;
          display: flex;
          opacity: 1;
          align-items: center;
          justify-content: space-between;
        }
      }
      .contentImg.onlyMovil {
        width: 100%;
        display: flex;
        opacity: 1;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .cardHotel.show {
    .p-carousel-prev {
      margin-bottom: 170px;
    }
    .p-carousel-next {
      margin-bottom: 170px;
    }
  }
  .cardHotel {
    .cardEvaluacion {
      .groupItems {
        padding: 0;
      }
    }
    flex-direction: column;
    gap: 13px;
    .imageHead {
      img {
        height: 200px !important;
      }
    }
    .cardTitulo {
      font-size: 14px !important;
      padding: 10px 15px !important;
    }
    .cardStar {
      padding: 0 25px 10px 25px !important;
    }
    .cardOpinion {
      padding: 0 25px 10px 25px !important;
    }
    .precio {
      .desde {
        font-size: 12px !important;
      }
      .tarifa {
        font-size: 24px !important;
      }
    }
  }
  .companyCar {
    .carTitulo {
      font-size: 14px;
      line-height: 21px;
    }
    .carSubtitulo {
      font-size: 12px;
      line-height: 18px;
    }
    .contentCompany {
      flex-direction: column-reverse;
    }
    .imgCar {
      width: 100% !important;
    }
    .logosCompany {
      width: 100% !important;
      .imgLogos {
        gap: 0;
        img {
          width: 40%;
        }
      }
    }
  }
  .tabDestino {
    padding: 0;
  }
  .movDestino {
    flex-direction: column-reverse;
    .tabContentDest {
      .opcionTab {
        margin-bottom: 25px;
      }
      .contOpcion {
        margin-left: 0;
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
  .tabContentDest {
    .tabColumna {
      gap: 20px;
      flex-wrap: wrap;
      .contenidoTab {
        width: 100%;
        padding-top: 10px;
        .contOpcion {
          font-size: 12px;
          line-height: 17px;
          margin-left: 0;
        }
      }
      .imagenTab {
        width: 100%;
      }
    }
    margin: 0;
    padding: 0 15px;
    .titleTabDest {
      padding: 0;
    }
    .nameCity {
      margin-left: 0;
    }
    .opcionTab {
      flex-wrap: wrap;
      gap: 10px;
      margin-left: 0;
      .tabName {
        font-size: 12px;
        line-height: 18px;
        height: 30px;
        width: 30%;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .mat-tab-label {
    min-width: auto !important;
  }
  .boxTab {
    .rowBoxTab {
      &:first-child {
        display: flex;
        width: 100%;
      }
      .mat-tab-body-active {
        width: 90%;
      }
      .mat-form-field-infix {
        width: 110px !important;
      }
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
      .padRight {
        padding-right: 10px;
      }
      .customers {
        width: 90px;
        padding-top: 15px;
      }
      .boxFlotante {
        right: 0;
      }
      .colBoxtab {
        padding: 0 0 10px 0;
      }
      .ignore-template {
        display: flex;
        .colBoxtab {
          width: 50%;
        }
      }
    }
  }
  .portadaAero {
    height: 163px;
    img {
      position: absolute;
      right: 0;
      width: auto;
      height: 163px;
    }
    .textoPortada {
      top: 55px;
      right: 10%;
      .aeroTitle {
        font-size: 18px;
        line-height: 27px;
        text-align: left;
      }
      .aeroDescrip {
        font-size: 10px;
        line-height: 15px;
      }
    }
  }
  .banner {
    height: 740px;
  }
  button.pasajero {
    font-size: 14px;
  }
  #ModalSesion {
    .modal-dialog {
      width: calc(100% - 15px);
    }
  }
  .forgot-password-modal {
    .modal-dialog {
      .modal-content {
        .modal-content {
          .recoveryRight {
            .btn-close {
              width: 32px !important;
            }
            .titleRecovery {
              margin: 15px 0 15px 0;
            }
            button {
              width: 50% !important;
            }
            .elementRow {
              .form-control {
                margin-bottom: 35px;
              }
            }
          }
        }
      }
    }
  }
  .new-account-modal {
    .modal-dialog {
      .modal-content {
        .recoveryRight {
          .btn-close {
            width: 32px !important;
          }
          .titleRecovery {
            margin: 15px 0 15px 0;
          }
          button {
            width: 50% !important;
          }
          .mat-ink-bar {
            min-width: 140px !important;
          }
        }
      }
    }
  }
  #ModalDatosOpcionales {
    .modal-body {
      padding: 10px;
      .personalDocument {
        .headerPortlet {
          .titleBoxRight {
            font-size: 22px;
          }
        }
        .rowContent {
          padding: 0px 10px;
          .inputGroup {
            margin-bottom: 10px;
          }
        }
        .moreInfo {
          padding: 0 20px;
          .title {
            font-size: 22px;
          }
          .question {
            flex-direction: column;
            align-items: flex-start;
          }
          .groupOption.popup {
            .checkOption {
              width: 100%;
              .mat-checkbox-layout {
                white-space: initial;
                .mat-checkbox-label {
                  margin-right: 0;
                }
              }
            }
          }
          .groupOption {
            .checkOption {
              width: 50%;
            }
            .groupRadio {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
  .ModalVerificaCorreo {
    .modal-dialog {
      width: 100%;
    }
  }
  .boxReclamaciones {
    .contLeft {
      .virtual {
        .titleVirtual {
          font-size: 24px;
          padding-bottom: 20px;
        }
        .contentVirtual {
          font-size: 14px;
        }
        .text {
          font-size: 14px;
        }
        padding: 30px 25px;
      }
      width: 100%;
    }
    .contRight {
      .rowGroup.columna {
        .twoCol.onlyMovil {
          .numero {
            width: 100%;
          }
        }
        .twoCol {
          .tipo {
            width: calc(50% - 5px);
          }
          .numero {
            width: calc(50% - 5px);
          }
          gap: 10px;
        }
      }
      .rowGroup {
        .colLeft {
          width: 100%;
        }
        .colRight {
          width: 100%;
        }
        .texto {
          text-align: justify;
        }
        flex-wrap: wrap;
      }
      .separador {
        margin: 30px 0;
      }
      width: 100%;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      padding: 30px 25px;
    }
    flex-direction: column;
  }
  app-flight-deals {
    button {
      background: none !important;
      &:hover {
        background: none !important;
      }
    }
  }
  .gridTab {
    .rowGrid {
      flex-direction: column;
      .selectSeguro {
        margin-bottom: 0;
      }
    }
  }
  .boxCalendar {
    .groupCalendario {
      .calendarStart {
        .headCalendar {
          width: 100%;
        }
        .bodyCalendar {
          .groupName {
            .nameDay {
              width: auto;
              flex: 0 1 15%;
            }
          }
          .groupDay {
            .dayNumber {
              span {
                width: 100%;
              }
              width: auto;
              flex: 0 1 15%;
            }
          }
          width: 100%;
        }
        border: none;
      }
      .calendarEnd {
        .headCalendar {
          width: 100%;
        }
        .bodyCalendar {
          .groupName {
            .nameDay {
              width: auto;
              flex: 0 1 15%;
            }
          }
          .groupDay {
            .dayNumber {
              span {
                width: 100%;
              }
              width: auto;
              flex: 0 1 15%;
            }
          }
          width: 100%;
        }
        display: none;
      }
      flex-direction: column;
    }
    width: 100%;
    left: 0 !important;
  }
  .onlyMobile {
    display: flex !important;
  }
  .hideMobile {
    display: none !important;
  }
  .promociones {
    .titleM {
      font-size: 16px;
      margin: 20px 0;
    }
    ul {
      li {
        h1 {
          font-size: 14px;
        }
        font-size: 12px;
      }
      padding-left: 20px;
    }
    width: calc(100% - 20px);
  }
  .boxCita {
    .boxGroupCita {
      .boxOption {
        .titleOption {
          font-size: 14px;
        }
        .contentOption {
          .iconLeft {
            min-width: 40px;
          }
          .textContent {
            font-size: 12px;
          }
          gap: 10px;
        }
        .boxActionCita {
          .btn {
            font-size: 12px;
          }
        }
        width: 100%;
      }
      width: calc(100% - 20px);
    }
  }
  .divPasMov {
    .divPas {
      .groupPasajero {
        .boxHelp {
          .descrip {
            font-size: 12px;
          }
          margin-top: 0;
          padding: 10px;
        }
        gap: 10px;
        margin-bottom: 15px;
      }
      margin: 0;
      padding: 0;
    }
    .selectDestino {
      padding: 0;
    }
    flex-direction: column;
  }
  .containerBox {
    &::after {
      height: 30em;
      background: url(/assets/citas/header-mobile.webp);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    .titleCanal {
      justify-content: center;
    }
    .boxCanales {
      .boxCardCanal {
        flex: 0 1 calc(50% - 20px);
      }
    }
    height: auto;
    padding: 50px 0;
  }
  .containerError {
    .imgBoxError {
      width: 70px;
      left: calc(50% - 35px);
    }
    .msgError {
      .titulo {
        font-size: 120px;
        line-height: 250px;
      }
      .mensaje {
        span {
          font-size: 12px;
        }
        font-size: 18px;
      }
      .boton {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 990px) and (min-width: 640px) {
  .portletFiltro {
    .rowFiltro {
      .columnFiltro {
        input {
          font-size: 14px;
          letter-spacing: 0;
        }
        input[type='text'] {
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
  .posFilter {
    max-width: 90%;
  }
  .boxFlotante {
    right: 10%;
  }
  .boxItemPackages {
    .boxItem {
      width: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }
  .boxCanalPackage {
    .boxCanal {
      flex: 1 1 20%;
    }
    flex-wrap: wrap;
    gap: 50px;
  }
  .rowBoxTab {
    display: flex;
    padding: 4px 0 10px 0;
    position: relative;
  }
  .onlydesktop {
    display: none !important;
  }
  .onlyMovTablet {
    display: block !important;
  }
  .boxVuelos {
    padding: 10px;
  }
  .boxImgVuelo {
    padding: 0 20px;
  }
  .viajaBox {
    .mnuViaja {
      .padLeft70 {
        img {
          width: 95%;
        }
        padding-left: 30px;
      }
      .subMenu {
        font-size: 14px;
      }
      padding-top: 150px;
    }
  }
  .newBoxTab {
    .sectionTab {
      .columnBox {
        flex-wrap: wrap;
        gap: 10px;
      }
      flex-wrap: wrap;
      gap: 10px;
    }
    padding: 15px;
  }
  .boxAero {
    .boxAbout {
      .descrip {
        padding: 0 30px;
      }
      .sliderAero {
        #carouselAero {
          width: 450px !important;
          img {
            width: 100%;
          }
        }
        width: 50%;
        display: flex;
        align-items: center;
      }
    }
  }
  .tabContentDest {
    .tabColumna {
      .contenidoTab {
        flex: 1 1 70%;
      }
      gap: 50px;
    }
    flex: 1 1 50%;
    padding: 0 30px;
  }
  .boxCalendar {
    left: 8% !important;
  }
  .promociones {
    width: 90%;
  }
  .boxCita {
    .boxGroupCita {
      .boxOption {
        .titleOption {
          font-size: 18px;
        }
        .contentOption {
          .iconLeft {
            min-width: 65px;
          }
          .textContent {
            font-size: 14px;
          }
          gap: 20px;
        }
        .boxActionCita {
          .btn {
            font-size: 13px;
          }
        }
      }
      width: 90%;
      justify-content: space-between;
    }
  }
  .containerBox {
    .boxCanales {
      .boxCardCanal {
        flex: 0 1 30%;
      }
    }
  }
  .containerError {
    .msgError {
      .titulo {
        font-size: 250px;
      }
      .mensaje {
        font-size: 20px;
      }
      .boton {
        font-size: 14px;
      }
    }
  }
}
@media (min-width: 768px) {
  .box-container {
    .box-perfil {
      .boxContact {
        flex-direction: row;
        padding: 20px 20px 0 20px;
      }
    }
    .box-content {
      .portlet-izq {
        display: block;
        width: 265px !important;
        margin-top: -10px;
        border: 1px solid #e4e5e7;
        box-sizing: border-box;
        border-radius: 5px 5px 5px 0;
        height: auto;
        min-height: fit-content;
        background: #fff;
        padding: 20px 34px;
        z-index: 9;
      }
      .portlet-der {
        padding: 0;
        .viewPerfil {
          .boxContPerfil {
            width: 400px;
            margin-right: 40px;
            flex-direction: row;
            .boxLeft {
              margin-bottom: 0;
              margin-right: 25px;
            }
            .boxRight {
              text-align: left;
              .boxBtn {
                justify-content: end;
              }
            }
          }
        }
        .personalDocument {
          .content {
            .rowContent {
              flex-direction: row;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) and (min-width: 991px) {
  .boxItemPackages {
    .boxItem {
      width: calc(33% - 20px);
      max-width: calc(33% - 20px);
    }
  }
}
@media (min-width: 1024px) {
  .rowBoxTab {
    .colBoxtab {
      padding: 0;
    }
  }
  .margin-filter {
    margin-right: 70px;
    margin-left: 70px;
  }
}
@media (max-width: 640px) and (max-width: 990px) and (min-width: 640px) {
  .mat-stepper-horizontal {
    padding-top: 20px;
  }
}
@media (max-width: 640px) and (max-width: 640px) {
  .card_ {
    .option {
      .dot {
        display: none;
      }
      .marginL {
        margin-left: 0;
      }
    }
  }
  .card.padCard {
    .boxFlotante {
      left: 15px;
      top: 55px;
    }
  }
  .boxTab {
    .rowBoxTab {
      .customers {
        width: auto;
      }
      flex-wrap: wrap;
      width: 100%;
    }
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
  }
}
@media (max-width: 640px) and (min-width: 1024px) {
  .customers {
    margin-top: 11px !important;
  }
}
@media (max-width: 767px) {
  .moreInfo {
    .groupOption {
      flex-direction: column;
      align-items: start;
    }
  }
}
@media (min-width: 1400px) {
  .container-fluid.fluid {
    width: 100% !important;
  }
  .card__content {
    overflow: hidden;
    display: flex;
    justify-content: center;
    .w-100 {
      width: auto !important;
    }
  }
  .mnuViaja {
    .padLeft70 {
      img {
        width: 60% !important;
      }
    }
  }
}
@media print {
  .doNotPrint {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .forgot-password-modal {
    .modal-dialog {
      width: calc(100% - 15px);
      .modal-content {
        .modal-content {
          .recoveryLeft {
            width: 100%;
            height: 320px;
          }
          flex-direction: column;
          overflow: hidden;
        }
      }
    }
  }
  .new-account-modal {
    .modal-dialog {
      width: calc(100% - 15px);
      .modal-content {
        .recoveryLeft {
          width: 100%;
          height: 320px;
        }
        flex-direction: column;
        overflow: hidden;
      }
    }
  }
}
@media (max-width: 1100px) and (min-width: 990px) {
  .containerBox {
    .boxCanales {
      .boxCardCanal {
        flex: 0 1 30%;
      }
    }
  }
}
