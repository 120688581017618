@font-face {
  font-family: 'CustomIcons';
  src: url('/assets/fonts/custom-icons-v101.woff') format('woff'),
  url('/assets/fonts/custom-icons-v101.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.custom-icon {
  font-family: 'CustomIcons', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-transform: none;
  vertical-align: middle;
  speak: none;

  &.icon-search:before {
    content: "\1f50d";
  }

  &.icon-airplane-right:before {
    content: "\1f6ea";
  }

  &.icon-map:before {
    content: "\1f5fa";
  }
}
